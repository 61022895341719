import React, { useState } from 'react';
import { Button, Form, Input, Tag } from 'antd';
import PageTitle from '../../../components/page-title';
import TextEditor from '../../../components/text-editor/text-editor';
import CustomizedUploadFileControl from '../../../components/customized-upload-file-control';
import { UPLOAD_TYPE, MAX_IMAGE_SIZE } from '../../../utils/constant';
import './style.scss';
import { REQUIRED } from 'utils/rule-form';

const NewArticle = () => {
  const [tags, setTags] = useState<string[]>([]);
  const [valueTagInput, setValueTagInput] = useState<string>('');

  const onAddTag = (set: any, oldData: any, data: any) => {
    if (oldData.indexOf(data) === -1) {
      set([...oldData, data]);
    }
  };

  const oncloseTag = (set: any, oldData: any, removedTag: any) => {
    const results = oldData.filter((item: string) => {
      return item !== removedTag;
    });
    set(results);
  };

  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <div>
      <PageTitle title='Article new' />
      <div className='new-article'>
        <Form
          layout='vertical'
          name='new-article-form'
          className='new-article__form'
          onFinish={onFinish}
        >
          <Form.Item name='title' label='Title' rules={[REQUIRED]}>
            <Input />
          </Form.Item>
          <Form.Item name='shortDescription' label='Short Description'>
            <Input />
          </Form.Item>
          <Form.Item name='file' label='File'>
            <CustomizedUploadFileControl type={UPLOAD_TYPE.IMAGE} maxFileSize={MAX_IMAGE_SIZE} />
          </Form.Item>
          <div className='new-article__tags'>
            <div className='new-article__tags-title'>Tags</div>
            <div className='new-article__tag-add'>
              <Input
                value={valueTagInput}
                type='text'
                className='w-full'
                onChange={(e: any) => {
                  setValueTagInput(e?.target?.value);
                }}
              />
              <Button
                type='primary'
                onClick={() => {
                  if (valueTagInput === '') {
                    return;
                  }
                  onAddTag(setTags, tags, valueTagInput.trim());
                  setValueTagInput('');
                }}
              >
                Add tag
              </Button>
            </div>

            <div className='new-article__tag-list'>
              {tags &&
                tags.map((item, index) => (
                  <Tag
                    key={index + item}
                    closable
                    onClose={() => {
                      oncloseTag(setTags, tags, item);
                    }}
                    color='blue'
                  >
                    {item}
                  </Tag>
                ))}
            </div>
          </div>
          <div className='new-article__content'>
            <Form.Item
              name='content'
              rules={[
                {
                  required: true,
                  message: 'Please enter body of post',
                },
              ]}
            >
              <TextEditor />
            </Form.Item>
          </div>
          <div className='new-article__footer'>
            <Button type='primary' htmlType='submit' className='login__button'>
              Add new
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default NewArticle;
