import {
  checkFile,
  validateWithoutSpecialCharacters,
  checkAllSpaceEditor,
  checkMaxEditor,
  checkRequiredEditor,
  checkAllSpaceWithoutRequireEditor,
  checkEmailSpaceRequire,
  checkPassword,
} from './common';
import { NOT_BLANK_MESSAGE, REQUIRED_MESSAGE } from './constant';

const REQUIRED = { required: true, message: REQUIRED_MESSAGE };
const WHITE_SPACE = { whitespace: true, message: REQUIRED_MESSAGE };
const WHITE_SPACE_NOT_REQUIRED = { whitespace: true, message: NOT_BLANK_MESSAGE };
const MAX_NUMBER_10 = {
  pattern: /^[\d]{0,10}$/,
  message: 'Inputted text exceeds the character limit. Please enter less than 10 characters',
};
const MAX_DECIMAL_10 = {
  pattern: /^[\d]{0,10}$|^\d{0,10}\.\d+$/,
  message: 'Inputted text exceeds the character limit. Please enter less than 10 characters',
};

const getMaxLengthValidate = (length: number) => {
  return {
    max: length,
    message: `Inputted text exceeds the character limit. Please enter less than ${length} characters.`,
  };
};

const WITHOUT_SPECIAL_CHARACTER = { validator: validateWithoutSpecialCharacters };
const CHECK_FILE = { validator: checkFile };
const CHECK_HTTP = {
  // eslint-disable-next-line
  pattern: /https?:\/\//g,
  message: 'Please enter the exact link.',
};
const CHECK_YOUTUBE = {
  pattern:
    // eslint-disable-next-line
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g,
  message: 'Please enter the exact link.',
};

const CHECK_ALL_SPACE = { validator: checkAllSpaceEditor };
const CHECK_ALL_SPACE_WITHOUT_REQUIRED = { validator: checkAllSpaceWithoutRequireEditor };
const CHECK_REQUIRED = { validator: checkRequiredEditor };
const CHECK_EMAIL_SPACE_REQUIRE = { validator: checkEmailSpaceRequire };
const MAX_10000_EDITOR = { validator: checkMaxEditor };
const CHECK_PASSWORD = { validator: checkPassword };

export {
  REQUIRED,
  CHECK_REQUIRED,
  WHITE_SPACE,
  WHITE_SPACE_NOT_REQUIRED,
  MAX_NUMBER_10,
  WITHOUT_SPECIAL_CHARACTER,
  CHECK_FILE,
  CHECK_HTTP,
  CHECK_YOUTUBE,
  CHECK_ALL_SPACE,
  CHECK_ALL_SPACE_WITHOUT_REQUIRED,
  MAX_10000_EDITOR,
  MAX_DECIMAL_10,
  getMaxLengthValidate,
  CHECK_EMAIL_SPACE_REQUIRE,
  CHECK_PASSWORD,
};
