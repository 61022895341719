import AxiosUtils from 'utils/axios-utils';
import { createBlackBookLoading, createBlackBookFailure, createBlackBookSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IUpdateBlackBook } from 'types/black-book';
import { paths } from 'routes/routes';

export const createBlackBook =
  (params: IUpdateBlackBook, navigate: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(createBlackBookLoading());
      const apiResponse: any = await AxiosUtils.post(API.blackBook, params);
      dispatch(createBlackBookSuccess(apiResponse?.data?.data));
      navigate(paths.blackBook);
    } catch (error: any) {
      dispatch(createBlackBookFailure(error));
    }
  };
