import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Table, Popconfirm, Space, Button, DatePicker, InputNumber, TableProps } from 'antd';
import { AppDispatch, RootState } from '../../../types/redux';
import './style.scss';
import PageTitle from '../../../components/page-title';
import { formatDate } from 'utils/common';
import { API_FORMAT_DATE, DISPLAY_FORMAT_DATE, PRICE_FILTER_MESSAGE } from 'utils/constant';
import InputSearch from 'components/input-search';
import {
  setDataMerchandise,
  setParamsMerchandise,
} from 'redux/merchandise/merchandise-list/reducers';
import { getMerchandise } from 'redux/merchandise/merchandise-list/actions';
import { deleteMerchandise } from 'redux/merchandise/merchandise-delete/actions';
import { IMerchandise, IPriceFilter } from 'types/merchandise';
import _ from 'lodash';
import { paths } from 'routes/routes';
import { getMaxLengthValidate } from 'utils/rule-form';

const Merchandise = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch() as AppDispatch;
  const merchandise = useSelector((state: RootState) => state.merchandise);

  const deleteMerchandiseData = useSelector((state: RootState) => state.deleteMerchandise);
  const { data, error, loading, total, paramsData } = merchandise;
  const navigate = useNavigate();
  const [priceFilter, setPriceFilter] = useState<IPriceFilter>({
    minPrice: null,
    maxPrice: null,
  });

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
    onChange: (current: number, size: number) => {
      dispatch(setParamsMerchandise({ paramsData, page: current, limit: size }));
    },
  };

  const onDelete = (id: string | number) => {
    dispatch(deleteMerchandise(id));
  };

  const onAdd = () => {
    navigate(paths.merchandiseCreate);
  };

  const onSearch = (value: string) => {
    dispatch(
      setParamsMerchandise({
        ...paramsData,
        name: value,
        page: 1,
        minPrice: priceFilter.minPrice,
        maxPrice: priceFilter.maxPrice,
      }),
    );
  };

  const handleFilterByDate = (e: any) => {
    if (!e) dispatch(setParamsMerchandise({ ...paramsData, startDate: '', endDate: '' }));
    else if (e?.length < 2) return;
    else {
      const startDate = formatDate(String(new Date(e[0])), API_FORMAT_DATE);
      const endDate = formatDate(String(new Date(e[1])), API_FORMAT_DATE);
      dispatch(setParamsMerchandise({ ...paramsData, startDate: startDate, endDate: endDate }));
    }
  };

  const debounceFilter = _.debounce((e, props) => {
    setPriceFilter({ ...priceFilter, [props]: e });
  }, 300);

  const handleFilterByPrice = (e: any, props: string) => {
    debounceFilter(e, props);
  };

  const columns: any = [
    {
      title: 'Thumbnail',
      dataIndex: 'images',
      key: 'thumbnail',
      width: 200,
      align: 'center',
      render: (images: any) => (
        <img className='thumbnail' src={images[0]?.thumbnailUrl || ''} alt='thumbnail' />
      ),
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'date',
      align: 'center',
      render: (text: string) => (text ? formatDate(text, DISPLAY_FORMAT_DATE) : ''),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      render: (text: string | number) => <span>${parseFloat(text as string).toFixed(2)}</span>,
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      align: 'center',
      render: (voucher: any) => (voucher ? voucher.name : ''),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: IMerchandise) => {
        const editLink = `/merchandise/${record.id}/edit`;
        return (
          <Space size='middle'>
            <Link to={editLink}>Edit</Link>
            <Popconfirm title='Are you sure to delete?' onConfirm={() => onDelete(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (priceFilter.maxPrice && priceFilter.minPrice) {
      priceFilter.maxPrice >= priceFilter.minPrice &&
        dispatch(
          setParamsMerchandise({
            ...paramsData,
            page: 1,
            minPrice: priceFilter.minPrice,
            maxPrice: priceFilter.maxPrice,
          }),
        );
    } else {
      dispatch(
        setParamsMerchandise({
          ...paramsData,
          page: 1,
          minPrice: priceFilter.minPrice,
          maxPrice: priceFilter.maxPrice,
        }),
      );
    }
  }, [priceFilter]);

  useEffect(() => {
    dispatch(getMerchandise(paramsData));
  }, [dispatch, paramsData, deleteMerchandiseData]);

  useEffect(() => {
    if (data.length === 0 && paramsData.page > 1) {
      dispatch(setParamsMerchandise({ ...paramsData, page: paramsData.page - 1 }));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setParamsMerchandise(null));
      dispatch(setDataMerchandise([]));
    };
  }, []);

  return (
    <div className='merchandise'>
      <PageTitle title='Merchandise' />
      <Space size='middle' className='merchandise__featureBox' align='start'>
        <div className='merchandise__searchBox'>
          <Button onClick={onAdd} type='primary' style={{ marginRight: '20px' }}>
            Add new
          </Button>
          <div>
            <InputSearch
              propRules={[getMaxLengthValidate(200)]}
              placeholder='Search by name'
              onChangeSearch={(value) => onSearch(value)}
            />
          </div>
        </div>
        <div className='merchandise__filter'>
          <div className='merchandise__filterBox'>
            <div className='merchandise__filterPrice'>
              <p className='merchandise__filterTitle'>Filter by price:</p>
              <InputNumber
                onChange={(e) => handleFilterByPrice(e, 'minPrice')}
                min={0}
                name='minPrice'
                addonAfter='$'
                placeholder='Min'
                className='merchandise__inputPrice'
              />
              <InputNumber
                onChange={(e) => handleFilterByPrice(e, 'maxPrice')}
                min={0}
                name='maxPrice'
                placeholder='Max'
                addonAfter='$'
                className='merchandise__inputPrice'
              />
            </div>
            <div className='merchandise__priceFilterLog'>
              <div></div>
              {priceFilter.maxPrice &&
              priceFilter.minPrice &&
              priceFilter.minPrice > priceFilter.maxPrice ? (
                <p>{PRICE_FILTER_MESSAGE}</p>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className='merchandise__filterBox'>
            <div className='merchandise__filterPrice'>
              <p className='merchandise__filterTitle'>Filter by date:</p>
              <RangePicker
                placeholder={['From', 'To']}
                format={DISPLAY_FORMAT_DATE}
                onChange={handleFilterByDate}
                className='merchandise__rangePicker'
              />
            </div>
          </div>
        </div>
      </Space>

      <div>
        {error ? (
          <Alert message={error} type='error' />
        ) : (
          <Table
            rowKey='id'
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            bordered
            scroll={{ y: '70vh', x: 900 }}
          />
        )}
      </div>
    </div>
  );
};

export default Merchandise;
