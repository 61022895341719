import AxiosUtils from 'utils/axios-utils';
import { getPolicyDetailFailure, getPolicyDetailLoading, getPolicyDetailSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';

export const getPolicyDetail = (pageCode: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getPolicyDetailLoading());
    const apiResponse = (await AxiosUtils.get(`${API.policyDetail}/${pageCode}`)) as any;
    dispatch(getPolicyDetailSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getPolicyDetailFailure(error.toString()));
  }
};
