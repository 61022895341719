import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const upCommingRaceSlice = createSlice({
  name: 'createRace',
  initialState: initialState,
  reducers: {
    createRaceLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    createRaceSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    createRaceFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCreateRaceError: (state) => {
      state.error = '';
    },
  },
});

export const { createRaceLoading, createRaceSuccess, createRaceFailure, resetCreateRaceError } =
  upCommingRaceSlice.actions;
export default upCommingRaceSlice.reducer;
