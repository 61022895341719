import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { Space, Button, Popconfirm, Alert, Table, DatePicker } from 'antd';
import PageTitle from '../../../components/page-title';
import { paths } from '../../../routes/routes';
import { AppDispatch, RootState } from '../../../types/redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  setDataExclusiveContent,
  setParamsExclusiveContent,
} from '../../../redux/exclusive-content/exclusive-content-list/reducers';
import { deleteExclusiveContent } from '../../../redux/exclusive-content/exclusive-content-delete/actions';
import { formatLocalDate } from '../../../utils/common';
import defaultImg from '../../../assets/images/default-img.png';
import { Link } from 'react-router-dom';
import { IExclusiveContent } from '../../../types/exclusive-content';
import { getExclusiveContent } from '../../../redux/exclusive-content/exclusive-content-list/actions';
import { DISPLAY_FORMAT_DATETIME, PRIMARY_FORMAT_DATE } from '../../../utils/constant';
import InputSearch from 'components/input-search';
import moment from 'moment';

const ExclusiveContent = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const dispatch = useDispatch() as AppDispatch;
  const exclusiveContent = useSelector((state: RootState) => state.exclusiveContent);
  const deleteExclusiveContentData = useSelector(
    (state: RootState) => state.deleteExclusiveContent,
  );
  const { data, error, loading, total, paramsData } = exclusiveContent;
  const [showData, setShowData] = useState<any>([]);

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
    onChange: (current: number, size: number) => {
      dispatch(setParamsExclusiveContent({ paramsData, page: current, limit: size }));
    },
  };

  const onAdd = () => {
    navigate(paths.exclusiveContentCreate);
  };

  const onDelete = (id: string | number) => {
    dispatch(deleteExclusiveContent(id));
  };

  const onSearch = (value: string) => {
    dispatch(setParamsExclusiveContent({ ...paramsData, title: value, page: 1 }));
  };

  const handleFilterSearchByDisplayTime = (e: any) => {
    if (!e)
      dispatch(
        setParamsExclusiveContent({ ...paramsData, displayTimeStart: '', displayTimeEnd: '' }),
      );
    else if (e?.length < 2) return;
    else {
      const displayTimeStart = moment.utc(e[0]).format(PRIMARY_FORMAT_DATE);
      const displayTimeEnd = moment.utc(e[1]).format(PRIMARY_FORMAT_DATE);
      dispatch(
        setParamsExclusiveContent({ ...paramsData, page: 1, displayTimeStart, displayTimeEnd }),
      );
    }
  };

  const handleFilterSearchByPurchaseTime = (e: any) => {
    if (!e)
      dispatch(
        setParamsExclusiveContent({ ...paramsData, purchaseTimeStart: '', purchaseTimeEnd: '' }),
      );
    else if (e?.length < 2) return;
    else {
      const purchaseTimeStart = moment.utc(e[0]).format(PRIMARY_FORMAT_DATE);
      const purchaseTimeEnd = moment.utc(e[1]).format(PRIMARY_FORMAT_DATE);
      dispatch(
        setParamsExclusiveContent({ ...paramsData, page: 1, purchaseTimeStart, purchaseTimeEnd }),
      );
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Display Time',
      dataIndex: ['displayTimeStart', 'displayTimeEnd'],
      key: 'displayTime',
      align: 'center' as const,
      render: (text: string, record: any) => (
        <span>
          From: {formatLocalDate(record['displayTimeStart'], DISPLAY_FORMAT_DATETIME)} <br />
          To: {formatLocalDate(record['displayTimeEnd'], DISPLAY_FORMAT_DATETIME)}
        </span>
      ),
    },
    {
      title: 'Purchase Time',
      dataIndex: ['purchaseTimeStart', 'purchaseTimeEnd'],
      key: 'purchaseTime',
      align: 'center' as const,
      render: (text: string, record: any) => (
        <span>
          From: {formatLocalDate(record['purchaseTimeStart'], DISPLAY_FORMAT_DATETIME)} <br />
          To: {formatLocalDate(record['purchaseTimeEnd'], DISPLAY_FORMAT_DATETIME)}
        </span>
      ),
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnailUrl',
      key: 'thumbnail',
      width: 200,
      align: 'center' as const,
      render: (text: string) => (
        <img className='thumbnail' src={text ? text : defaultImg} alt='thumbnail' />
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      align: 'center' as const,
      render: (text: string | number) => <span>AUD{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
      render: (text: string) => {
        return <span>{text}</span>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: IExclusiveContent) => {
        const editLink = `${paths.exclusiveContent}/${record.id}/edit`;
        return (
          <Space size='middle'>
            <Link to={editLink}>{record.status === 'AVAILABLE' ? 'View' : 'Edit'}</Link>
            <Popconfirm title='Are you sure to delete?' onConfirm={() => onDelete(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getExclusiveContent(paramsData));
  }, [dispatch, paramsData, deleteExclusiveContentData]);

  useEffect(() => {
    if (data.length === 0 && paramsData.page > 1) {
      dispatch(setParamsExclusiveContent({ ...paramsData, page: paramsData.page - 1 }));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setParamsExclusiveContent(null));
      dispatch(setDataExclusiveContent([]));
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      data &&
        setShowData([
          ...data.map((item: any) => {
            const status =
              moment().isSameOrAfter(moment(item.displayTimeStart, PRIMARY_FORMAT_DATE)) &&
              moment().isSameOrBefore(moment(item.displayTimeEnd, PRIMARY_FORMAT_DATE))
                ? 'AVAILABLE'
                : 'HIDDEN';
            return {
              ...item,
              status,
            };
          }),
        ]);
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  return (
    <div>
      <PageTitle title='Late Mail Tips' />
      <Space size='middle' className='exclusive-content__featureBox' align='start'>
        <div className='exclusive-content__searchBox'>
          <Button type='primary' onClick={onAdd} style={{ marginRight: '20px' }}>
            Add new
          </Button>
          <div>
            <InputSearch
              placeholder='Search by title'
              onChangeSearch={(value) => onSearch(value)}
            />
          </div>
        </div>
        <div className='exclusive-content__filter'>
          <div className='exclusive-content__filterBox'>
            <p className='exclusive-content__filterTitle'>Filter by display time:</p>
            <RangePicker
              placeholder={['From Date', 'To Date']}
              format={DISPLAY_FORMAT_DATETIME}
              onChange={handleFilterSearchByDisplayTime}
              className='exclusive-content__rangePicker'
              showTime
            />
          </div>
          <div className='exclusive-content__filterBox'>
            <p className='exclusive-content__filterTitle'>Filter by purchase time:</p>
            <RangePicker
              placeholder={['From Date', 'To Date']}
              format={DISPLAY_FORMAT_DATETIME}
              onChange={handleFilterSearchByPurchaseTime}
              className='exclusive-content__rangePicker'
              showTime
            />
          </div>
        </div>
      </Space>

      <div>
        {error ? (
          <Alert message={error} type='error' />
        ) : (
          <Table
            rowKey='id'
            columns={columns}
            dataSource={showData}
            pagination={pagination}
            loading={loading || showData.length !== data.length}
            scroll={{ y: '70vh', x: 900 }}
            bordered
          />
        )}
      </div>
    </div>
  );
};

export default ExclusiveContent;
