export const API = {
  // auth
  login: 'admin/signin',
  changePassword: 'admin/change-password',

  // user management
  users: 'user',
  banUser: 'user/delete',
  editUser: '/user/update',
  export: '/user/export',

  // black book
  blackBook: 'black-book',
  blackBookSort: 'black-book/custom',
  blackBookDetail: 'black-book/detail',
  blackBookUpdate: 'black-book/update',
  blackBookPushNoti: 'black-book/notification',

  //upcoming race
  upcomingRaceList: 'upcoming-race',
  upcomingRaceDetail: 'upcoming-race',
  upcomingRaceUpdate: 'upcoming-race/update',

  // podcast
  podcast: 'podcast',
  podcastDetail: 'podcast/detail',
  podcastUpdate: 'podcast/update',

  region: 'region',
  article: '',
  banner: '/banner',
  category: '',

  //merchandise
  merchandise: 'merchandise',
  merchandiseUpdate: 'merchandise/update',

  //exclusive content
  exclusiveContent: 'exclusive',
  exclusiveContentDetail: 'exclusive',
  exclusiveContentUpdate: 'exclusive',

  // policy
  policyDetail: 'content-page',
  policyUpdate: 'content-page',

  // free account list
  freeAccounts: 'user/free-account',
  deleteFreeAccount: 'user/free-account',
  editFreeAccount: 'user/update',
  createFreeAccount: 'user/signup',
  banFreeAccount: 'user/delete',
  changePasswordFreeAccount: '/user/free-account/update-password',

  // request delete
  requestDelete: 'user/delete_request',
  createRequestDelete: 'user/delete_request',
  deleteRequestDelete: 'user/deactive-admin',
};
