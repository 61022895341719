import { Form, FormInstance } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

const useRangeDateTime = (form: FormInstance<any>, type: string) => {
  const currentStartDate = Form.useWatch(`${type}StartDate`, form);
  const currentStartHour = Form.useWatch(`${type}StartHour`, form);
  const currentStartMinute = Form.useWatch(`${type}StartMinute`, form);
  const currentEndDate = Form.useWatch(`${type}EndDate`, form);
  const currentEndHour = Form.useWatch(`${type}EndHour`, form);
  const currentEndMinute = Form.useWatch(`${type}EndMinute`, form);
  const [disableStartHour, setDisableStartHour] = useState(true);
  const [disableStartMinute, setDisableStartMinute] = useState(true);
  const [disableEndDate, setDisableEndDate] = useState(true);
  const [disableEndHour, setDisableEndHour] = useState(true);
  const [disableEndMinute, setDisableEndMinute] = useState(true);

  useEffect(() => {
    if (form.getFieldInstance(`${type}StartHour`)) {
      currentStartDate
        ? setDisableStartHour(false)
        : (setDisableStartHour(true), form.setFieldValue(`${type}StartHour`, ''));
      if (type === 'display' && !currentStartDate) {
        form.setFieldValue(`purchaseStartDate`, '');
      }
    }
  }, [currentStartDate]);

  useEffect(() => {
    if (type === 'display' && currentStartDate && currentStartHour && currentStartMinute) {
      form.setFieldValue(`purchaseStartDate`, currentStartDate);
      form.setFieldValue(`purchaseStartHour`, currentStartHour);
      form.setFieldValue(`purchaseStartMinute`, currentStartMinute);
    }
  }, [currentStartDate, currentStartHour, currentStartMinute]);

  useEffect(() => {
    if (form.getFieldInstance(`${type}StartMinute`)) {
      currentStartDate && currentStartHour
        ? setDisableStartMinute(false)
        : (setDisableStartMinute(true), form.setFieldValue(`${type}StartMinute`, ''));
    }
  }, [currentStartHour]);

  useEffect(() => {
    if (form.getFieldInstance(`${type}EndDate`)) {
      currentStartMinute
        ? setDisableEndDate(false)
        : (setDisableEndDate(true), form.setFieldValue(`${type}EndDate`, ''));
    }
  }, [currentStartMinute]);

  useEffect(() => {
    if (form.getFieldInstance(`${type}EndHour`)) {
      currentEndDate
        ? setDisableEndHour(false)
        : (setDisableEndHour(true), form.setFieldValue(`${type}EndHour`, ''));
      if (type === 'display' && !currentEndDate) {
        form.setFieldValue(`purchaseEndDate`, '');
      }
    }
  }, [currentEndDate]);

  useEffect(() => {
    if (form.getFieldInstance(`${type}EndMinute`)) {
      currentEndDate && currentEndHour
        ? setDisableEndMinute(false)
        : (setDisableEndMinute(true), form.setFieldValue(`${type}EndMinute`, ''));
    }
  }, [currentEndDate, currentEndHour]);

  const getDisableStartDays = (current: any) => {
    const subFlag1 = current.isBefore(moment().startOf('day'));
    if (subFlag1) {
      return true;
    }
    if (currentEndDate) {
      const subFlag2 = current.isBefore(moment(currentEndDate).startOf('day').subtract(5, 'day'));
      const subflag3 = current.isAfter(moment(currentEndDate).endOf('day'));
      if (subFlag1 || subFlag2 || subflag3) {
        return true;
      }
    }
    if (
      currentStartHour &&
      currentStartMinute &&
      moment(current)
        .set({ hour: currentStartHour.hour(), minute: currentStartMinute.minute() })
        .isBefore(moment())
    ) {
      return true;
    }
    return false;
  };

  const getDisableEndDays = (current: any) => {
    if (
      currentStartDate &&
      currentStartHour &&
      currentStartMinute &&
      currentEndHour &&
      currentEndMinute &&
      moment(currentStartDate)
        .set({
          hour: currentStartHour.hour(),
          minute: currentStartMinute.minute(),
        })
        .isAfter(
          moment(current).set({ hour: currentEndHour.hour(), minute: currentEndMinute.minute() }),
        )
    ) {
      return true;
    }
    const subFlag1 = current.isBefore(moment(currentStartDate).startOf('day'));
    const subFlag2 = current.isAfter(moment(currentStartDate).endOf('day').add(5, 'day'));

    if (subFlag1 === subFlag2) {
      return false;
    }
    return true;
  };

  const getDisabledStartHours = () => {
    const hours = [];
    if (currentStartDate && currentStartDate.isSame(moment(), 'day')) {
      const currentHour = moment().hour();

      for (let i = 0; i < currentHour; i++) {
        hours.push(i);
      }
      if (
        currentStartMinute &&
        moment(currentStartDate)
          .set({ hour: currentHour, minute: currentStartMinute.minute(), second: 0 })
          .isBefore(moment())
      ) {
        hours.push(currentHour);
      }
    }
    if (
      currentStartDate &&
      currentEndDate &&
      currentEndHour &&
      currentEndDate.date() === moment(currentStartDate).add(5, 'days').date()
    ) {
      const currentHour = currentEndHour.hour();

      for (let i = 0; i < currentHour; i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledStartMinutes = () => {
    const minutes = [];
    const currentMinute = moment().minute();
    if (
      currentStartDate &&
      currentStartHour &&
      currentStartDate.isSame(moment(), 'day') &&
      currentStartHour.hour() === moment().hour()
    ) {
      for (let i = 0; i < currentMinute; i++) {
        minutes.push(i);
      }
    }
    if (
      currentStartDate &&
      currentStartHour &&
      currentEndDate &&
      currentEndHour &&
      currentEndMinute &&
      currentEndDate.date() === moment(currentStartDate).add(5, 'days').date() &&
      currentEndHour.hour() === currentStartHour.hour()
    ) {
      for (let i = 0; i < currentEndMinute.minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const getDisabledEndHours = () => {
    const hours = [];
    if (
      currentStartDate &&
      currentEndDate &&
      currentStartHour &&
      currentEndDate.isSame(currentStartDate, 'day')
    ) {
      const currentHour = currentStartHour.hour();

      for (let i = 0; i < currentHour; i++) {
        hours.push(i);
      }
    }
    if (
      currentStartDate &&
      currentEndDate &&
      currentStartHour &&
      currentEndDate.date() === moment(currentStartDate).add(5, 'days').date()
    ) {
      const currentHour = 24;
      for (let i = 0; i < currentHour; i++) {
        if (i > currentStartHour.hour()) {
          hours.push(i);
        }
      }
    }
    return hours;
  };

  const getDisabledEndMinutes = () => {
    const minutes = [];

    if (
      currentStartDate &&
      currentEndDate &&
      currentStartHour &&
      currentEndHour &&
      currentEndDate.isSame(currentStartDate, 'day') &&
      currentEndHour.hour() === currentStartHour.hour()
    ) {
      const currentMinute = currentStartMinute.minute();
      for (let i = 0; i < currentMinute; i++) {
        minutes.push(i);
      }
    }
    if (
      currentEndDate &&
      currentEndHour &&
      currentStartDate &&
      currentEndDate &&
      currentStartHour &&
      currentEndDate.date() === moment(currentStartDate).add(5, 'days').date() &&
      currentEndHour.hour() === currentStartHour.hour()
    ) {
      const currentMinute = 60;
      for (let i = 0; i < currentMinute; i++) {
        if (i > currentStartMinute.minute()) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  return {
    currentStartDate,
    currentEndDate,
    disableEndDate,
    disableStartHour,
    disableStartMinute,
    disableEndHour,
    disableEndMinute,
    currentStartHour,
    currentEndHour,
    currentStartMinute,
    currentEndMinute,
    getDisabledStartHours,
    getDisabledStartMinutes,
    getDisabledEndHours,
    getDisabledEndMinutes,
    getDisableEndDays,
    getDisableStartDays,
  };
};

export default useRangeDateTime;
