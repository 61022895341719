import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import './style.scss';
import { CloseOutlined } from '@ant-design/icons';
import { CHECK_HTTP, getMaxLengthValidate, REQUIRED, WHITE_SPACE } from 'utils/rule-form';
import _ from 'lodash';
import { HorseInformation } from 'types/upcoming-race';

interface Props {
  index: number;
}

const CustomAddHorses = ({ index: indexRaces }: Props) => {
  const [visible, setVisible] = useState(true);
  const form = Form.useFormInstance();

  const hasDuplicate = (listHorse: HorseInformation[]) => {
    if (listHorse) {
      const linkArray = listHorse?.map((item: HorseInformation) =>
        item?.linkBettingSite?.trim().split(/ +/).join(' '),
      );
      const informationArray = listHorse?.map((item: HorseInformation) =>
        item?.description?.trim().split(/ +/).join(' '),
      );
      return (
        _.uniq(linkArray.filter((item) => item)).length !==
          linkArray.filter((item) => item).length ||
        _.uniq(informationArray.filter((item) => item)).length !==
          informationArray.filter((item) => item).length
      );
    }
    return false;
  };

  const onChangeHorseInfo = () => {
    const listHorse = form.getFieldValue('races')?.[indexRaces]?.horseInformation;
    setVisible(hasDuplicate(listHorse));
  };

  return (
    <div className='custom-add-horses'>
      <Form.List name={[indexRaces, 'horseInformation']}>
        {(fields, { add, remove }, { errors }) => {
          return (
            <div>
              {fields.map((field, index) => {
                const horseInfo =
                  form.getFieldValue('races')?.[indexRaces]?.horseInformation?.[index];
                const clicks = horseInfo?.clickCountBettingSite;
                const views = form.getFieldValue('viewCountBettingSite');
                const clickThroughRatio =
                  views && clicks ? `CTR = ${((clicks / views) * 100).toFixed(2)} %` : 'CTR = 0%';
                return (
                  <div key={field.key} className='custom-add-horses__item'>
                    {fields.length && (
                      <div className='custom-add-horses__remove'>
                        <CloseOutlined onClick={() => remove(field.name)} />
                      </div>
                    )}
                    <Form.Item
                      name={[index, 'description']}
                      label='Horse Information'
                      rules={[REQUIRED, getMaxLengthValidate(100), WHITE_SPACE]}
                    >
                      <Input onChange={onChangeHorseInfo} />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'linkBettingSite']}
                      label={
                        <>
                          Link
                          {horseInfo?.linkBettingSite ? (
                            <span className='__ctr'>{clickThroughRatio}</span>
                          ) : (
                            ''
                          )}
                        </>
                      }
                      rules={[CHECK_HTTP, getMaxLengthValidate(1000)]}
                    >
                      <Input onChange={onChangeHorseInfo} />
                    </Form.Item>
                    {typeof clicks === 'number' && (
                      <p className='custom-add-horses__count'>Click Counts: {clicks}</p>
                    )}
                  </div>
                );
              })}
              <div className='custom-add-horses__max-item'>
                <Button ghost type='primary' onClick={() => add()} htmlType='button' size='small'>
                  Add Horse
                </Button>
                {fields.length > 0 && <p>Total: {fields.length} horse</p>}
              </div>
              {visible && (
                <div className='custom-add-horses__error-list'>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default CustomAddHorses;
