import AxiosUtils from 'utils/axios-utils';
import { deleteBlackBookLoading, deleteBlackBookFailed, deleteBlackBookSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';

export const deleteBlackBook = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteBlackBookLoading());
    const apiResponse = (await AxiosUtils._delete(`${API.blackBook}/${id}`)) as any;
    dispatch(deleteBlackBookSuccess(apiResponse.data));
    toast.success('Deleted the Black Book successfully!', { autoClose: 3000 });
  } catch (error: any) {
    toast.error('Deleted the Black Book failed!', { autoClose: 3000 });
    dispatch(deleteBlackBookFailed(error.toString()));
  }
};
