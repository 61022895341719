import AxiosUtils from 'utils/axios-utils';
import {
  deleteExclusiveContentLoading,
  deleteExclusiveContentFailed,
  deleteExclusiveContentSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';

export const deleteExclusiveContent = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteExclusiveContentLoading());
    const apiResponse = (await AxiosUtils._delete(`${API.exclusiveContent}/${id}`)) as any;
    dispatch(deleteExclusiveContentSuccess(apiResponse.data));
    toast.success('Deleted the Late Mail Tips successfully!', { autoClose: 3000 });
  } catch (error: any) {
    toast.error('Deleted the Late Mail Tips failed!', { autoClose: 3000 });
    dispatch(deleteExclusiveContentFailed(error.toString()));
  }
};
