import AxiosUtils from 'utils/axios-utils';
import {
  getExclusiveContentLoading,
  getExclusiveContentSuccess,
  getExclusiveContentFailure,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { ParamsTableType } from 'types/black-book';

export const getExclusiveContent = (params: ParamsTableType) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getExclusiveContentLoading());
    const apiResponse = (await AxiosUtils.get(API.exclusiveContent, params)) as any;
    dispatch(getExclusiveContentSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getExclusiveContentFailure(error.toString()));
  }
};
