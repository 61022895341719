import AxiosUtils from 'utils/axios-utils';
import {
  deleteMerchandiseLoading,
  deleteMerchandiseFailed,
  deleteMerchandiseSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';

export const deleteMerchandise = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deleteMerchandiseLoading());
    const apiResponse = (await AxiosUtils._delete(`${API.merchandise}/${id}`)) as any;
    dispatch(deleteMerchandiseSuccess(apiResponse.data));
    toast.success('Deleted the merchandise successfully!', { autoClose: 3000 });
  } catch (error: any) {
    toast.error('Deleted Merchandise failed!', { autoClose: 3000 });
    dispatch(deleteMerchandiseFailed(error.toString()));
  }
};
