import AxiosUtils from 'utils/axios-utils';
import {
  getBlackBookDetailLoading,
  getBlackBookDetailSuccess,
  getBlackBookDetailFailure,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';

export const getBlackBookDetail = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getBlackBookDetailLoading());
    const apiResponse = (await AxiosUtils.get(`${API.blackBookDetail}/${id}`)) as any;
    dispatch(getBlackBookDetailSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getBlackBookDetailFailure(error.toString()));
  }
};
