import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const freeAccountSlice = createSlice({
  name: 'editRace',
  initialState: initialState,
  reducers: {
    editFreeAccountLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    editFreeAccountSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    editFreeAccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetEditFreeAccountError: (state) => {
      state.error = '';
    },
  },
});

export const {
  editFreeAccountLoading,
  editFreeAccountFailure,
  editFreeAccountSuccess,
  resetEditFreeAccountError,
} = freeAccountSlice.actions;
export default freeAccountSlice.reducer;
