import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    id: '',
    title: '',
    description: '',
    displayTimeStart: '',
    displayTimeEnd: '',
    purchaseTimeStart: '',
    purchaseTimeEnd: '',
    thumbnailUrl: '',
    thumbnailKey: '',
    price: '',
    contentNotification: { description: '' },
  },
  loading: false,
  error: '',
};

const exclusiveContentSlice = createSlice({
  name: 'exclusiveContentDetail',
  initialState: initialState,
  reducers: {
    getExclusiveContentDetailLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getExclusiveContentDetailSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getExclusiveContentDetailFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    resetExclusiveContentDetailData: (state) => {
      state.data = initialState.data;
    },
  },
});

export const {
  getExclusiveContentDetailLoading,
  getExclusiveContentDetailSuccess,
  getExclusiveContentDetailFailure,
  resetExclusiveContentDetailData,
} = exclusiveContentSlice.actions;
export default exclusiveContentSlice.reducer;
