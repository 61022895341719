import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Row, Col, Spin } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'types/redux';
import { useSelector } from 'react-redux';
import { setCheckedList } from 'redux/users/user-list/reducers';
import { USER_OPTIONS } from 'utils/constant';

const ModalExport = () => {
  const dispatch = useDispatch() as AppDispatch;

  const users = useSelector((state: RootState) => state.users);
  const { checkedList } = users;

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  useEffect(() => {
    setCheckAll(checkedList.length === USER_OPTIONS.length);
    setIndeterminate(checkedList.length !== USER_OPTIONS.length);
  }, [checkedList]);

  const onChange = (list: CheckboxValueType[]) => {
    dispatch(setCheckedList(list));
    setIndeterminate(!!list.length && list.length < USER_OPTIONS.length);
    setCheckAll(list.length === USER_OPTIONS.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const defaultArray = USER_OPTIONS.filter((item) => item.default).map((item) => item.value);
    dispatch(setCheckedList(e.target.checked ? null : defaultArray));
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <div>
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        All
      </Checkbox>
      <Divider />
      <Checkbox.Group style={{ width: '100%' }} value={checkedList} onChange={onChange}>
        <Row>
          {USER_OPTIONS.map((item, index) => (
            <Col key={index} span={12}>
              <Checkbox value={item.value} disabled={item.default}>
                {item.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
};

export default ModalExport;
