import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
  total: 0,
  paramsData: {
    page: 1,
    limit: 10,
    title: '',
    startDate: null,
    endDate: null,
  },
};

const podcastSlice = createSlice({
  name: 'podcast',
  initialState: initialState,
  reducers: {
    getPodcastLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getPodcastSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data.list;
      state.total = action.payload.data.count;
    },
    getPodcastFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    setParamsPodcast: (state, action) => {
      state.paramsData = action.payload
        ? { ...state.paramsData, ...action.payload }
        : initialState.paramsData;
    },
  },
});

export const { getPodcastLoading, getPodcastSuccess, getPodcastFailure, setParamsPodcast } =
  podcastSlice.actions;
export default podcastSlice.reducer;
