import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import auth from './auth/reducers';
import users from './users/user-list/reducers';
import articles from './articles/reducers';
import blackBook from './black-book/black-book-list/reducers';
import podcast from './podcast/podcast-list/reducers';
import blackBookDetail from './black-book/black-book-detail/reducers';
import userBanEdit from './users/user-ban-edit/reducers';
import createBlackBook from './black-book/black-book-create/reducers';
import updateBlackBook from './black-book/black-book-update/reducers';
import deleteBlackBook from './black-book/black-book-delete/reducers';
import deletePodcast from './podcast/podcast-delete/reducers';
import banners from './banners/reducers';
import editBanners from './banners-edit/reducers';
import raceDetail from './upcoming-race/race-detail/reducers';
import createRace from './upcoming-race/race-create/reducers';
import updateRace from './upcoming-race/race-update/reducers';
import upcomingRace from './upcoming-race/race-list/reducers';
import upcomingRaceDelete from './upcoming-race/race-delete/reducers';
import blackBookSort from './black-book/black-book-list-sort/reducers';
import createPodcast from './podcast/podcast-create/reducers';
import podcastDetail from './podcast/podcast-detail/reducers';
import updatePodcast from './podcast/podcast-update/reducers';
import merchandise from './merchandise/merchandise-list/reducers';
import deleteMerchandise from './merchandise/merchandise-delete/reducers';
import merchandiseDetail from './merchandise/merchandise-detail/reducers';
import createMerchandise from './merchandise/merchandise-create/reducers';
import updateMerchandise from './merchandise/merchandise-update/reducers';
import exclusiveContent from './exclusive-content/exclusive-content-list/reducers';
import exclusiveContentDetail from './exclusive-content/exclusive-content-detail/reducers';
import deleteExclusiveContent from './exclusive-content/exclusive-content-delete/reducers';
import createExclusiveContent from './exclusive-content/exclusive-content-create/reducers';
import updateExclusiveContent from './exclusive-content/exclusive-content-update/reducers';
import policyDetail from './policy-setting/policy-detail/reducers';
import updatePolicy from './policy-setting/policy-update/reducers';
import pushNotiBlackBook from './black-book/black-book-push-noti/reducers';
import freeAccounts from './free-accounts/free-account-list/reducers';
import createFreeAccount from './free-accounts/free-account-create/reducers';
import editFreeAccount from './free-accounts/free-account-edit/reducers';
import banEditFreeAccount from './free-accounts/free-account-ban-edit/reducers';
import requestDelete from './request-delete/request-delete-list/reducers';
import createRequestDelete from './request-delete/request-delete-create/reducers';
import deleteRequestDelete from './request-delete/request-delete-delete/reducers';

const reducer = combineReducers({
  auth,
  articles,
  blackBook,
  podcast,
  blackBookDetail,
  users,
  userBanEdit,
  createBlackBook,
  updateBlackBook,
  deleteBlackBook,
  deletePodcast,
  banners,
  editBanners,
  upcomingRace,
  raceDetail,
  createRace,
  updateRace,
  upcomingRaceDelete,
  blackBookSort,
  createPodcast,
  podcastDetail,
  updatePodcast,
  merchandise,
  deleteMerchandise,
  merchandiseDetail,
  createMerchandise,
  updateMerchandise,
  exclusiveContent,
  exclusiveContentDetail,
  deleteExclusiveContent,
  createExclusiveContent,
  updateExclusiveContent,
  policyDetail,
  updatePolicy,
  pushNotiBlackBook,
  freeAccounts,
  createFreeAccount,
  editFreeAccount,
  banEditFreeAccount,
  requestDelete,
  createRequestDelete,
  deleteRequestDelete,
});

const store = configureStore({
  reducer,
});

export default store;
