import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  totalCount: 0,
  error: '',
  paramsData: {
    page: 1,
    limit: 10,
    sort: '',
    type: '',
    title: '',
  },
};

const upcomingRacesSlice = createSlice({
  name: 'upcomingRaces',
  initialState: initialState,
  reducers: {
    getUpcomingRacesLoading: (state) => {
      state.isLoading = true;
    },
    getUpcomingRacesSuccess: (state, action?) => {
      state.data = action.payload.list;
      state.totalCount = action.payload.count;
      state.isLoading = false;
    },
    getUpcomingRacesError: (state, action) => {
      state.isLoading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    setParamsData: (state, action) => {
      state.paramsData = action.payload
        ? { ...state.paramsData, ...action.payload }
        : initialState.paramsData;
    },
  },
});

export const {
  getUpcomingRacesLoading,
  getUpcomingRacesSuccess,
  getUpcomingRacesError,
  setParamsData,
} = upcomingRacesSlice.actions;

export default upcomingRacesSlice.reducer;
