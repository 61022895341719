import React, { useEffect } from 'react';
import { Button, Form, Input, InputNumber, Select } from 'antd';
import 'suneditor/dist/css/suneditor.min.css';
import './style.scss';
import PageTitle from '../../../components/page-title';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../types/redux';
import { useSelector, useDispatch } from 'react-redux';
import Indicator from 'components/indicator';
import BackButton from 'components/back-button';
import {
  CHECK_HTTP,
  MAX_DECIMAL_10,
  MAX_10000_EDITOR,
  REQUIRED,
  WHITE_SPACE,
  WITHOUT_SPECIAL_CHARACTER,
  WHITE_SPACE_NOT_REQUIRED,
  getMaxLengthValidate,
  CHECK_ALL_SPACE_WITHOUT_REQUIRED,
} from 'utils/rule-form';
import { getMerchandiseDetail } from 'redux/merchandise/merchandise-detail/actions';
import CustomAddImages from '../../../components/custom-add-images';
import { IImage, IUpdateMerchandise } from 'types/merchandise';
import { updateMerchandise } from 'redux/merchandise/merchandise-update/actions';
import { createMerchandise } from 'redux/merchandise/merchandise-create/actions';
import { paths } from 'routes/routes';
import TextEditor from '../../../components/text-editor/text-editor';
import { REQUIRED_MESSAGE, STATUS_OPTIONS } from 'utils/constant';
import { resetCreateMerchandiseError } from 'redux/merchandise/merchandise-create/reducers';
import { resetUpdateMerchandiseError } from 'redux/merchandise/merchandise-update/reducers';
import { resetMerchandiseDetailData } from 'redux/merchandise/merchandise-detail/reducers';

const NewMerchandise = () => {
  const { Option } = Select;
  const { id } = useParams();
  const [form] = Form.useForm();

  const merchandise = useSelector((state: RootState) => state.merchandiseDetail);
  const { loading: loadingDetail, data: dataDetail } = merchandise;
  const createMerchandiseDt = useSelector((state: RootState) => state.createMerchandise);
  const { loading: loadingCreate, error: errorCreate } = createMerchandiseDt;
  const updateMerchandiseDt = useSelector((state: RootState) => state.updateMerchandise);
  const { loading: loadingUpdate, error: errorUpdate } = updateMerchandiseDt;
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const title = id ? 'Edit Merchandise' : 'New Merchandise';
  const buttonText = id ? 'Save' : 'Add new';
  const { name, price, voucher, images, linkToShop, description } = dataDetail;

  const isLoading = loadingDetail || loadingCreate || loadingUpdate;

  const formInitValue = {
    name: id ? name : '',
    price: id ? price : '',
    voucherName: id ? voucher?.name : '',
    status: id && voucher ? voucher.status.toString() : undefined,
    images: id
      ? images.map((item: IImage) => {
          return {
            file: {
              fileUrl: item.thumbnailUrl,
              fileKey: item.thumbnailKey,
            },
          };
        })
      : [],
    description: id && description,
  };

  const onFinish = async (value: any) => {
    const { images, voucherName, status } = value;
    const params: IUpdateMerchandise = {
      ...value,
      name: value.name.trim().split(/ +/).join(' '),
      images: images.map((item: any) => {
        return {
          thumbnailKey: item.file.fileKey,
          thumbnailUrl: item.file.fileUrl,
        };
      }),
      ...(voucherName && {
        voucher: { name: voucherName, status: status },
      }),
      description: value?.description || undefined,
    };
    delete params.voucherName;
    delete params.status;
    if (id) {
      dispatch(updateMerchandise(params, id));
    } else {
      dispatch(createMerchandise(params, navigate));
    }
  };

  const changeValue = (e: any) => {
    !e.target.value && form.setFieldValue('status', undefined);
  };

  useEffect(() => {
    id && dispatch(getMerchandiseDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        voucherName: voucher?.name || '',
        status: voucher ? voucher.status.toString() : undefined,
        linkToShop,
        price,
        description,
        images: images.map((item: IImage) => {
          return {
            file: {
              fileUrl: item.thumbnailUrl,
              fileKey: item.thumbnailKey,
            },
          };
        }),
      });
    }
  }, [form, dataDetail, id]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateMerchandiseError());
      dispatch(resetUpdateMerchandiseError());
      dispatch(resetMerchandiseDetailData());
    };
  }, []);

  return (
    <div>
      <BackButton label='Back to list' sourceLink={paths.merchandise} />
      <PageTitle
        title={title}
        style={{
          padding: '5px 30px',
          width: '800px',
          color: 'white',
          backgroundColor: '#1d2451',
          marginTop: 15,
        }}
      />
      <div className='new-merchandise'>
        {isLoading ? (
          <Indicator />
        ) : (
          <Form
            form={form}
            layout='vertical'
            name='new-merchandise-form'
            className='new-merchandise_form'
            onFinish={onFinish}
            initialValues={formInitValue}
          >
            <Form.Item
              name='name'
              label='Product Name'
              rules={[REQUIRED, WHITE_SPACE, getMaxLengthValidate(100), WITHOUT_SPECIAL_CHARACTER]}
            >
              <Input />
            </Form.Item>
            <div className='new-merchandise__flex-box'>
              <Form.Item name='price' label='Price' rules={[REQUIRED, MAX_DECIMAL_10]}>
                <InputNumber min={0} precision={2} step={0.01} addonAfter='$' />
              </Form.Item>
              <Form.Item
                name='linkToShop'
                label='Link to shop'
                className='new-merchandise__link'
                rules={[REQUIRED, getMaxLengthValidate(1000), CHECK_HTTP]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className='new-merchandise__flex-box'>
              <Form.Item
                name='voucherName'
                label='Voucher code'
                className='new-merchandise__voucher'
                rules={[WHITE_SPACE_NOT_REQUIRED, getMaxLengthValidate(20)]}
              >
                <Input onChange={changeValue} />
              </Form.Item>
              <Form.Item
                name='status'
                label='Status'
                dependencies={['voucherName']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue('voucherName') && !value) {
                        return Promise.reject(new Error(REQUIRED_MESSAGE));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Select
                  placeholder='Select Status'
                  className='new-merchandise__select'
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {STATUS_OPTIONS.map((item: { label: string; key: string }) => (
                    <Option value={item.key} key={item.label}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              name='description'
              label='Description'
              rules={[CHECK_ALL_SPACE_WITHOUT_REQUIRED, MAX_10000_EDITOR]}
            >
              <TextEditor />
            </Form.Item>
            <Form.Item name='images' label='Images List' className='dynamic-field__image'>
              <CustomAddImages REQUIRE_MESSAGE={REQUIRED_MESSAGE} />
            </Form.Item>
            <div className='new-merchandise__footer'>
              {errorCreate && <p className='red-text'>{errorCreate} </p>}
              {errorUpdate && <p className='red-text'>{errorUpdate} </p>}
              <div className='new-merchandise__end'>
                <Button type='primary' htmlType='submit' className='login__button'>
                  {buttonText}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default NewMerchandise;
