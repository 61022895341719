import AxiosUtils from 'utils/axios-utils';
import { createPodcastLoading, createPodcastFailure, createPodcastSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IPodcast } from 'types/podcast';
import _ from 'lodash';
import { paths } from 'routes/routes';

export const createPodcast = (params: IPodcast, navigate: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(createPodcastLoading());
    const trueParams = _.pickBy(params, _.identity);
    const apiResponse: any = await AxiosUtils.post(API.podcast, trueParams);
    dispatch(createPodcastSuccess(apiResponse?.data?.data));
    navigate(paths.podcast);
  } catch (error: any) {
    dispatch(createPodcastFailure(error));
  }
};
