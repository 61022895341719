import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  isLoading: false,
  error: '',
};

const requestDeleteSlice = createSlice({
  name: 'createRequestDelete',
  initialState: initialState,
  reducers: {
    createrequestDeleteLoading: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    createrequestDeleteSuccess: (state, action) => {
      state.error = '';
      state.isLoading = false;
      state.data = action.payload;
    },
    createRequestDeleteFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetCreateRequestDeleteError: (state) => {
      state.error = '';
    },
  },
});

export const {
  createrequestDeleteLoading,
  createrequestDeleteSuccess,
  createRequestDeleteFailure,
  resetCreateRequestDeleteError,
} = requestDeleteSlice.actions;
export default requestDeleteSlice.reducer;
