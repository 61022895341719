import AxiosUtils from 'utils/axios-utils';
import {
  uploadMerchandiseLoading,
  uploadMerchandiseFailed,
  uploadMerchandiseSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { IUpdateMerchandise } from 'types/merchandise';

export const updateMerchandise =
  (params: IUpdateMerchandise, id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(uploadMerchandiseLoading());
      const apiResponse = (await AxiosUtils.put(`${API.merchandiseUpdate}/${id}`, params)) as any;
      dispatch(uploadMerchandiseSuccess(apiResponse?.data?.data));
      toast.success('Updated the merchandise successfully!', { autoClose: 3000 });
    } catch (error: any) {
      dispatch(uploadMerchandiseFailed(error));
    }
  };
