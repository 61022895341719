import AxiosUtils from 'utils/axios-utils';
import {
  createFreeAccountLoading,
  createFreeAccountFailure,
  createFreeAccountSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { getFreeAccounts } from '../free-account-list/actions';

export const createFreeAccount =
  (params: any, onCloseModal: any, paramsData: any, resetForm: any) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(createFreeAccountLoading());
      const apiResponse: any = await AxiosUtils.post(API.createFreeAccount, params);
      dispatch(createFreeAccountSuccess(apiResponse?.data?.data));
      dispatch(getFreeAccounts(paramsData));
      toast.success('Create Free Account successfully');
      onCloseModal(false);
      resetForm();
    } catch (error: any) {
      typeof error === 'string' && toast.error(error);
      dispatch(createFreeAccountFailure(error));
    }
  };
