import React from 'react';
import Menu from './components/menu';
import styles from './styles.module.scss';
import Profile from './components/profile';

interface Props {
  children: any;
}
const HeaderLayout = ({ children }: Props) => {
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <Menu />
        <Profile />
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default HeaderLayout;
