import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Spin, Alert, Table, Popconfirm, Space, Button, Input } from 'antd';
import getArticles from '../../../redux/articles/actions';
import { AppDispatch, RootState } from '../../../types/redux';
import { Article } from '../../../types/article';
import './style.scss';
import PageTitle from '../../../components/page-title';
import { paths } from 'routes/routes';

const Articles = () => {
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getArticles());
  }, [dispatch]);

  const articles = useSelector((state: RootState) => state.articles);
  const { data, error, loading } = articles;

  const onDelete = (id: string | number) => {
    console.log(id);
  };

  const onAdd = () => {
    navigate(paths.articleCreate);
  };

  const onSearch = (value: string) => {
    console.log(value);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (text: string | number) => <span>{text}</span>,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Body',
      key: 'body',
      render: (_: any, record: Article) => <div>{record.body}</div>,
    },
    {
      title: 'Created At',
      key: 'createdAt',
      render: (_: any, record: Article) => <div>{record.createdAt}</div>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: Article) => {
        const editLink = `/article/${record.id}/edit`;
        return (
          <Space size='middle'>
            <Link to={editLink}>Edit</Link>
            <Popconfirm title='Are you sure to delete?' onConfirm={() => onDelete(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <div className='articles'>
      <PageTitle title='Articles' />
      <Space size='middle' style={{ marginBottom: '16px' }}>
        <Button onClick={onAdd} type='primary'>
          Add new
        </Button>
        <Input.Search placeholder='Enter the title' onSearch={onSearch} />
      </Space>

      {loading ? (
        <div className='articles__loading'>
          <Spin />
        </div>
      ) : (
        <div>
          {error ? (
            <Alert message={error} type='error' />
          ) : (
            <>
              {data && data.length ? (
                <Table rowKey='id' columns={columns} dataSource={data} />
              ) : (
                <div>No data</div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Articles;
