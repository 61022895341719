import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const merchandiseSlice = createSlice({
  name: 'updateMerchandise',
  initialState: initialState,
  reducers: {
    uploadMerchandiseLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    uploadMerchandiseSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    uploadMerchandiseFailed: (state, action) => {
      state.loading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    resetUpdateMerchandiseError: (state) => {
      state.error = '';
    },
  },
});

export const {
  uploadMerchandiseLoading,
  uploadMerchandiseFailed,
  uploadMerchandiseSuccess,
  resetUpdateMerchandiseError,
} = merchandiseSlice.actions;
export default merchandiseSlice.reducer;
