import AxiosUtils from 'utils/axios-utils';
import {
  getExclusiveContentDetailLoading,
  getExclusiveContentDetailSuccess,
  getExclusiveContentDetailFailure,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';

export const getExclusiveContentDetail = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getExclusiveContentDetailLoading());
    const apiResponse = (await AxiosUtils.get(`${API.exclusiveContentDetail}/${id}`)) as any;
    dispatch(getExclusiveContentDetailSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getExclusiveContentDetailFailure(error.toString()));
  }
};
