import React, { useRef } from 'react';
import { Input } from 'antd';
import FileUpload from './file-upload';
import './style.scss';
interface Props {
  value?: {
    fileKey: string;
    fileUrl: string;
  };
  onChange?: any;
  type: string;
  maxFileSize: number;
  imageRatio?: number;
  disable?: boolean;
}

const CustomizedUploadFileControl = ({
  value,
  onChange,
  type,
  maxFileSize,
  imageRatio,
  disable,
}: Props) => {
  const progressRef = useRef<any>();
  const statusRef = useRef<any>();
  const displayProgresRef = useRef<any>();

  React.useEffect(() => {
    displayProgresRef.current.style.display = 'none';
  }, []);

  return (
    <div>
      <Input type='text' value={value?.fileUrl || ''} hidden />
      <Input type='text' value={value?.fileKey || ''} hidden />
      <FileUpload
        value={value}
        onChange={onChange}
        type={type}
        maxFileSize={maxFileSize}
        imageRatio={imageRatio}
        disable={disable}
        statusRef={statusRef}
        progressRef={progressRef}
        displayProgresRef={displayProgresRef}
      />
      <div ref={displayProgresRef} style={{ marginTop: '10px' }}>
        <span ref={statusRef}></span>
        <div className='progress-bar'>
          <div ref={progressRef} className='filler' />
        </div>
      </div>

      {/* <progress ref={progressRef} value='90' max='100' />
      <div className='progress-element progress-element--html'>
        <div className='progress-container'>
          <progress max='100' value='95'>
            95%
          </progress>
        </div>
      </div> */}
    </div>
  );
};

export default CustomizedUploadFileControl;
