import { AppDispatch } from 'types/redux';
import {
  getUpcomingRacesLoading,
  getUpcomingRacesError,
  getUpcomingRacesSuccess,
} from './reducers';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import _ from 'lodash';
import { IRacePagination } from 'types/upcoming-race';

export const getUpcomingRaces = (params: IRacePagination) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getUpcomingRacesLoading());
    const trueParams = _.pickBy(params, _.identity);

    const res: any = await AxiosUtils.get(API.upcomingRaceList, trueParams);
    dispatch(getUpcomingRacesSuccess(res?.data?.data));
  } catch (error) {
    dispatch(getUpcomingRacesError(error));
  }
};
