import AxiosUtils from 'utils/axios-utils';
import { deleteUpcomingRaceLoading, deleteUpcomingRaceFailed, getRaceSelected } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { getUpcomingRaces } from '../race-list/actions';

export const deleteUpcomingRace =
  (id: string | number, pagination: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteUpcomingRaceLoading(true));
      await AxiosUtils._delete(`${API.upcomingRaceList}/${id}`);
      toast.success('Deleted Upcoming Race success!', { autoClose: 3000 });
      dispatch(getUpcomingRaces(pagination));
    } catch (error) {
      dispatch(deleteUpcomingRaceFailed(error));
    } finally {
      dispatch(deleteUpcomingRaceLoading(false));
      dispatch(getRaceSelected(id));
    }
  };
