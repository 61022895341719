import AxiosUtils from 'utils/axios-utils';
import { updateRaceLoading, updateRaceFailed, updateRaceSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { IUpcomingRace } from 'types/upcoming-race';

export const updateRace = (params: IUpcomingRace, id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateRaceLoading());
    const apiResponse = (await AxiosUtils.put(`${API.upcomingRaceUpdate}/${id}`, params)) as any;
    dispatch(updateRaceSuccess(apiResponse?.data?.data));
    toast.success('Updated upcoming races successfully!', { autoClose: 3000 });
  } catch (error: any) {
    dispatch(updateRaceFailed(error));
  }
};
