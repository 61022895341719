import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const artilceSlice = createSlice({
  name: 'article',
  initialState: initialState,
  reducers: {
    getArticlesLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getArticlesSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    getArticlesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { getArticlesLoading, getArticlesSuccess, getArticlesFailure } = artilceSlice.actions;
export default artilceSlice.reducer;
