import { Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { REQUIRED } from 'utils/rule-form';
import countries from '../../utils/countries.json';
import './style.scss';

interface Props {
  form: any;
  countryDefault: string;
}

function SelectCountry({ form, countryDefault }: Props) {
  const { Option } = Select;
  const [stateOptions, setStateOptpions] = useState<string[]>([]);

  const handleSetListState = (value: string) => {
    const countryItem = countries.filter((item) => item.name === value)[0];
    setStateOptpions(countryItem?.state);
  };

  const handleSelectOnSearch = (selected: string) => {
    handleSetListState(selected);
    form.setFieldsValue({ state: undefined });
  };

  useEffect(() => {
    handleSetListState(countryDefault);
  }, [countryDefault]);

  return (
    <div className='select-location'>
      <Form.Item name='country' label='Country' rules={[REQUIRED]}>
        <Select
          placeholder='Country'
          style={{ width: 300 }}
          onSelect={handleSelectOnSearch}
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          {countries.map((item: any) => (
            <Option value={item.name} key={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='state' label='State' rules={[REQUIRED]}>
        <Select
          placeholder='State'
          style={{ width: 300 }}
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          {stateOptions?.map((item: string) => (
            <Option value={item} key={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export default SelectCountry;
