import React from 'react';
import { useNavigate } from 'react-router-dom';
import { removeAuth } from 'redux/auth/reducers';
import { useAppDisPatch } from '../../../utils/common';
import { deleteSession } from '../../../utils/session';
import styles from './styles.module.scss';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { paths } from 'routes/routes';

const Profile = () => {
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);

  const handleLogout = () => {
    deleteSession();
    dispatch(removeAuth());
    navigate(paths.login);
  };
  // const handleChangePassword = () => {
  //   setOpen(true);
  // };

  const MENU_ITEM = [
    { label: 'Log out', onClick: handleLogout, icon: faArrowRightFromBracket },
    // {
    //   label: 'Change Password',
    //   onClick: handleChangePassword,
    //   icon: faKey,
    // },
  ];

  return (
    <div className={styles.profile}>
      {MENU_ITEM.map((item: any, index: number) => (
        <div key={index} className={styles.profileMenu} onClick={item.onClick}>
          <FontAwesomeIcon icon={item.icon} size='lg' color={'white'} />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default Profile;
