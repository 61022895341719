import AxiosUtils from 'utils/axios-utils';
import { deletePodcastLoading, deletePodcastFailed, deletePodcastSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';

export const deletePodcast = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(deletePodcastLoading());
    const apiResponse = (await AxiosUtils._delete(`${API.podcast}/${id}`)) as any;
    dispatch(deletePodcastSuccess(apiResponse.data));
    toast.success('Deleted the podcast successfully!', { autoClose: 3000 });
  } catch (error: any) {
    toast.error('Deleted the podcast failed!', { autoClose: 3000 });
    dispatch(deletePodcastFailed(error.toString()));
  }
};
