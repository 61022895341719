import { AppDispatch } from 'types/redux';
import { getStatusLoading, getStatusError, getUserSelected } from './reducers';
import { toast } from 'react-toastify';
import { USER_STATUS } from 'utils/constant';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import { UsersTableType } from 'types/users';
import { getUsers } from 'redux/users/user-list/actions';

export const banUser =
  (id: string, pagination: UsersTableType) => async (dispatch: AppDispatch) => {
    try {
      dispatch(getStatusLoading(true));
      await AxiosUtils._delete(`${API.banUser}/${id}`);

      dispatch(getStatusError(''));

      dispatch(getUsers(pagination));

      toast.success('Ban user successfully!', { autoClose: 3000 });
    } catch (error) {
      dispatch(getStatusError(error));
    } finally {
      dispatch(getStatusLoading(false));
      dispatch(getUserSelected(id));
    }
  };

export const editUser =
  (id: string, data: { status: number }, pagination: UsersTableType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(getStatusLoading(true));
      await AxiosUtils.put(`${API.editUser}/${id}`, data);

      dispatch(getStatusError(''));

      dispatch(getUsers(pagination));
      let textAction;
      switch (data.status) {
        case USER_STATUS.ACTIVE:
        case USER_STATUS.INACTIVE:
          textAction = 'Unban';
          break;
        default:
          textAction = 'Ban';
      }
      toast.success(`${textAction} user successfully!`, { autoClose: 3000 });
    } catch (error) {
      dispatch(getStatusError(error));
    } finally {
      dispatch(getStatusLoading(false));
      dispatch(getUserSelected(id));
    }
  };

export const verifyUser =
  (id: string, data: { status: number }, pagination: UsersTableType) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(getStatusLoading(true));
      await AxiosUtils.put(`${API.editUser}/${id}`, data);

      dispatch(getStatusError(''));

      dispatch(getUsers(pagination));
      let textAction;
      switch (data.status) {
        case USER_STATUS.ACTIVE:
          textAction = 'Verify user';
          break;
        default:
          textAction = '';
      }
      toast.success(`${textAction} user successfully!`, { autoClose: 3000 });
    } catch (error) {
      dispatch(getStatusError(error));
    } finally {
      dispatch(getStatusLoading(false));
      dispatch(getUserSelected(id));
    }
  };
