import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const blackBookSlice = createSlice({
  name: 'deleteBlackBook',
  initialState: initialState,
  reducers: {
    deleteBlackBookLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    deleteBlackBookSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    deleteBlackBookFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { deleteBlackBookLoading, deleteBlackBookFailed, deleteBlackBookSuccess } =
  blackBookSlice.actions;
export default blackBookSlice.reducer;
