import React, { useEffect } from 'react';
import { Alert } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types/redux';
import { getPolicyDetail } from 'redux/policy-setting/policy-detail/actions';
import { resetUpdatePolicy } from 'redux/policy-setting/policy-update/reducers';
import { AppDispatch } from 'types/redux';
import Indicator from 'components/indicator';

const PublicPolicy = () => {
  const dispatch = useDispatch() as AppDispatch;
  const policyDetail = useSelector((state: RootState) => state.policyDetail);
  const { loading, data, error } = policyDetail;

  useEffect(() => {
    dispatch(getPolicyDetail(1));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdatePolicy());
    };
  }, []);

  return (
    <div
      className='public-policy'
      style={{ background: '#eee', minHeight: '100vh', display: 'flex', justifyContent: 'center' }}
    >
      <div style={{ backgroundColor: 'white', width: '80%', padding: '32px 24px' }}>
        {loading ? (
          <Indicator />
        ) : (
          <div>
            {error ? (
              <Alert message={error} type='error' />
            ) : (
              <div style={{ marginTop: '20px', background: 'white', padding: '32px 28px' }}>
                <h2>Jig Racing &gt; Private Policy</h2>
                <div
                  style={{
                    backgroundColor: 'black',
                    margin: '8px 0',
                    height: '1px',
                    width: '100%',
                  }}
                ></div>
                <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicPolicy;
