import React, { useEffect } from 'react';
import { Form, Button, Spin } from 'antd';
import PageTitle from '../../components/page-title';
import DynamicField from './dynamic-field';
import { isEmptyObject, useAppDisPatch } from '../../utils/common';
import saveBanners from '../../redux/banners-edit/actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import getBanners from '../../redux/banners/actions';
import { BannerFormValues, BannerItemFromApi } from '../../types/banner';
import './styles.scss';

const BannerPage = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDisPatch();

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  const editBanners = useSelector((state: RootState) => state.editBanners);
  const banners = useSelector((state: RootState) => state.banners);
  const { error, loading: getLoading, data } = banners;
  const { error: editError, loading: editLoading, data: savedData } = editBanners;
  const bannersWatch = Form.useWatch('banners', form);
  const totalBannersWatch = bannersWatch && bannersWatch.length;
  const disabledSubmitButton = !bannersWatch || totalBannersWatch === 0 || editLoading;
  const loading = getLoading || editLoading;
  const onFinish = (values: BannerFormValues) => {
    const { banners } = values;

    const bannersConvert = banners.map((item) => {
      const valueUrl = item.isInternal
        ? item.typeLink && !isEmptyObject(item.typeId)
          ? `${item.typeLink}/${item?.typeId.value}/${item.typeId.label}`
          : ''
        : item.url;
      return {
        title: item.title,
        link: {
          isInternal: item.isInternal,
          url: valueUrl || '',
          type: item.isInternal ? (item.typeLink ? item.typeLink : null) : null,
          id: item.typeId?.value && item.isInternal ? item.typeId?.value : null,
        },
        thumbnailUrl: item.file.fileUrl,
        thumbnailKey: item.file.fileKey,
        id: item?.id,
      };
    });
    dispatch(saveBanners(bannersConvert));
  };

  useEffect(() => {
    const initialBanners =
      Array.isArray(data) && data.length
        ? data.map((item: any) => {
            const isInternal = item?.link?.isInternal;
            const linkUrl = item?.link?.url;
            return {
              id: item.id,
              clickUrlCount: item?.userClick?.length,
              viewCountBanner: item?.userView?.length,
              title: item.title,
              isInternal: isInternal,
              url: (!isInternal && linkUrl) || '',
              typeLink: (isInternal && linkUrl?.split('/')[0]) || null,
              typeId:
                {
                  value: isInternal && linkUrl?.split('/')[1],
                  label: isInternal && linkUrl?.split('/')[2],
                } || null,
              file: {
                fileUrl: item.thumbnailUrl,
                fileKey: item.thumbnailKey,
              },
            };
          })
        : [];
    form.setFieldValue('banners', initialBanners);
  }, [data]);

  useEffect(() => {
    const initialBanners =
      Array.isArray(savedData) && savedData.length
        ? savedData.map((item: BannerItemFromApi) => {
            const isInternal = item?.link?.isInternal;
            const linkUrl = item?.link?.url;
            return {
              clickUrlCount: item.clickUrlCount,
              viewCountBanner: item.view,
              title: item.title,
              isInternal: isInternal,
              url: (!isInternal && linkUrl) || '',
              typeLink: (isInternal && linkUrl?.split('/')[0]) || null,
              typeId:
                {
                  value: isInternal && linkUrl?.split('/')[1],
                  label: isInternal && linkUrl?.split('/')[2],
                } || null,
              file: {
                fileUrl: item.thumbnailUrl,
                fileKey: item.thumbnailKey,
              },
            };
          })
        : [];
    form.setFieldValue('banners', initialBanners);
  }, [savedData]);

  return (
    <div className='banners'>
      <PageTitle title='Banner slideshow' />
      <div className='banners__content'>
        {loading ? (
          <div className='banners__loading'>
            <Spin />
          </div>
        ) : (
          <div>
            {error ? (
              <div className='banners__error'>{error}</div>
            ) : (
              <Form layout='vertical' form={form} onFinish={onFinish}>
                <div className='banner-item'>
                  <DynamicField />
                </div>
                {totalBannersWatch > 0 && (
                  <div>
                    <Form.Item>
                      <div className='banners__submit-button'>
                        <Button
                          disabled={disabledSubmitButton}
                          loading={editLoading}
                          type='primary'
                          htmlType='submit'
                          size='large'
                        >
                          Save {totalBannersWatch} items
                        </Button>
                      </div>
                    </Form.Item>
                  </div>
                )}
                {editError && <div className='banners__error'>{editError}</div>}
              </Form>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BannerPage;
