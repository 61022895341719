const USER_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  BANNED_INACTIVE: 2,
  BANNED_ACTIVE: 3,
};

const USER_OPTIONS = [
  {
    value: 'id',
    label: 'ID',
    default: true,
  },
  {
    value: 'firstName',
    label: 'First Name',
    default: true,
  },
  {
    value: 'lastName',
    label: 'Last Name',
    default: true,
  },
  {
    value: 'country',
    label: 'Country',
  },
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'phoneNumber',
    label: 'Phone Number',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'subscriber',
    label: 'Subscriber',
  },
];

const EXCLUSIVE_PRICE_OPTIONS = [
  {
    value: 4.99,
  },
  {
    value: 6.99,
  },
  {
    value: 9.99,
  },
  {
    value: 14.99,
  },
  {
    value: 19.99,
  },
];

const DISPLAY_FORMAT_DATE = 'DD-MM-YYYY';

const DISPLAY_FORMAT_DATETIME = 'DD-MM-YYYY HH:mm';

const DISPLAY_FORMAT_TIME = 'HH:mm';

const API_FORMAT_DATE = 'YYYY-MM-DD';

const PRIMARY_FORMAT_DATE = 'YYYY-MM-DDTHH:mm:00Z';

export const MAX_IMAGE_SIZE = 20;
export const MAX_VIDEO_SIZE = 1000;

const UPLOAD_TYPE = {
  IMAGE: 'image',
  AUDIO: 'audio',
  VIDEO: 'video',
};

const KEY_LINK_TYPE = {
  BLACKBOOK: 'blackbook',
  UPCOMING_RACE: 'upcoming_race',
  MERCHANDISE: 'merchandise',
  PODCAST: 'podcast',
};

const OPTIONS_LINK_TYPE = [
  {
    label: 'Blackbook',
    value: KEY_LINK_TYPE.BLACKBOOK,
  },
  {
    label: 'Upcoming race',
    value: KEY_LINK_TYPE.UPCOMING_RACE,
  },
  {
    label: 'Merchandise',
    value: KEY_LINK_TYPE.MERCHANDISE,
  },
  {
    label: 'Weekly podcast',
    value: KEY_LINK_TYPE.PODCAST,
  },
];

const PRICE_FILTER_MESSAGE = 'Minimum number must be lower than Maximum';
const REQUIRED_MESSAGE = 'This field is required.';
const NOT_ALLOW_SPECIAL_CHARACTERS_MESSAGE =
  'The title only accepts text, number, parentheses, comma, forward slash, dash , underscore, space.';
const NOT_BLANK_MESSAGE = 'All Blank Space Not Allowed';
const DUPLICATE_MESSAGE = 'Link or Horse information duplication not allow';

const UPCOMING_RACE_STATUS = {
  HIDE: 0,
  SHOW: 1,
};

const DELAY_SECONDS = 60;

const STATUS_OPTIONS = [
  {
    label: 'HIDDEN',
    key: '0',
  },
  {
    label: 'PUBLISHED',
    key: '1',
  },
];

const FILE_FORMAT = {
  [UPLOAD_TYPE.IMAGE]: ['png', 'jpeg', 'jpg'],
  [UPLOAD_TYPE.AUDIO]: [
    'mp3',
    'wav',
    'ogg',
    'mp4',
    'adts',
    '3gp',
    'mov',
    'flac',
    'm4a',
    'webm',
    'mpeg',
  ],
  [UPLOAD_TYPE.VIDEO]: ['mp4', 'webm', '3gp', 'ogg', 'mpeg'],
};

export {
  USER_STATUS,
  USER_OPTIONS,
  API_FORMAT_DATE,
  DISPLAY_FORMAT_DATE,
  DISPLAY_FORMAT_DATETIME,
  DISPLAY_FORMAT_TIME,
  UPLOAD_TYPE,
  KEY_LINK_TYPE,
  OPTIONS_LINK_TYPE,
  REQUIRED_MESSAGE,
  UPCOMING_RACE_STATUS,
  NOT_ALLOW_SPECIAL_CHARACTERS_MESSAGE,
  NOT_BLANK_MESSAGE,
  STATUS_OPTIONS,
  FILE_FORMAT,
  DUPLICATE_MESSAGE,
  PRIMARY_FORMAT_DATE,
  PRICE_FILTER_MESSAGE,
  EXCLUSIVE_PRICE_OPTIONS,
  DELAY_SECONDS,
};
