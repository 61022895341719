import AxiosUtils from 'utils/axios-utils';
import { saveBannersLoading, saveBannersSuccess, saveBannersFailure } from './reducers';
import { AppDispatch } from '../../types/redux';
import { API } from '../api-route';
import { toast } from 'react-toastify';
import getBanners from 'redux/banners/actions';
import { BannerItemRequest } from '../../types/banner';

const saveBanners = (banners: BannerItemRequest[]) => async (dispatch: AppDispatch) => {
  try {
    dispatch(saveBannersLoading());
    const apiResponse: any = await AxiosUtils.post(API.banner, banners);
    dispatch(saveBannersSuccess(apiResponse?.data?.data));
    toast.success('Updated the banner slideshow successfully!', { autoClose: 1500 });
    dispatch(getBanners());
  } catch (error: any) {
    dispatch(saveBannersFailure(error));
  }
};

export default saveBanners;
