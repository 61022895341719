import { getArticlesLoading, getArticlesSuccess, getArticlesFailure } from './reducers';
import { AppDispatch } from '../../types/redux';
import axios from 'axios';

const getArticles = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getArticlesLoading());
    const fakeURL = 'https://5aeb604f046d7b0014fb6e2d.mockapi.io';
    // const apiResponse = (await AxiosUtils.get('/api/articles')) as any;
    const apiResponse = (await axios.get(`${fakeURL}/articles`)) as any;
    dispatch(getArticlesSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getArticlesFailure(error.toString()));
  }
};

export default getArticles;
