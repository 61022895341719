import React, { useEffect } from 'react';
import './style.scss';
import { formatDate } from '../../../utils/common';
import { API_FORMAT_DATE, DISPLAY_FORMAT_DATE } from '../../../utils/constant';
import { IBlackBook } from '../../../types/black-book';
import { Alert, Button, DatePicker, Popconfirm, Space, Table } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import PageTitle from '../../../components/page-title';
import InputSearch from '../../../components/input-search';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../types/redux';
import { setParamsPodcast } from '../../../redux/podcast/podcast-list/reducers';
import { getPodcast } from '../../../redux/podcast/podcast-list/actions';
import { deletePodcast } from '../../../redux/podcast/podcast-delete/actions';
import { paths } from 'routes/routes';

const PodcastPage = () => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch() as AppDispatch;
  const deletePodcastData = useSelector((state: RootState) => state.deletePodcast);
  const podcast = useSelector((state: RootState) => state.podcast);
  const { data, error, loading, total, paramsData } = podcast;
  const navigate = useNavigate();

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
    onChange: (current: number, size: number) => {
      dispatch(setParamsPodcast({ paramsData, page: current, limit: size }));
    },
  };

  const handleFilterSearch = (e: any) => {
    if (!e) dispatch(setParamsPodcast({ ...paramsData, startDate: null, endDate: null }));
    else if (e?.length < 2) return;
    else {
      const startDate = formatDate(String(new Date(e[0])), API_FORMAT_DATE);
      const endDate = formatDate(String(new Date(e[1])), API_FORMAT_DATE);
      dispatch(setParamsPodcast({ ...paramsData, startDate, endDate }));
    }
  };

  const onAdd = () => {
    navigate(paths.podcastCreate);
  };

  const onSearch = (value: string) => {
    dispatch(setParamsPodcast({ ...paramsData, title: value ? value : null, page: 1 }));
  };

  const onDelete = (id: string | number) => {
    dispatch(deletePodcast(id));
  };

  const columns = [
    {
      title: 'Podcast Name',
      dataIndex: 'title',
      key: 'title',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center' as const,
      render: (text: string) => (text ? formatDate(text, DISPLAY_FORMAT_DATE) : ''),
    },
    {
      title: 'Audience',
      dataIndex: 'viewAudience',
      key: 'viewAudience',
      render: (text: string | number) => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: IBlackBook) => {
        const editLink = `/podcast/${record.id}/edit`;
        return (
          <Space size='middle'>
            <Link to={editLink}>Edit</Link>
            <Popconfirm title='Are you sure to delete?' onConfirm={() => onDelete(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getPodcast(paramsData));
  }, [dispatch, paramsData, deletePodcastData]);

  useEffect(() => {
    if (data.length === 0 && paramsData.page > 1) {
      dispatch(setParamsPodcast({ ...paramsData, page: paramsData.page - 1 }));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setParamsPodcast(null));
    };
  }, []);

  return (
    <div className='podcast'>
      <PageTitle title='Weekly Podcast' />
      <div>
        <Space size='middle' className='podcast__featureBox'>
          <div className='podcast__searchBox'>
            <Button type='primary' style={{ marginRight: '20px' }} onClick={onAdd}>
              Add new
            </Button>
            <div>
              <InputSearch
                placeholder='Search podcast by name'
                onChangeSearch={(value) => onSearch(value)}
              />
            </div>
          </div>

          <div className='podcast__filterBox'>
            <p>Filter by date:</p>
            <RangePicker
              placeholder={['From Date', 'To Date']}
              format={DISPLAY_FORMAT_DATE}
              onChange={handleFilterSearch}
            />
          </div>
        </Space>
        {error ? (
          <Alert message={error} type='error' />
        ) : (
          <Table
            rowKey='id'
            dataSource={data}
            loading={loading}
            columns={columns}
            pagination={pagination}
            bordered
            scroll={{ y: '70vh', x: 900 }}
          />
        )}
      </div>
    </div>
  );
};

export default PodcastPage;
