import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  totalCount: 0,
  error: '',
  paramsData: {
    page: 1,
    limit: 10,
  },
};

const requestDeleteSlice = createSlice({
  name: 'RequestDeleteList',
  initialState: initialState,
  reducers: {
    getRequestDeleteLoading: (state) => {
      state.isLoading = true;
      state.error = '';
    },
    getRequestDeleteSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data.users;
      state.totalCount = action.payload.data.count;
    },
    getRequestDeleteFailure: (state, action) => {
      state.isLoading = false;
      state.error = JSON.stringify(action.payload);
    },
    setParamsRequestDelete: (state, action) => {
      state.paramsData = action.payload
        ? { ...state.paramsData, ...action.payload }
        : initialState.paramsData;
    },
    setDataRequestDelete: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  getRequestDeleteLoading,
  getRequestDeleteSuccess,
  getRequestDeleteFailure,
  setParamsRequestDelete,
  setDataRequestDelete,
} = requestDeleteSlice.actions;
export default requestDeleteSlice.reducer;
