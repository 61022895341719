import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const pushNotiBlackBookSlice = createSlice({
  name: 'pushNotiBlackBook',
  initialState: initialState,
  reducers: {
    pushNotiBlackBookLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    pushNotiBlackBookSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    pushNotiBlackBookFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    pushNotiBlackBookError: (state) => {
      state.error = '';
    },
  },
});

export const {
  pushNotiBlackBookLoading,
  pushNotiBlackBookFailure,
  pushNotiBlackBookError,
  pushNotiBlackBookSuccess,
} = pushNotiBlackBookSlice.actions;
export default pushNotiBlackBookSlice.reducer;
