import React, { useEffect, useState } from 'react';
import { getUsers } from 'redux/users/user-list/actions';
import { Space, Table, Alert, Modal, Button, Spin, Row, Col, Select, Popconfirm } from 'antd';
import PageTitle from 'components/page-title';
import { UsersTableType, UsersType } from 'types/users';
import { DISPLAY_FORMAT_DATETIME, USER_OPTIONS, USER_STATUS } from 'utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'types/redux';
import { editUser, verifyUser } from 'redux/users/user-ban-edit/actions';
import type { TableProps } from 'antd';
import { setCheckedList, setParamsData } from 'redux/users/user-list/reducers';
import _ from 'lodash';
import './style.scss';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import ModalExport from './modal-export';
import { exportCSV } from 'utils/common';
import InputSearch from 'components/input-search';
import { deleteUser } from 'redux/request-delete/request-delete-delete/actions';
import moment from 'moment';

const UsersPage = () => {
  const dispatch = useDispatch() as AppDispatch;

  const users = useSelector((state: RootState) => state.users);
  const userBanEdit = useSelector((state: RootState) => state.userBanEdit);
  const { data, error, isLoading, totalCount, paramsData, checkedList } = users;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalExport, setIsModalExport] = useState(false);
  const [isModalVerifyUser, setIsModalVerifyUser] = useState(false);

  const [status, setStatus] = useState(USER_STATUS.INACTIVE);
  const [statusFilter, setStatusFilter] = useState('');
  const [userId, setUserId] = useState('');
  const [search, setSearch] = useState('');
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isModalDetail, setIsModalDetail] = useState<UsersType | null>(null);

  const onDeleteUser = (email: string) => {
    dispatch(
      deleteUser(
        {
          user_email: email,
        },
        paramsData,
        setIsModalDetail,
      ),
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as const,
      render: (_: any, record: UsersType) => (
        <span>{`${record.firstName} ${record.lastName}`}</span>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center' as const,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center' as const,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      align: 'center' as const,
    },
    {
      title: 'Subscriber',
      dataIndex: 'subscription',
      key: 'subscriber',
      align: 'center' as const,
      render: (sub: any) => {
        if (sub?.state === 'NO') return 'Non - Subscriber';
        if (sub?.state === 'EXPIRED') return 'Cancelled';
        if (sub?.state === 'IN_USE') {
          if (
            sub?.productId === 'jig_racing_subscription_group_weekly_dev' ||
            sub?.productId === 'jig_racing_subscription_group_weekly' ||
            sub?.productId === 'jig_racing_weekly' ||
            sub?.productId === 'jig_racing_subscription_weekly'
          )
            return '1 Week';
          if (
            sub?.productId === 'jig_racing_subscription_group_one_month_dev' ||
            sub?.productId === 'jig_racing_subscription_group_one_month' ||
            sub?.productId === 'jig_racing_one_month_1' ||
            sub?.productId === 'jig_racing_subscription_one_month'
          )
            return '1 Month';
          if (
            sub?.productId === 'jig_racing_subscription_group_three_months_dev' ||
            sub?.productId === 'jig_racing_subscription_group_three_months' ||
            sub?.productId === 'jig_racing_three_month_1' ||
            sub?.productId === 'jig_racing_subscription_three_months'
          )
            return '3 Months';
        }
      },
    },
    {
      title: 'Last Cancel Date',
      dataIndex: 'subscription',
      key: 'cancelDate',
      align: 'center' as const,
      render: (data: any) => {
        if (!data.cancelDate) return '';
        else {
          return moment(data.cancelDate).format(DISPLAY_FORMAT_DATETIME);
        }
      },
    },
    {
      title: 'Last Purchase Date',
      dataIndex: 'subscription',
      key: 'purchaseDate',
      align: 'center' as const,
      render: (data: any) => {
        console.log(data);
        if (!data.purchaseDate) return '';
        else {
          return moment(data.purchaseDate).format(DISPLAY_FORMAT_DATETIME);
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center' as const,
      width: '20%',
      render: (status: number, record: UsersType) => {
        let valueBan: number;
        switch (status) {
          case USER_STATUS.ACTIVE:
            valueBan = USER_STATUS.BANNED_ACTIVE;
            break;
          case USER_STATUS.INACTIVE:
            valueBan = USER_STATUS.BANNED_INACTIVE;
            break;
          case USER_STATUS.BANNED_ACTIVE:
            valueBan = USER_STATUS.ACTIVE;
            break;
          default:
            valueBan = USER_STATUS.INACTIVE;
        }

        const isUserBanned =
          status == USER_STATUS.BANNED_ACTIVE || status == USER_STATUS.BANNED_INACTIVE;

        const textStatus =
          status == USER_STATUS.ACTIVE ? (
            <Button
              type='primary'
              shape='round'
              size='small'
              style={{
                textAlign: 'center',
                marginBottom: 4,
                backgroundColor: '#00d969',
                borderColor: '#00d969',
              }}
            >
              Active
            </Button>
          ) : status == USER_STATUS.INACTIVE ? (
            <div>
              <Button
                type='primary'
                shape='round'
                size='small'
                style={{
                  textAlign: 'center',
                  marginBottom: 4,
                  backgroundColor: '#8f28d4',
                  borderColor: '#8f28d4',
                }}
              >
                Inactive
              </Button>
            </div>
          ) : (
            ''
          );

        const buttonAction = (
          <div>
            {isUserBanned ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  type='primary'
                  shape='round'
                  size='small'
                  style={{
                    color: 'red',
                    width: 90,
                    marginBottom: '8px',
                    backgroundColor: '#f2ee07',
                    border: 'none',
                  }}
                >
                  Banned
                </Button>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '4px' }}>
                  <Button
                    onClick={() => handleChangeStatus(valueBan, record.id)}
                    shape='round'
                    size='small'
                    style={{ width: 70, color: 'black' }}
                  >
                    {isUserBanned ? 'Unban' : 'Ban'}
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {textStatus && <div style={{ marginBottom: '4px' }}>{textStatus}</div>}
                <div
                  style={
                    status == USER_STATUS.INACTIVE
                      ? { display: 'flex', flexDirection: 'row-reverse', gap: '4px' }
                      : {}
                  }
                >
                  <Button
                    onClick={() => handleChangeStatus(valueBan, record.id)}
                    shape='round'
                    size='small'
                    style={{ width: 70, color: 'black' }}
                  >
                    {isUserBanned ? 'Unban' : 'Ban'}
                  </Button>
                  {status == USER_STATUS.INACTIVE ? (
                    <Button
                      onClick={() => handleVerifyUser(USER_STATUS.ACTIVE, record.id)}
                      shape='round'
                      size='small'
                      style={{
                        color: 'black',
                        textAlign: 'center',
                        marginBottom: 4,
                        width: 70,
                      }}
                    >
                      Verify
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
          </div>
        );

        return (
          <div>
            {userBanEdit.isLoading && record.id === userId ? (
              <Spin />
            ) : (
              <div className=''>
                {buttonAction}
                {userBanEdit.error && record.id === userBanEdit.userSelected && (
                  <Alert
                    message={userBanEdit.error}
                    type='error'
                    style={{ marginTop: '8px', fontSize: '12px' }}
                  />
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'detail',
      key: 'detail',
      width: 150,
      align: 'center' as const,
      render: (_: any, record: UsersType) => (
        <Button type='ghost' onClick={() => setIsModalDetail(record)}>
          View Detail
        </Button>
      ),
    },
  ];

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: totalCount,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
  };

  useEffect(() => {
    dispatch(getUsers(paramsData));
  }, [paramsData]);

  useEffect(() => {
    return () => {
      dispatch(setParamsData(null));
    };
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
    const data = { status };
    dispatch(editUser(userId, data, paramsData));
  };

  const handleOkVerifyUser = () => {
    setIsModalVerifyUser(false);
    const data = { status };
    dispatch(verifyUser(userId, data, paramsData));
  };

  const handleChangeStatus = (value: number, id: string) => {
    setIsModalVisible(true);
    setStatus(value);
    setUserId(id);
  };

  const handleVerifyUser = (value: number, id: string) => {
    setIsModalVerifyUser(true);
    setStatus(value);
    setUserId(id);
  };

  const handleTableChange: TableProps<UsersType>['onChange'] = (
    pagination,
    filter,
    sorter: any,
  ) => {
    const currentSort = {
      sort: sorter.order ? sorter.field : '',
      type: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
    };
    const previousSort = _.pick(paramsData, ['sort', 'type']);
    const currentPage = (_.isEqual(currentSort, previousSort) && pagination.current) || 1;

    const params: UsersTableType = {
      page: currentPage,
      limit: pagination.pageSize || 10,
      name: search,
      status: statusFilter,
      ...currentSort,
    };
    dispatch(setParamsData(params));
  };

  const onSearch = (value: string) => {
    setSearch(value);
    dispatch(setParamsData({ ...paramsData, name: value, page: 1 }));
  };

  const changeColorRow = (record: UsersType) => {
    const isUserBanned =
      record.status == USER_STATUS.BANNED_ACTIVE || record.status == USER_STATUS.BANNED_INACTIVE;
    const statusColor = isUserBanned
      ? 'users-page_banned'
      : record.status == USER_STATUS.INACTIVE
      ? 'users-page_inactive'
      : '';
    return statusColor;
  };

  async function handleExport() {
    setExportLoading(true);

    const bodyExport = {
      fields: checkedList.map((item) => {
        if (item === 'subscriber') return 'subscription';
        return item;
      }),
      isExportFull: true,
    };

    const res: any = await AxiosUtils.post(API.export, bodyExport);

    const dataUsers: Array<UsersType> = res.data;

    if (!dataUsers || !dataUsers.length) return;

    exportCSV(dataUsers, 'Users List');

    dispatch(setCheckedList(null));
    setIsModalExport(false);
    setExportLoading(false);
  }

  const handleChangeFilter = (e: any) => {
    const status = e.toString();
    setStatusFilter(status);
    dispatch(setParamsData({ ...paramsData, status: status, page: 1 }));
  };

  const textActionStatus = status == USER_STATUS.ACTIVE ? 'unban' : 'ban';

  return (
    <div className='users-page'>
      <PageTitle title='Users' />
      <Space size='middle' className='users-page_space'>
        <div className='users-page_space--search'>
          <Button type='primary' onClick={() => setIsModalExport(true)}>
            Export Data
          </Button>
          <InputSearch
            placeholder='Search user by name'
            onChangeSearch={(value) => onSearch(value)}
          />
        </div>
        <div className='users-page_space--filter'>
          <span className='users-page_space--filter-label'>Filter by status:</span>
          <Select
            defaultValue=''
            style={{ width: 150 }}
            onChange={handleChangeFilter}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <Select.Option value={''}>All</Select.Option>
            <Select.Option value={USER_STATUS.INACTIVE}>Inactive</Select.Option>
            <Select.Option value={USER_STATUS.ACTIVE}>Active</Select.Option>
            <Select.Option value={USER_STATUS.BANNED_INACTIVE}>Banned Inactive</Select.Option>
            <Select.Option value={USER_STATUS.BANNED_ACTIVE}>Banned Active</Select.Option>
          </Select>
        </div>
      </Space>
      {error ? (
        <Alert message={error} type='error' />
      ) : (
        <Table
          columns={columns}
          dataSource={data.slice(0, paramsData.limit)}
          rowKey='id'
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName={changeColorRow}
          scroll={{ y: '70vh', x: 900 }}
          bordered
        />
      )}

      <Modal
        title='Confirm'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <p>Are you sure to {textActionStatus} this user?</p>
      </Modal>

      <Modal
        title='Confirm'
        visible={isModalVerifyUser}
        onOk={handleOkVerifyUser}
        onCancel={() => setIsModalVerifyUser(false)}
      >
        <p>Are you sure to activate this user?</p>
      </Modal>

      <Modal
        title='Users CSV Export Settings'
        visible={isModalExport}
        onOk={handleExport}
        onCancel={() => setIsModalExport(false)}
      >
        <ModalExport />
        <div className='users-page__loading'>{exportLoading ? <Spin></Spin> : ''}</div>
      </Modal>

      <Modal
        title='User Detail'
        open={!!isModalDetail}
        width={800}
        footer={null}
        onCancel={() => setIsModalDetail(null)}
      >
        {isModalDetail ? (
          <div>
            <div className='users-page_info'>
              {USER_OPTIONS.map((item, index) => {
                let textValue;
                switch (item.value) {
                  case 'subscriber':
                    if (isModalDetail?.subscription?.state === 'NO') textValue = 'Non - Subscriber';
                    if (isModalDetail?.subscription?.state === 'EXPIRED') textValue = 'CANCELLED';
                    if (isModalDetail?.subscription?.state === 'IN_USE') {
                      if (
                        isModalDetail?.subscription?.productId ===
                          'jig_racing_subscription_group_weekly_dev' ||
                        isModalDetail?.subscription.productId ===
                          'jig_racing_subscription_group_weekly' ||
                        isModalDetail?.subscription.productId === 'jig_racing_weekly' ||
                        isModalDetail?.subscription.productId === 'jig_racing_subscription_weekly'
                      )
                        textValue = '1 WEEK';
                      if (
                        isModalDetail?.subscription?.productId ===
                          'jig_racing_subscription_group_one_month_dev' ||
                        isModalDetail?.subscription.productId ===
                          'jig_racing_subscription_group_one_month' ||
                        isModalDetail?.subscription.productId === 'jig_racing_one_month_1' ||
                        isModalDetail?.subscription.productId ===
                          'jig_racing_subscription_one_month'
                      )
                        textValue = '1 MONTH';
                      if (
                        isModalDetail?.subscription?.productId ===
                          'jig_racing_subscription_group_three_months_dev' ||
                        isModalDetail?.subscription.productId ===
                          'jig_racing_subscription_group_three_months' ||
                        isModalDetail?.subscription.productId === 'jig_racing_three_month_1' ||
                        isModalDetail?.subscription.productId ===
                          'jig_racing_subscription_three_months'
                      )
                        textValue = '3 MONTHS';
                    }
                    break;
                  case 'status':
                    textValue =
                      isModalDetail[item.value] === USER_STATUS.ACTIVE
                        ? 'Active'
                        : isModalDetail[item.value] === USER_STATUS.INACTIVE
                        ? 'Inactive'
                        : 'Banned';
                    break;
                  default:
                    textValue = isModalDetail[item.value];
                    break;
                }
                return (
                  <Row className='users-page_info--row' key={index}>
                    <Col span={8}>
                      <strong>{item.label}</strong>
                    </Col>
                    <Col span={12}>
                      <p>{textValue}</p>
                    </Col>
                  </Row>
                );
              })}
            </div>
            <div className='users-page_detail-modal-footer'>
              <Popconfirm
                title='Are you sure to delete?'
                onConfirm={() => {
                  onDeleteUser(isModalDetail.email);
                }}
              >
                <Button type='primary' danger>
                  Delete This User
                </Button>
              </Popconfirm>
              <Button type='primary' onClick={() => setIsModalDetail(null)}>
                Ok
              </Button>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

export default UsersPage;
