import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const exclusiveContentSlice = createSlice({
  name: 'createExclusiveContent',
  initialState: initialState,
  reducers: {
    createExclusiveContentLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    createExclusiveContentSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    createExclusiveContentFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCreateExclusiveContentError: (state) => {
      state.error = '';
    },
  },
});

export const {
  createExclusiveContentLoading,
  createExclusiveContentSuccess,
  createExclusiveContentFailure,
  resetCreateExclusiveContentError,
} = exclusiveContentSlice.actions;
export default exclusiveContentSlice.reducer;
