import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../types/redux';
import moment, { Moment } from 'moment';
import AxiosUtils from './axios-utils';
import {
  DISPLAY_FORMAT_DATE,
  NOT_ALLOW_SPECIAL_CHARACTERS_MESSAGE,
  NOT_BLANK_MESSAGE,
  PRIMARY_FORMAT_DATE,
  REQUIRED_MESSAGE,
} from './constant';
import { FileUploadTypes } from 'types/common';
import axios from 'axios';

export const useAppDisPatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const formatDate = (date?: any, format?: string) => {
  if (!date) return '';
  const convertDate = new Date(date);
  const newDate = moment(convertDate)
    .utc()
    .format(format ? format : DISPLAY_FORMAT_DATE);
  return newDate;
};

export const formatLocalDate = (date?: any, format?: string) => {
  if (!date) return '';
  const newDate = moment(date, PRIMARY_FORMAT_DATE).local().format(format);
  return newDate;
};

export const combineDateAndTimeMomentToString = (date: Moment, hour: Moment, minute: Moment) => {
  return date
    .local()
    .set({
      hour: hour.get('hour'),
      minute: minute.get('minute'),
      second: 0,
      millisecond: 0,
    })
    .format();
};

export const combineDateAndTimeMoment = (date: Moment, hour: Moment, minute: Moment) => {
  if (date && hour && minute) {
    return date.local().set({
      hour: hour.get('hour'),
      minute: minute.get('minute'),
      second: 0,
      millisecond: 0,
    });
  }
  return undefined;
};

export const handleUploadFile = async (
  file: any,
  limit?: string,
  statusRef?: any,
  progressRef?: any,
  displayProgresRef?: any,
  controller?: any,
): Promise<any> => {
  if (controller.current) {
    controller.current.abort();
  }

  controller.current = new AbortController();

  const res = (await AxiosUtils.postS3('/common/s3/upload', {
    name: file?.name,
    type: file?.type.split('/')[0],
  })) as any;

  progressRef.current.style.width = `${0}%`;
  displayProgresRef.current.style.display = 'block';

  await axios.request({
    signal: controller.current.signal,
    method: 'PUT',
    url: res?.data?.data?.uploadUrl,
    data: file,
    onUploadProgress: (p) => {
      const percent = Math.round((p.loaded / p.total) * 100);

      statusRef.current.innerText = `Uploading... ${percent}% (${Math.round(
        p.loaded / (1024 * 1024),
      )}MB / ${Math.round(p.total / (1024 * 1024))}MB)`;

      progressRef.current.style.width = `${percent}%`;

      if (p.loaded === p.total) displayProgresRef.current.style.display = 'none';
    },
  });

  controller.current = null;

  return {
    data: {
      data: {
        fileUrl: res?.data?.data?.fileUrl,
        fileKey: res?.data?.data?.fileUrl,
      },
    },
  };
};

export const deleteS3File = async (key: string): Promise<any> => {
  return (await AxiosUtils.postS3('/common/s3/delete-file', { key })) as any;
};

export const checkHaveHttp = (str: string) => {
  if ((str && str.indexOf('http://') == 0) || (str && str.indexOf('https://') == 0)) {
    return true;
  }
  return false;
};
export const exportCSV = (data: Array<any>, fileName: string) => {
  const csvContent = 'data:text/csv;charset=utf-8,' + data;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const checkFile = (_: any, value: FileUploadTypes) => {
  if (value?.error) {
    return Promise.reject(new Error(value?.error));
  }
  if (value?.fileUrl) {
    return Promise.resolve();
  }

  return Promise.reject(new Error(REQUIRED_MESSAGE));
};

export const validateWithoutSpecialCharacters = (_: any, value: string) => {
  // eslint-disable-next-line
  const regexWithoutSpecialCharacters = /[`!@#$%^&*+\=\[\]{};':"\\|.<>\?~]/;
  if (!regexWithoutSpecialCharacters.test(value)) return Promise.resolve();
  else return Promise.reject(new Error(NOT_ALLOW_SPECIAL_CHARACTERS_MESSAGE));
};

const getInnerText = (text: string) => {
  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = text;
  return tempDivElement.textContent || '';
};

export const checkAllSpaceEditor = (_: any, value: string) => {
  const textTest = getInnerText(value);
  const regexWithoutSpace = /^\s+$/;
  if (!regexWithoutSpace.test(textTest)) return Promise.resolve();
  return Promise.reject(new Error(REQUIRED_MESSAGE));
};

export const checkEmailSpaceRequire = (_: any, value: string) => {
  if (value.trim().length === 0) {
    return Promise.reject(new Error(REQUIRED_MESSAGE));
  }
  const emailValidate = String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
  if (emailValidate) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Please enter valid email'));
};

export const checkPassword = (_: any, value: string) => {
  if (value.length === 0) {
    return Promise.reject(new Error(REQUIRED_MESSAGE));
  }
  if (/\s/g.test(value)) {
    return Promise.reject(new Error('The password can not contain space'));
  }
  if (value.length < 8 || value.length > 30) {
    return Promise.reject(new Error('Password must be in 8-30 characters range'));
  }
  return Promise.resolve();
};

export const checkAllSpaceWithoutRequireEditor = (_: any, value: string) => {
  const textTest = getInnerText(value);
  const regexWithoutSpace = /^\s+$/;
  if (!regexWithoutSpace.test(textTest)) return Promise.resolve();
  return Promise.reject(new Error(NOT_BLANK_MESSAGE));
};

export const checkRequiredEditor = (_: any, value: string) => {
  const textContent = getInnerText(value);
  if (textContent) return Promise.resolve();
  return Promise.reject(new Error(REQUIRED_MESSAGE));
};

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const checkMaxEditor = (_: any, value: string) => {
  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = value;
  const textTest = tempDivElement.textContent || '';

  if (textTest.length <= 10000) return Promise.resolve();

  return Promise.reject(
    new Error(
      'Inputted text exceeds the character limit. Please enter less than 10000 characters.',
    ),
  );
};
