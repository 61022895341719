import React, { useEffect, useState } from 'react';
import PageTitle from './../../../components/page-title';
import { Alert, Space, Table, TableProps } from 'antd';
// import InputSearch from 'components/input-search';
import './style.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'types/redux';
import { useSelector } from 'react-redux';
import { setParamsRequestDelete } from 'redux/request-delete/request-delete-list/reducers';
import { getRequestDelete } from 'redux/request-delete/request-delete-list/actions';
// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UsersTableType, UsersType } from 'types/users';
import _ from 'lodash';

const RequestDelete = () => {
  const dispatch = useDispatch() as AppDispatch;
  const [search, setSearch] = useState('');
  const requestDelete = useSelector((state: RootState) => state.requestDelete);
  const { data, error, isLoading, totalCount, paramsData } = requestDelete;
  // const loading = false;

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: totalCount,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
  };

  // const onSearch = (value: string) => {
  //   setSearch(value);
  //   dispatch(setParamsRequestDelete({ ...paramsData, name: value, page: 1 }));
  // };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    // {
    //   title: 'Detail/Action',
    //   key: 'detail',
    //   align: 'center' as const,
    //   render: (_: any, record: any) => {
    //     return (
    //       <div>
    //         <div className='free-accounts-page__table-action'>
    //           {loading ? (
    //             <Spin />
    //           ) : (
    //             <Popconfirm
    //               title='Are you sure to delete?'
    //               onConfirm={() => {
    //                 console.log('run this');
    //               }}
    //             >
    //               <FontAwesomeIcon icon={faTrashAlt} size='lg' color={'black'} />
    //             </Popconfirm>
    //           )}
    //           <Alert
    //             message={'this is error'}
    //             type='error'
    //             style={{ marginTop: '8px', fontSize: '12px' }}
    //           />
    //         </div>
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    dispatch(getRequestDelete(paramsData));
  }, [paramsData]);

  useEffect(() => {
    return () => {
      dispatch(setParamsRequestDelete(null));
    };
  }, []);

  useEffect(() => {
    if (data.length === 0 && paramsData.page > 1) {
      dispatch(getRequestDelete({ ...paramsData, page: paramsData.page - 1 }));
    }
  }, [data]);

  const handleTableChange: TableProps<UsersType>['onChange'] = (
    pagination,
    filter,
    sorter: any,
  ) => {
    const currentSort = {
      sort: sorter.order ? sorter.field : '',
      type: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
    };
    const previousSort = _.pick(paramsData, ['sort', 'type']);
    const currentPage = (_.isEqual(currentSort, previousSort) && pagination.current) || 1;

    const params: UsersTableType = {
      page: currentPage,
      limit: pagination.pageSize || 10,
      name: search,
      ...currentSort,
    };
    dispatch(setParamsRequestDelete(params));
  };

  return (
    <div className='free-accounts-page'>
      <PageTitle title='REQUEST DELETE' />
      <Space size='middle' className='users-page_space'>
        <div className='users-page_space--search'>
          {/* <InputSearch placeholder='Search by name' onChangeSearch={onSearch} /> */}
        </div>
      </Space>
      {error ? (
        <Alert message={error} type='error' />
      ) : (
        <Table
          columns={columns}
          dataSource={data.slice(0, paramsData.limit)}
          rowKey='id'
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ y: '70vh', x: 900 }}
          bordered
        />
      )}
    </div>
  );
};

export default RequestDelete;
