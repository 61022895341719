import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { login } from 'redux/auth/actions';
import { useAppDisPatch } from '../../utils/common';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import logo from '../../assets/icons/logo-black.svg';
import './styles.scss';
import { Session } from '../../types/common';
import { getSession } from '../../utils/session';
import { REQUIRED } from 'utils/rule-form';
import { paths } from 'routes/routes';

interface FormValues {
  email: string;
  password: string;
}

const LoginPage = () => {
  const dispatch = useAppDisPatch();
  const navigate = useNavigate();
  const session: Session = getSession();

  useEffect(() => {
    if (session && session.accessToken) {
      navigate(paths.home);
    }
  }, [session]);

  const auth = useSelector((state: RootState) => state.auth);
  const { error, isLoading } = auth;

  const onLogin = (values: FormValues) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    dispatch(login(data, navigate));
  };

  return (
    <div className='login'>
      <Form layout='vertical' name='login-form' className='login__form' onFinish={onLogin}>
        <div className='login__logo'>
          <img src={logo} alt='logo' />
        </div>
        <Form.Item name='email' label='Email' rules={[REQUIRED]}>
          <Input className='login__inputStyle' />
        </Form.Item>
        <Form.Item label='Password' name='password' rules={[REQUIRED]}>
          <Input.Password />
        </Form.Item>

        <Button
          loading={isLoading}
          disabled={isLoading}
          className='login__btn_login'
          type='primary'
          block
          size='middle'
          htmlType='submit'
        >
          Log in
        </Button>
        {error && <div className='login__error'>{error}</div>}
      </Form>
    </div>
  );
};

export default LoginPage;
