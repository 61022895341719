import AxiosUtils from 'utils/axios-utils';
import {
  pushNotiBlackBookLoading,
  pushNotiBlackBookFailure,
  pushNotiBlackBookSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IPushNotiBlackBook } from 'types/black-book';
import { toast } from 'react-toastify';

export const pushNotiBlackBook = (params: IPushNotiBlackBook) => async (dispatch: AppDispatch) => {
  try {
    dispatch(pushNotiBlackBookLoading());
    const apiResponse: any = await AxiosUtils.post(API.blackBookPushNoti, params);
    dispatch(pushNotiBlackBookSuccess(apiResponse?.data?.data));
    toast.success('Push Black Book notification successfully!', { autoClose: 3000 });
  } catch (error: any) {
    dispatch(pushNotiBlackBookFailure(error));
  }
};
