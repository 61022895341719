import React from 'react';
import PageTitle from '../../components/page-title';

const HomePage = () => {
  return (
    <div>
      <PageTitle title='Dashboard' />
      <div>
        <h2>Hi there, welcome to Jig Racing!</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis vestibulum augue massa
          sed aenean.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
