import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
  total: 0,
  paramsData: {
    page: 1,
    limit: 10,
    name: '',
    startDate: '',
    endDate: '',
    startPrice: null,
    endPrice: null,
  },
};

const merchandiseSlice = createSlice({
  name: 'merchandise',
  initialState: initialState,
  reducers: {
    getMerchandiseLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getMerchandiseSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data.list;
      state.total = action.payload.data.count;
    },
    getMerchandiseFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    setParamsMerchandise: (state, action) => {
      state.paramsData = action.payload
        ? { ...state.paramsData, ...action.payload }
        : initialState.paramsData;
    },
    setDataMerchandise: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  getMerchandiseLoading,
  getMerchandiseSuccess,
  getMerchandiseFailure,
  setParamsMerchandise,
  setDataMerchandise,
} = merchandiseSlice.actions;
export default merchandiseSlice.reducer;
