import AxiosUtils from 'utils/axios-utils';
import { getRaceDetailLoading, getRaceDetailSuccess, getRaceDetailFailure } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';

export const getRaceDetail = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getRaceDetailLoading());
    const apiResponse = (await AxiosUtils.get(`${API.upcomingRaceDetail}/${id}`)) as any;
    dispatch(getRaceDetailSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getRaceDetailFailure(error.toString()));
  }
};
