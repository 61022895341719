import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
  total: 0,
  paramsData: {
    page: 1,
    limit: 10,
    title: '',
    status: '',
    displayTimeStart: '',
    displayTimeEnd: '',
    purchaseTimeStart: '',
    purchaseTimeEnd: '',
  },
};

const exclusiveContentSlice = createSlice({
  name: 'exclusiveContent',
  initialState: initialState,
  reducers: {
    getExclusiveContentLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getExclusiveContentSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data.list;
      state.total = action.payload.data.count;
    },
    getExclusiveContentFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    setParamsExclusiveContent: (state, action) => {
      state.paramsData = action.payload
        ? { ...state.paramsData, ...action.payload }
        : initialState.paramsData;
    },
    setDataExclusiveContent: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  getExclusiveContentLoading,
  getExclusiveContentSuccess,
  getExclusiveContentFailure,
  setParamsExclusiveContent,
  setDataExclusiveContent,
} = exclusiveContentSlice.actions;
export default exclusiveContentSlice.reducer;
