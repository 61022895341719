import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const blackBookSlice = createSlice({
  name: 'createBlackBook',
  initialState: initialState,
  reducers: {
    createBlackBookLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    createBlackBookSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    createBlackBookFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCreateBlackBookError: (state) => {
      state.error = '';
    },
  },
});

export const {
  createBlackBookLoading,
  createBlackBookSuccess,
  createBlackBookFailure,
  resetCreateBlackBookError,
} = blackBookSlice.actions;
export default blackBookSlice.reducer;
