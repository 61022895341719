import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
  total: 0,
  paramsData: {
    page: 1,
    limit: 10,
    name: '',
    startDate: '',
    endDate: '',
  },
};

const blackBookSlice = createSlice({
  name: 'blackBook',
  initialState: initialState,
  reducers: {
    getBlackBookLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getBlackBookSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data.list;
      state.total = action.payload.data.count;
    },
    getBlackBookFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    setParamsBlackBook: (state, action) => {
      state.paramsData = action.payload
        ? { ...state.paramsData, ...action.payload }
        : initialState.paramsData;
    },
    setDataBlackBook: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const {
  getBlackBookLoading,
  getBlackBookSuccess,
  getBlackBookFailure,
  setParamsBlackBook,
  setDataBlackBook,
} = blackBookSlice.actions;
export default blackBookSlice.reducer;
