import React from 'react';
import './style.scss';
import PageTitle from '../../../components/page-title';
interface Props {
  id?: string | number;
}
const DetailArticle = (props: Props) => {
  const { id } = props;
  console.log(111, id);
  return (
    <div>
      <PageTitle title='Article detail' />
    </div>
  );
};

export default DetailArticle;
