import AxiosUtils from 'utils/axios-utils';
import {
  getRequestDeleteFailure,
  getRequestDeleteLoading,
  getRequestDeleteSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { ParamsTableType } from 'types/request-delete';

export const getRequestDelete = (params: ParamsTableType) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getRequestDeleteLoading());
    const apiResponse = (await AxiosUtils.get(API.requestDelete, params)) as any;
    dispatch(getRequestDeleteSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getRequestDeleteFailure(error.toString()));
  }
};
