import React, { useEffect } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import 'suneditor/dist/css/suneditor.min.css';
import './style.scss';
import PageTitle from '../../../components/page-title';
import { useParams, useNavigate } from 'react-router-dom';
import { getBlackBookDetail } from 'redux/black-book/black-book-detail/actions';
import { updateBlackBook } from 'redux/black-book/black-book-update/actions';
import { AppDispatch, RootState } from '../../../types/redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { IUpdateBlackBook } from 'types/black-book';
import { createBlackBook } from 'redux/black-book/black-book-create/actions';
import Indicator from 'components/indicator';
import moment from 'moment';
import { formatDate } from 'utils/common';
import {
  API_FORMAT_DATE,
  DISPLAY_FORMAT_DATE,
  REQUIRED_MESSAGE,
  STATUS_OPTIONS,
  // UPLOAD_TYPE,
} from 'utils/constant';
// import CustomizedUploadFileControl from '../../../components/customized-upload-file-control';
import BackButton from 'components/back-button';
import SelectCountry from 'components/select-location';
import {
  getMaxLengthValidate,
  REQUIRED,
  WHITE_SPACE,
  WHITE_SPACE_NOT_REQUIRED,
  WITHOUT_SPECIAL_CHARACTER,
} from 'utils/rule-form';
import { paths } from 'routes/routes';
import { resetUpdateBlackBookError } from 'redux/black-book/black-book-update/reducers';
import { resetCreateBlackBookError } from 'redux/black-book/black-book-create/reducers';

const NewBlackBook = () => {
  const { Option } = Select;
  const { id } = useParams();
  const [form] = Form.useForm();

  const blackBook = useSelector((state: RootState) => state.blackBookDetail);
  const { loading: blackBookLoading, data: blackBookDetail } = blackBook;
  const { name, description, country, state, trackLocation, status, date } = blackBookDetail;
  const createBlackBookDt = useSelector((state: RootState) => state.createBlackBook);
  const { loading: createLoading, error: createError } = createBlackBookDt;
  const updateBlackBookDt = useSelector((state: RootState) => state.updateBlackBook);
  const { loading: updateLoading, error: updateError } = updateBlackBookDt;
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const { TextArea } = Input;
  const title = id ? 'Edit Black book' : 'New Black book';
  const buttonText = id ? 'Save' : 'Add new';
  const dateDetail = moment(date).utc();

  const isLoading = blackBookLoading || createLoading || updateLoading;

  const formInitValue = {
    name: id ? name : '',
    description: id ? description : '',
    country: id ? country : '',
    state: id ? state : '',
    trackLocation: id ? trackLocation : '',
    status: id ? status.toString() : '1',
    date: id ? dateDetail : '',
  };

  const onFinish = async (value: any) => {
    const date = formatDate(value.date, API_FORMAT_DATE);
    const trimName = value.name.trim().split(/ +/).join(' ');
    const params: IUpdateBlackBook = {
      name: trimName,
      description: value.description,
      country: value.country,
      state: value.state,
      status: value.status,
      date,
      ...(value.trackLocation && { trackLocation: value.trackLocation }),
    };
    if (id) {
      dispatch(updateBlackBook(params, id));
    } else {
      dispatch(createBlackBook(params, navigate));
    }
  };

  useEffect(() => {
    id && dispatch(getBlackBookDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        name,
        description,
        country,
        state,
        trackLocation,
        status: status.toString(),
        date: date ? dateDetail : '',
      });
    }
  }, [form, blackBookDetail, id]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateBlackBookError());
      dispatch(resetCreateBlackBookError());
    };
  }, []);

  return (
    <div>
      <BackButton label='Back to list' sourceLink={paths.blackBook} />
      <PageTitle
        title={title}
        style={{
          padding: '5px 30px',
          width: '800px',
          color: 'white',
          backgroundColor: '#1d2451',
          marginTop: 15,
        }}
      />
      <div className='black-book'>
        {isLoading ? (
          <Indicator />
        ) : (
          <Form
            form={form}
            layout='vertical'
            name='black-book-form'
            className='black-book__form'
            onFinish={onFinish}
            initialValues={formInitValue}
          >
            <Form.Item
              name='name'
              label='Name'
              rules={[REQUIRED, WHITE_SPACE, getMaxLengthValidate(100), WITHOUT_SPECIAL_CHARACTER]}
            >
              <Input />
            </Form.Item>
            <SelectCountry form={form} countryDefault={formInitValue.country} />
            <Form.Item
              name='trackLocation'
              label='Track location'
              rules={[
                WHITE_SPACE_NOT_REQUIRED,
                getMaxLengthValidate(50),
                WITHOUT_SPECIAL_CHARACTER,
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: REQUIRED_MESSAGE }]}
              name='date'
              label='Start Date'
            >
              <DatePicker format={DISPLAY_FORMAT_DATE} style={{ width: '100%' }} />
            </Form.Item>
            {/*<Form.Item name='thumbnail' label='Image (Only png/jpg/jepg, Maxsize: 5 MB)'>*/}
            {/*  <CustomizedUploadFileControl type={UPLOAD_TYPE.IMAGE} maxFileSize={5} />*/}
            {/*</Form.Item>*/}
            <Form.Item
              name='description'
              label='Description'
              rules={[REQUIRED, WHITE_SPACE, getMaxLengthValidate(255)]}
            >
              <TextArea autoSize={{ minRows: 5 }} />
            </Form.Item>

            <div className='black-book__footer'>
              {createError && <p className='red-text'>{createError} </p>}
              {updateError && <p className='red-text'>{updateError} </p>}
              <div className='black-book__end'>
                <Button type='primary' htmlType='submit' className='login__button'>
                  {buttonText}
                </Button>
                <Form.Item name='status' rules={[REQUIRED]}>
                  <Select
                    placeholder='Select Status'
                    className='black-book__select'
                    getPopupContainer={(trigger) => trigger.parentElement}
                  >
                    {STATUS_OPTIONS.map((item: { label: string; key: string }) => (
                      <Option value={item.key} key={item.label}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default NewBlackBook;
