import AxiosUtils from 'utils/axios-utils';
import {
  deleteRequestDeleteFailed,
  deleterequestDeleteLoading,
  deleterequestDeleteSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { DeleteBody } from 'types/request-delete';
import { getUsers } from 'redux/users/user-list/actions';
import { UsersTableType } from 'types/users';

export const deleteUser =
  (data: DeleteBody, paramsData: UsersTableType, onCloseModal: any) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(deleterequestDeleteLoading());
      const apiResponse = (await AxiosUtils.post(`${API.deleteRequestDelete}`, data)) as any;
      dispatch(deleterequestDeleteSuccess(apiResponse.data));
      dispatch(getUsers(paramsData));
      onCloseModal(null);
      toast.success('Delete user successfully!', { autoClose: 3000 });
    } catch (error: any) {
      toast.error('Deleted user failed!', { autoClose: 3000 });
      dispatch(deleteRequestDeleteFailed(error.toString()));
    }
  };
