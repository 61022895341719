import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input } from 'antd';
import 'suneditor/dist/css/suneditor.min.css';
import './style.scss';
import PageTitle from '../../../components/page-title';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../types/redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { IUpcomingRace, IYoutubeLink, RaceType } from 'types/upcoming-race';
import Indicator from 'components/indicator';
import moment from 'moment';
import { formatDate } from 'utils/common';
import { API_FORMAT_DATE, DISPLAY_FORMAT_DATE, REQUIRED_MESSAGE } from 'utils/constant';
import { getRaceDetail } from 'redux/upcoming-race/race-detail/actions';
import CustomAddRace from '../custom-add-race';
import { createRace } from 'redux/upcoming-race/race-create/actions';
import { updateRace } from 'redux/upcoming-race/race-update/actions';
import TextEditor from 'components/text-editor/text-editor';
import BackButton from 'components/back-button';
import SelectCountry from 'components/select-location';
import Preview from '../preview';
import CustomAddPastWinners from '../custom-add-past-winners';
import {
  CHECK_HTTP,
  MAX_10000_EDITOR,
  getMaxLengthValidate,
  REQUIRED,
  WHITE_SPACE,
  WITHOUT_SPECIAL_CHARACTER,
  CHECK_ALL_SPACE_WITHOUT_REQUIRED,
} from 'utils/rule-form';
import { paths } from 'routes/routes';
import CustomAddImages from 'components/custom-add-images';
import { IImage } from 'types/merchandise';
import { resetCreateRaceError } from 'redux/upcoming-race/race-create/reducers';
import { resetUpdateRaceError } from 'redux/upcoming-race/race-update/reducers';
import { resetRaceDetailData } from 'redux/upcoming-race/race-detail/reducers';

const NewRace = () => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const raceInfo = useSelector((state: RootState) => state.raceDetail);
  const raceInfoLoading = raceInfo.loading;
  const createRaceDt = useSelector((state: RootState) => state.createRace);
  const { loading: createRaceLoading, error: createRaceError } = createRaceDt;
  const updateRaceDt = useSelector((state: RootState) => state.updateRace);
  const { loading: updateRaceLoading, error: updateRaceError } = updateRaceDt;
  const isLoading = raceInfoLoading || createRaceLoading || updateRaceLoading;
  const watchLink = Form.useWatch('linkBettingSite', form);

  const raceDetail = raceInfo?.data;

  const {
    title,
    linkBettingSite,
    races,
    content,
    pastWinners,
    country,
    state,
    images,
    date,
    linkToBettingSiteText,
    viewCountBettingSite,
  } = raceDetail;
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const headTitle = id ? 'Edit Upcoming Race' : 'New Upcoming Race';
  const buttonText = id ? 'Save' : 'Add new';
  const dateDetail = moment(date).utc();

  const calculateCTR = (viewCount: number, clickCount: number) => {
    const clickThroughRatio =
      clickCount && viewCount
        ? `CTR = ${((clickCount / viewCount) * 100).toFixed(2)} %`
        : 'CTR = 0%';
    return clickThroughRatio;
  };

  const [showPreview, setShowPreview] = useState(false);

  const formInitValue = {
    title: id ? title : '',
    date: id ? dateDetail : '',
    linkBettingSite: id ? linkBettingSite : '',
    linkToBettingSiteText: id ? linkBettingSite : 'Go to betting site',
    races: id ? races : [],
    images: id
      ? images.map((item: IImage) => {
          return {
            file: {
              fileUrl: item.thumbnailUrl,
              fileKey: item.thumbnailKey,
            },
          };
        })
      : [],
    content: id ? content : '',
    pastWinners: id ? pastWinners : [],
    country: id ? country : '',
    state: id ? state : '',
    viewCountBettingSite: id ? viewCountBettingSite : '',
  };

  const onFinish = async (value: any) => {
    const newRaces = value.races.map((item: any) => {
      const newObj = {
        title: item.title,
        raceNumber: item.raceNumber,
        length: item.length,
        description: item.description,
        audioUrl: item?.file?.fileUrl,
        audioKey: item?.file?.fileKey,
        ...(item?.horseInformation && {
          horseInformation: item?.horseInformation?.map((horse: any) => {
            return {
              description: horse.description ? horse.description.trim().split(/ +/).join(' ') : '',
              linkBettingSite: horse.linkBettingSite
                ? horse.linkBettingSite.trim().split(/ +/).join(' ')
                : '',
              id: horse?.id,
            };
          }),
        }),
        ...(item.id && { id: item.id }),
        youtubeLink:
          item?.youtubeLink?.map((item: IYoutubeLink) => {
            return {
              url: item.url,
              description: item.description,
            };
          }) || [],
      };
      return newObj;
    });

    const date = formatDate(value.date, API_FORMAT_DATE);
    const params: IUpcomingRace = {
      ...value,
      date,
      races: newRaces,
      images: value.images.map((item: any) => {
        return {
          thumbnailKey: item.file.fileKey,
          thumbnailUrl: item.file.fileUrl,
        };
      }),
    };
    delete params.thumbnail;
    if (id) {
      dispatch(updateRace(params, id));
    } else {
      dispatch(createRace(params, navigate));
    }
  };

  const onClosePreview = () => {
    setShowPreview(false);
  };
  const onShowPreview = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        setShowPreview(true);
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  useEffect(() => {
    id && dispatch(getRaceDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        title,
        date: date ? dateDetail : '',
        linkBettingSite,
        content,
        pastWinners,
        country,
        state,
        linkToBettingSiteText,
        images: images.map((item: IImage) => {
          return {
            file: {
              fileUrl: item.thumbnailUrl,
              fileKey: item.thumbnailKey,
            },
          };
        }),
        races: races.map((item: RaceType) => {
          return {
            ...item,
            file: {
              fileUrl: item.audioUrl,
              fileKey: item.audioKey,
            },
          };
        }),
        viewCountBettingSite,
      });
    }
  }, [form, raceDetail, id]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateRaceError());
      dispatch(resetUpdateRaceError());
      dispatch(resetRaceDetailData());
    };
  }, []);

  return (
    <div>
      <BackButton label='Back to list' sourceLink={paths.upcoming} />
      <PageTitle
        title={headTitle}
        style={{
          padding: '5px 30px',
          width: '800px',
          color: 'white',
          backgroundColor: '#1d2451',
          marginTop: 15,
        }}
      />
      <div className='upcoming-race'>
        <div className='upcoming-race-left'>
          {isLoading ? (
            <Indicator />
          ) : (
            <Form
              form={form}
              layout='vertical'
              name='upcoming-race-form'
              className='upcoming-race__form'
              onFinish={onFinish}
              initialValues={formInitValue}
            >
              <Form.Item
                name='title'
                label='Track & Day of the Week'
                rules={[
                  REQUIRED,
                  WHITE_SPACE,
                  getMaxLengthValidate(100),
                  WITHOUT_SPECIAL_CHARACTER,
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item rules={[REQUIRED]} name='date' label='Date'>
                <DatePicker format={DISPLAY_FORMAT_DATE} style={{ width: '100%' }} />
              </Form.Item>
              <SelectCountry form={form} countryDefault={country} />
              <Form.Item
                name='linkBettingSite'
                label={
                  <>
                    <div className='upcoming-race__link-to-betting-title'>
                      {'Link To Betting Site '}
                      {id && watchLink ? (
                        <div className='upcoming-race__click-through-ratio'>
                          <span>
                            {calculateCTR(
                              raceDetail.viewCountBettingSite,
                              raceDetail.clickCountBettingSite,
                            )}
                          </span>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                }
                rules={[CHECK_HTTP]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='linkToBettingSiteText'
                label='Custom Betting site button'
                rules={[getMaxLengthValidate(50), CHECK_ALL_SPACE_WITHOUT_REQUIRED]}
              >
                <Input />
              </Form.Item>
              <Form.Item name='images' className='dynamic-field__image' label='Images List'>
                <CustomAddImages REQUIRE_MESSAGE={REQUIRED_MESSAGE} maxItem={5} />
              </Form.Item>
              <Form.Item
                name='content'
                label='Description'
                rules={[MAX_10000_EDITOR, CHECK_ALL_SPACE_WITHOUT_REQUIRED]}
              >
                <TextEditor />
              </Form.Item>
              <Form.Item label='Races' rules={[REQUIRED]}>
                <CustomAddRace />
              </Form.Item>
              <Form.Item label='Past winners'>
                <CustomAddPastWinners />
              </Form.Item>
              <div className='upcoming-race__footer'>
                {createRaceError && <p className='red-text'>{createRaceError} </p>}
                {updateRaceError && <p className='red-text'>{updateRaceError} </p>}
                <div className='upcoming-race__end'>
                  <Button type='primary' htmlType='submit' className='login__button'>
                    {buttonText}
                  </Button>
                  <Button type='primary' className='preview__button' onClick={onShowPreview}>
                    Show preview
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </div>
        <div className='upcoming-race-right'>
          <Preview form={form} open={showPreview} onClose={onClosePreview} />
        </div>
      </div>
    </div>
  );
};

export default NewRace;
