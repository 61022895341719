import AxiosUtils from 'utils/axios-utils';
import { getBannersLoading, getBannersSuccess, getBannersFailure } from './reducers';
import { AppDispatch } from '../../types/redux';
import { API } from '../api-route';

const getBanners = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(getBannersLoading());
    const apiResponse: any = await AxiosUtils.get(API.banner);
    dispatch(getBannersSuccess(apiResponse?.data?.data));
  } catch (error: any) {
    dispatch(getBannersFailure(error));
  }
};

export default getBanners;
