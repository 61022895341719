import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    createdAt: '',
    date: '',
    id: '',
    linkBettingSite: '',
    races: [],
    status: '',
    title: '',
    updatedAt: '',
    content: '',
    pastWinners: [],
    country: '',
    state: '',
    images: [],
    viewCountBettingSite: undefined,
    clickCountBettingSite: undefined,
    linkToBettingSiteText: '',
  },
  loading: false,
  error: '',
};

const updateRaceSlice = createSlice({
  name: 'updateRace',
  initialState: initialState,
  reducers: {
    updateRaceLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    updateRaceSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    updateRaceFailed: (state, action) => {
      state.loading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    resetUpdateRaceError: (state) => {
      state.error = '';
    },
  },
});

export const { updateRaceLoading, updateRaceFailed, updateRaceSuccess, resetUpdateRaceError } =
  updateRaceSlice.actions;
export default updateRaceSlice.reducer;
