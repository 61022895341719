import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from 'routes/protected-route';
import SidebarLayout from 'layouts';
import { mainRoutes } from 'routes/routes';
import { ToastContainer } from 'react-toastify';
import { RouteObject } from './types/route';
import { Modal } from 'antd';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/antd.min.css';
import './styles/index.scss';
import { useSelector } from 'react-redux';
import { RootState } from './types/redux';
import { deleteSession } from './utils/session';

function App() {
  const auth = useSelector((state: RootState) => state.auth);
  const { isTokenExpired } = auth;

  const showTokenExpiredModal = () => {
    Modal.info({
      title: 'Token expired',
      content: (
        <div>
          <p>Token expired! Click Ok to login again</p>
        </div>
      ),

      onOk() {
        deleteSession();
        window.location.href = '/login';
      },
    });
  };

  useEffect(() => {
    if (isTokenExpired) {
      showTokenExpiredModal();
    }
  }, [isTokenExpired]);

  return (
    <div className='App'>
      <Routes>
        {mainRoutes.map((route: RouteObject, index: number) => {
          const Component = route.component;
          if (route.isPublish) {
            return <Route key={index} path={route.path} element={<Component />} />;
          }
          return (
            <Route
              key={index}
              path={route.path}
              element={
                <ProtectedRoute>
                  <SidebarLayout>
                    <Component />
                  </SidebarLayout>
                </ProtectedRoute>
              }
            />
          );
        })}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
