import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    country: '',
    state: '',
    trackLocation: '',
    description: '',
    id: '',
    name: '',
    // thumbnailUrl: '',
    // thumbnailKey: '',
    status: '',
    date: '',
  },
  loading: false,
  error: '',
};

const blackBookSlice = createSlice({
  name: 'blackBookDetail',
  initialState: initialState,
  reducers: {
    getBlackBookDetailLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getBlackBookDetailSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getBlackBookDetailFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
  },
});

export const { getBlackBookDetailLoading, getBlackBookDetailSuccess, getBlackBookDetailFailure } =
  blackBookSlice.actions;
export default blackBookSlice.reducer;
