import React from 'react';
import './style.scss';

interface Props {
  title: string;
  style?: any;
}

const PageTitle = ({ title, style }: Props) => {
  return (
    <div className='page-title' style={style}>
      {title}
    </div>
  );
};

export default PageTitle;
