import AxiosUtils from 'utils/axios-utils';
import { editFreeAccountLoading, editFreeAccountFailure, editFreeAccountSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';

export const editFreeAccount =
  (params: any, id: string, onCloseModal: any, resetForm: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(editFreeAccountLoading());
      const apiResponse: any = await AxiosUtils.post(API.changePasswordFreeAccount, {
        ...params,
        userId: id,
      });
      dispatch(editFreeAccountSuccess(apiResponse?.data?.data));
      onCloseModal(null);
      resetForm();
      toast.success('Update password successfully');
    } catch (error: any) {
      dispatch(editFreeAccountFailure(error));
      toast.error('Update password unsuccessfully');
    }
  };
