import { AppDispatch } from 'types/redux';
import { getStatusLoading, getStatusError, getFreeAccountSelected } from './reducers';
import { toast } from 'react-toastify';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import { UsersTableType } from 'types/users';
import { getFreeAccounts } from '../free-account-list/actions';

export const deleteFreeAccount =
  (id: string, pagination: UsersTableType) => async (dispatch: AppDispatch) => {
    try {
      dispatch(getStatusLoading(true));
      await AxiosUtils._delete(`${API.deleteFreeAccount}/${id}`);

      dispatch(getStatusError(''));

      dispatch(getFreeAccounts(pagination));

      toast.success('Delete user successfully!', { autoClose: 3000 });
    } catch (error) {
      dispatch(getStatusError(error));
    } finally {
      dispatch(getStatusLoading(false));
      dispatch(getFreeAccountSelected(id));
    }
  };
