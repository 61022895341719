import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    content: '',
  },
  loading: false,
  error: '',
};

const updatePolicySlice = createSlice({
  name: 'updatePolicy',
  initialState: initialState,
  reducers: {
    updatePolicyLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    updatePolicySuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    updatePolicyFailed: (state, action) => {
      state.loading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    resetUpdatePolicy: (state) => {
      state.error = '';
    },
  },
});

export const { updatePolicyFailed, updatePolicyLoading, updatePolicySuccess, resetUpdatePolicy } =
  updatePolicySlice.actions;
export default updatePolicySlice.reducer;
