import React from 'react';
import { Form, Input, Button } from 'antd';
import './style.scss';
import { CloseOutlined } from '@ant-design/icons';
import {
  CHECK_YOUTUBE,
  getMaxLengthValidate,
  REQUIRED,
  WHITE_SPACE_NOT_REQUIRED,
} from 'utils/rule-form';

interface Props {
  index: number;
}

const CustomAddLink = ({ index }: Props) => {
  return (
    <div className='custom-add-link'>
      <Form.List name={[index, 'youtubeLink']}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <div key={field.key} className='custom-add-link__item'>
                    {fields.length && (
                      <div className='custom-add-link__remove'>
                        <CloseOutlined onClick={() => remove(field.name)} />
                      </div>
                    )}
                    <Form.Item
                      name={[index, 'url']}
                      label='Youtube Url'
                      rules={[REQUIRED, CHECK_YOUTUBE]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'description']}
                      label='Description'
                      rules={[WHITE_SPACE_NOT_REQUIRED, getMaxLengthValidate(1000)]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                );
              })}
              <div className='custom-add-link__max-item'>
                <Button ghost type='primary' onClick={() => add()} htmlType='button' size='small'>
                  Add Link Youtube
                </Button>
                {fields.length > 0 && <p>Total: {fields.length} links</p>}
              </div>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default CustomAddLink;
