import AxiosUtils from 'utils/axios-utils';
import {
  getPodcastDetailLoading,
  getPodcastDetailSuccess,
  getPodcastDetailFailure,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';

export const getPodcastDetail = (id: string | number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getPodcastDetailLoading());
    const apiResponse = (await AxiosUtils.get(`${API.podcastDetail}/${id}`)) as any;
    dispatch(getPodcastDetailSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getPodcastDetailFailure(error.toString()));
  }
};
