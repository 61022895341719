import React from 'react';
import { Link } from 'react-router-dom';

interface IButtonProps {
  label: string;
  sourceLink: string;
}

function BackButton({ label, sourceLink }: IButtonProps) {
  return <Link to={sourceLink}>{`< ${label}`}</Link>;
}

export default BackButton;
