import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const freeAccountSlice = createSlice({
  name: 'createFreeAccount',
  initialState: initialState,
  reducers: {
    createFreeAccountLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    createFreeAccountSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    createFreeAccountFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCreateFreeAccountError: (state) => {
      state.error = '';
    },
  },
});

export const {
  createFreeAccountLoading,
  createFreeAccountFailure,
  createFreeAccountSuccess,
  resetCreateFreeAccountError,
} = freeAccountSlice.actions;
export default freeAccountSlice.reducer;
