import AxiosUtils from 'utils/axios-utils';
import {
  createExclusiveContentLoading,
  createExclusiveContentFailure,
  createExclusiveContentSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IUpdateExclusiveContent } from 'types/exclusive-content';
import { paths } from 'routes/routes';

export const createExclusiveContent =
  (params: IUpdateExclusiveContent, navigate: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(createExclusiveContentLoading());
      const apiResponse: any = await AxiosUtils.post(API.exclusiveContent, params);
      dispatch(createExclusiveContentSuccess(apiResponse?.data?.data));
      navigate(paths.exclusiveContent);
    } catch (error: any) {
      dispatch(createExclusiveContentFailure(error));
    }
  };
