import AxiosUtils from 'utils/axios-utils';
import { getMerchandiseLoading, getMerchandiseSuccess, getMerchandiseFailure } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { ParamsTableType } from 'types/black-book';

export const getMerchandise = (params: ParamsTableType) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getMerchandiseLoading());
    const apiResponse = (await AxiosUtils.get(API.merchandise, params)) as any;
    dispatch(getMerchandiseSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getMerchandiseFailure(error.toString()));
  }
};
