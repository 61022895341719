import React from 'react';
import { Form, Input, Button } from 'antd';
import { UPLOAD_TYPE } from 'utils/constant';
import './style.scss';
import CustomizedUploadFileControl from '../../../components/customized-upload-file-control';
import { CloseOutlined } from '@ant-design/icons';
import { MAX_IMAGE_SIZE } from 'utils/constant';
import { CHECK_FILE, CHECK_HTTP, REQUIRED } from 'utils/rule-form';

const CustomAddMedia = () => {
  return (
    <div className='custom-add-media'>
      <Form.List name='sponsors'>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <div key={field.key} className='custom-add-media__item'>
                    {fields.length && (
                      <div className='custom-add-media__remove'>
                        <CloseOutlined onClick={() => remove(field.name)} />
                      </div>
                    )}
                    <Form.Item
                      name={[index, 'logo']}
                      label={`Add sponsor's logo (Only png/jpg/jepg, Maxsize: ${MAX_IMAGE_SIZE} MB)`}
                      rules={[CHECK_FILE]}
                      className='custom-add-media__image'
                    >
                      <CustomizedUploadFileControl
                        type={UPLOAD_TYPE.IMAGE}
                        maxFileSize={MAX_IMAGE_SIZE}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'linkMediaSite']}
                      label={`Link to sponsor's site`}
                      rules={[REQUIRED, CHECK_HTTP]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                );
              })}
              <div className='custom-add-media__max-item'>
                {fields.length > 0 && <p>Total: {fields.length} items</p>}
                <Button ghost type='primary' onClick={() => add()} htmlType='button'>
                  Add a sponsor
                </Button>
              </div>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default CustomAddMedia;
