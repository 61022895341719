import AxiosUtils from 'utils/axios-utils';
import { getPodcastLoading, getPodcastSuccess, getPodcastFailure } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IPodcastPagination } from 'types/podcast';

export const getPodcast = (params: IPodcastPagination) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getPodcastLoading());
    const apiResponse = (await AxiosUtils.get(API.podcast, params)) as any;
    dispatch(getPodcastSuccess(apiResponse.data));
  } catch (error: any) {
    dispatch(getPodcastFailure(error.toString()));
  }
};
