import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    createdAt: '',
    date: '',
    id: '',
    linkBettingSite: '',
    races: [],
    status: '',
    title: '',
    updatedAt: '',
    content: '',
    pastWinners: [],
    country: '',
    state: '',
    images: [],
    viewCountBettingSite: 0,
    clickCountBettingSite: 0,
    linkToBettingSiteText: '',
  },
  loading: false,
  error: '',
};

const upCommingRaceSlice = createSlice({
  name: 'raceDetail',
  initialState: initialState,
  reducers: {
    getRaceDetailLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getRaceDetailSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getRaceDetailFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    resetRaceDetailData: (state) => {
      state.data = initialState.data;
    },
  },
});

export const {
  getRaceDetailLoading,
  getRaceDetailSuccess,
  getRaceDetailFailure,
  resetRaceDetailData,
} = upCommingRaceSlice.actions;
export default upCommingRaceSlice.reducer;
