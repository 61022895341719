import AxiosUtils from 'utils/axios-utils';
import { createRaceLoading, createRaceFailure, createRaceSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IUpcomingRace } from 'types/upcoming-race';
import { paths } from 'routes/routes';

export const createRace =
  (params: IUpcomingRace, navigate: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(createRaceLoading());
      const apiResponse: any = await AxiosUtils.post(API.upcomingRaceList, params);
      dispatch(createRaceSuccess(apiResponse?.data?.data));
      navigate(paths.upcoming);
    } catch (error: any) {
      dispatch(createRaceFailure(error));
    }
  };
