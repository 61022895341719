import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: '',
  userSelected: '',
};

const usersSlice = createSlice({
  name: 'userBanEdit',
  initialState: initialState,
  reducers: {
    getStatusLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getUserSelected: (state, action) => {
      state.userSelected = action.payload;
    },
    getStatusError: (state, action) => {
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
  },
});

export const { getStatusLoading, getStatusError, getUserSelected } = usersSlice.actions;

export default usersSlice.reducer;
