import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const saveBannersSlice = createSlice({
  name: 'editBanners',
  initialState: initialState,
  reducers: {
    saveBannersLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    saveBannersSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    saveBannersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { saveBannersLoading, saveBannersSuccess, saveBannersFailure } =
  saveBannersSlice.actions;
export default saveBannersSlice.reducer;
