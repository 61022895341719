import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconLogo } from 'assets/icons/logo.svg';
import {
  faUserGroup,
  faFlag,
  faHorseHead,
  faClock,
  faMicrophoneLines,
  faBriefcase,
  faNewspaper,
  faGear,
  faIdCard,
  faUserXmark,
} from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { Link } from 'react-router-dom';

const paths = [
  {
    to: '/users',
    name: 'Users',
    icon: faUserGroup,
  },
  {
    to: '/banner',
    name: 'Banner Slideshow',
    icon: faFlag,
  },
  {
    to: '/black-book',
    name: 'Black book',
    icon: faHorseHead,
  },
  {
    to: '/upcoming-race',
    name: 'Upcoming Races',
    icon: faClock,
  },
  {
    to: '/podcast',
    name: 'Weekly Podcast',
    icon: faMicrophoneLines,
  },
  {
    to: '/merchandise',
    name: 'Merchandise',
    icon: faBriefcase,
  },
  {
    to: '/exclusive-content',
    name: 'Late Mail Tips',
    icon: faNewspaper,
  },
  {
    to: '/policy-setting',
    name: 'Policy Setting',
    icon: faGear,
  },
  {
    to: '/free-account',
    name: 'Free Accounts',
    icon: faIdCard,
  },
  {
    to: '/request-delete',
    name: 'Request Delete',
    icon: faUserXmark,
  },
];

const Menu = () => {
  return (
    <div className={styles.menu}>
      <Link to='/' className={styles.linkToHome}>
        <IconLogo className={styles.logo} />
      </Link>
      {paths.map((path, index) => {
        return (
          <NavLink
            className={({ isActive }) => {
              const activeStyle = isActive ? styles.nav_active : styles.nav_normal;
              return cn(activeStyle, styles.nav_item);
            }}
            to={path.to}
            key={index}
          >
            <div className={styles.item}>
              <FontAwesomeIcon icon={path.icon} size='lg' color={'white'} />
              <span>{path.name}</span>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default Menu;
