import React, { useRef, useEffect, useMemo } from 'react';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { handleUploadFile } from 'utils/common';
import SunEditorCore from 'suneditor/src/lib/core';
import { MAX_IMAGE_SIZE } from 'utils/constant';
import _ from 'lodash';
interface Props {
  forwardRef?: any;
  value?: string;
  minHeight?: string;
  maxHeight?: string;
  name?: string;
  placeholder?: string;
  disablePlugin?: string[];
  onChange?: (value: string) => void;
  disable?: boolean;
}

const TagsBlackList = {
  image: 'img',
};

const TextEditor = ({
  forwardRef,
  value,
  name,
  minHeight,
  maxHeight,
  disablePlugin = ['image', 'video', 'template', 'save'],
  placeholder,
  onChange,
  disable = false,
}: Props) => {
  const editorRef = useRef<SunEditorCore>();
  const imagesRef = useRef<any>([]);

  const getEditorInstance = (instance: SunEditorCore) => {
    editorRef.current = instance;
  };

  useEffect(() => {
    if (editorRef.current && forwardRef) {
      forwardRef.current = editorRef.current;
    }
  }, [editorRef.current]);

  useEffect(() => {
    if (editorRef.current) {
      imagesRef.current.concat(editorRef.current.getImagesInfo());
    }
  }, [editorRef.current]);

  const EditorEvents = {
    preUploadImage: (files: any, info: any, response: any) => {
      if (disablePlugin?.includes('image')) {
        response('');
      } else if (files[0].size / (1024 * 1024) > MAX_IMAGE_SIZE) {
        editorRef.current?.noticeOpen(
          `Image size must be less than 2MB, now is ${files[0].size / (1024 * 1024)} MB`,
        );
        response('');
      } else {
        handleUploadFile(files[0], MAX_IMAGE_SIZE.toString())
          .then((res) => {
            response({
              result: [
                {
                  url: res?.data?.data?.fileUrl,
                  name: res?.data?.data?.fileKey,
                },
              ],
            });
          })
          .catch((err) => {
            editorRef.current?.noticeOpen(err);
            response('');
          });
      }
    },

    onImageUploadError: (errorMessage: any, result: any) => {
      if (result?.uploadSize > result?.limitSize) {
        editorRef.current?.noticeOpen(
          `Image size must be less than 2MB. Uploaded size: ${Number(
            result?.uploadSize / (1024 * 1024),
          ).toFixed(2)} MB`,
        );
      }
    },
  };

  const EditorOptions = {
    buttonList: useMemo(() => {
      const newButtonList = _.cloneDeep(buttonList.complex);
      for (const i of disablePlugin || []) {
        for (const k of newButtonList) {
          _.remove(k, (item) => {
            return item === i;
          });
        }
      }
      return newButtonList;
    }, []),

    tagsBlackList: useMemo(() => {
      const tags = [];
      for (const key in TagsBlackList) {
        if (disablePlugin?.includes(key)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tags.push(TagsBlackList[key]);
        }
      }
      return tags.join('|');
    }, []),
  };

  return (
    <SunEditor
      name={name}
      setContents={value}
      width='100%'
      height='100%'
      setAllPlugins={true}
      placeholder={placeholder}
      setOptions={{
        minHeight: minHeight ?? '150px',
        maxHeight: maxHeight ?? '80vh',
        buttonList: EditorOptions.buttonList,
        // charCounter: true,
        // charCounter: true,
        charCounterType: 'char',
        charCounterLabel: `Maximum (10000 characters): `,
        defaultStyle: 'font-family:Arial, Helvetica, sans-serif;',
        imageUploadSizeLimit: 2 * 1024 * 1024,
        pasteTagsBlacklist: EditorOptions.tagsBlackList,
      }}
      onImageUploadBefore={EditorEvents.preUploadImage as any}
      onImageUploadError={EditorEvents.onImageUploadError}
      getSunEditorInstance={getEditorInstance}
      onChange={onChange}
      disable={disable}
    />
  );
};

export default TextEditor;
