import { Spin } from 'antd';
import React from 'react';
import './style.scss';

function Indicator() {
  return (
    <div className='wrapModal'>
      <Spin />
    </div>
  );
}

export default Indicator;
