import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    title: '',
    description: '',
    linkBettingSite: '',
    sponsors: [],
    id: '',
    thumbnailKey: '',
    thumbnailUrl: '',
    videoKey: '',
    videoUrl: '',
    linkToBettingSiteText: '',
    isVideo: true,
    youtubeLink: '',
    videoDuration: '',
    viewCountBettingSite: undefined,
    clickCountBettingSite: undefined,
  },
  loading: false,
  error: '',
};

const podcastSlice = createSlice({
  name: 'podcastDetail',
  initialState: initialState,
  reducers: {
    getPodcastDetailLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getPodcastDetailSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getPodcastDetailFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    resetDataPodcast: (state) => {
      state.data = initialState.data;
    },
  },
});

export const {
  getPodcastDetailLoading,
  getPodcastDetailSuccess,
  getPodcastDetailFailure,
  resetDataPodcast,
} = podcastSlice.actions;
export default podcastSlice.reducer;
