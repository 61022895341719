import React, { useEffect, useState } from 'react';
import { Space, Table, Alert, Button, Popconfirm, Spin, DatePicker } from 'antd';
import PageTitle from 'components/page-title';
import { API_FORMAT_DATE, DISPLAY_FORMAT_DATE, UPCOMING_RACE_STATUS } from 'utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'types/redux';
import type { TableProps } from 'antd';
import _ from 'lodash';
import './style.scss';
import { formatDate } from 'utils/common';
import { Link, useNavigate } from 'react-router-dom';

import { deleteUpcomingRace } from 'redux/upcoming-race/race-delete/actions';
import { IUpcomingRace } from 'types/upcoming-race';
import { getUpcomingRaces } from 'redux/upcoming-race/race-list/actions';
import { setParamsData } from 'redux/upcoming-race/race-list/reducers';
import InputSearch from 'components/input-search';
import { paths } from 'routes/routes';

const UpcomingRaces = () => {
  const dispatch = useDispatch() as AppDispatch;

  const listUpcomingRaces = useSelector((state: RootState) => state.upcomingRace);
  const raceDelete = useSelector((state: RootState) => state.upcomingRaceDelete);

  const { data, error, isLoading, totalCount, paramsData } = listUpcomingRaces;

  const [raceId, setRaceId] = useState('');
  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      key: 'title',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      render: (date: string) => <span>{formatDate(date)}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any) => {
        const editLink = `/upcoming-race/${record.id}/edit`;
        return (
          <Space size='middle'>
            <Link to={editLink}>Edit</Link>
            {raceDelete.isLoading && record.id == raceId ? (
              <Spin />
            ) : (
              <Popconfirm title='Are you sure to delete?' onConfirm={() => onDelete(record.id)}>
                <a>Delete</a>
              </Popconfirm>
            )}
            {raceDelete.error && record.id === raceDelete.raceSelected && (
              <Alert
                message={raceDelete.error}
                type='error'
                style={{ marginTop: '8px', fontSize: '12px' }}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const onDelete = (id: string) => {
    setRaceId(id);
    dispatch(deleteUpcomingRace(id, paramsData));
  };

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: totalCount,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
  };

  useEffect(() => {
    dispatch(getUpcomingRaces(paramsData));
  }, [paramsData]);

  useEffect(() => {
    if (data.length === 0 && paramsData.page > 1) {
      dispatch(setParamsData({ ...paramsData, page: paramsData.page - 1 }));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setParamsData(null));
    };
  }, []);

  const handleTableChange: TableProps<IUpcomingRace>['onChange'] = (
    pagination,
    filter,
    sorter: any,
  ) => {
    const currentSort = {
      sort: sorter.order ? sorter.field : '',
      type: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
    };
    const previousSort = _.pick(paramsData, ['sort', 'type']);
    const currentPage = (_.isEqual(currentSort, previousSort) && pagination.current) || 1;

    const params: any = {
      page: currentPage,
      limit: pagination.pageSize || 10,
      title: search,
      ...currentSort,
    };
    dispatch(setParamsData(params));
  };

  const onSearch = (value: string) => {
    setSearch(value);
    dispatch(setParamsData({ ...paramsData, title: value, page: 1 }));
  };

  const changeColorRow = (record: IUpcomingRace) => {
    const isHideRace = record.status == UPCOMING_RACE_STATUS.HIDE;
    const statusColor = isHideRace ? 'upcoming-races-page_hide' : 'upcoming-races-page_show';
    return statusColor;
  };

  const handleFilterSearch = (e: any) => {
    if (!e) dispatch(setParamsData({ ...paramsData, startDate: '', endDate: '' }));
    else if (e?.length < 2) return;
    else {
      const startDate = formatDate(String(new Date(e[0])), API_FORMAT_DATE);
      const endDate = formatDate(String(new Date(e[1])), API_FORMAT_DATE);
      dispatch(setParamsData({ ...paramsData, page: 1, startDate, endDate }));
    }
  };

  return (
    <div className='upcoming-races-page'>
      <PageTitle title='Upcoming races' />
      <Space size='middle' className='upcoming-races-page_space'>
        <div className='upcoming-races-page_space--search'>
          <Button type='primary' onClick={() => navigate(paths.upcomingRaceCreate)}>
            Add new race
          </Button>
          <InputSearch
            placeholder='Search race by title'
            onChangeSearch={(value) => onSearch(value)}
          />
        </div>
        <div className='upcoming-races-page_space--filter'>
          <span className='upcoming-races-page_space--filter-label'>Filter by date:</span>
          <DatePicker.RangePicker
            placeholder={['From Date', 'To Date']}
            format={DISPLAY_FORMAT_DATE}
            onChange={handleFilterSearch}
          />
        </div>
      </Space>
      {error ? (
        <Alert message={error} type='error' />
      ) : (
        <Table
          columns={columns}
          dataSource={data.slice(0, paramsData.limit)}
          rowKey='id'
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          rowClassName={changeColorRow}
          scroll={{ y: '70vh', x: 900 }}
          bordered
        />
      )}
    </div>
  );
};

export default UpcomingRaces;
