import { Form, Input, Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/redux';
import { useAppDisPatch } from 'utils/common';
import { FormValue } from './../../../types/request-delete';
import { createRequestDelete } from 'redux/request-delete/request-delete-create/actions';
import { REQUIRED } from 'utils/rule-form';
import logo from '../../../assets/icons/logo-black.svg';
import './style.scss';

const NewRequestDelete = () => {
  const dispatch = useAppDisPatch();

  const requestDelete = useSelector((state: RootState) => state.createRequestDelete);
  const { error, isLoading } = requestDelete;

  const onSubmit = (values: FormValue) => {
    dispatch(createRequestDelete(values));
  };

  return (
    <div className='request-delete'>
      <Form layout='vertical' name='login-form' className='login__form' onFinish={onSubmit}>
        <div className='login__logo'>
          <img src={logo} alt='logo' />
        </div>
        <Form.Item name='name' label='Name' rules={[REQUIRED]}>
          <Input className='login__inputStyle' />
        </Form.Item>
        <Form.Item label='Email' name='email' rules={[REQUIRED]}>
          <Input className='login__inputStyle' />
        </Form.Item>

        <Button
          loading={isLoading}
          disabled={isLoading}
          className='login__btn_login'
          type='primary'
          block
          size='middle'
          htmlType='submit'
        >
          Send Delete Request
        </Button>
        {error && <div className='login__error'>{error}</div>}
      </Form>
    </div>
  );
};

export default NewRequestDelete;
