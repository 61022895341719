import React from 'react';
import { Form, Input, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './style.scss';
import {
  CHECK_ALL_SPACE,
  CHECK_ALL_SPACE_WITHOUT_REQUIRED,
  getMaxLengthValidate,
  REQUIRED,
} from 'utils/rule-form';

const CustomAddPastWinners = () => {
  return (
    <div className='custom-add-past-winner'>
      <Form.List name='pastWinners'>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => {
                return (
                  <div key={field.key} className='custom-add-past-winner__item'>
                    {fields.length && (
                      <div className='custom-add-past-winner__remove'>
                        <CloseOutlined onClick={() => remove(field.name)} />
                      </div>
                    )}
                    <Form.Item
                      name={[index, 'horseName']}
                      label='Horse Name (max 50 characters)'
                      className='custom-add-past-winner__name'
                      rules={[REQUIRED, getMaxLengthValidate(50), CHECK_ALL_SPACE]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'horseDescription']}
                      label='Horse description (max 200 characters)'
                      className='custom-add-past-winner__description'
                      rules={[getMaxLengthValidate(200), CHECK_ALL_SPACE_WITHOUT_REQUIRED]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                );
              })}
              <div className='custom-add-past-winner__max-item'>
                {fields.length > 0 && <p>Total: {fields.length} items</p>}
                <Button ghost type='primary' onClick={() => add()} htmlType='button'>
                  Add Past Winners
                </Button>
              </div>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default CustomAddPastWinners;
