import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    title: '',
    description: '',
    linkBettingSite: '',
    sponsors: [],
    id: '',
    thumbnailKey: '',
    thumbnailUrl: '',
    videoKey: '',
    videoUrl: '',
    linkToBettingSiteText: '',
    isVideo: true,
    youtubeLink: '',
    videoDuration: '',
    viewCountBettingSite: undefined,
    clickCountBettingSite: undefined,
  },
  loading: false,
  error: '',
};

const updatePodcastSlice = createSlice({
  name: 'updatePodcast',
  initialState: initialState,
  reducers: {
    updatePodcastLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    updatePodcastSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    updatePodcastFailed: (state, action) => {
      state.loading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    resetUpdatePodcast: (state) => {
      state.error = '';
    },
  },
});

export const {
  updatePodcastLoading,
  updatePodcastFailed,
  updatePodcastSuccess,
  resetUpdatePodcast,
} = updatePodcastSlice.actions;
export default updatePodcastSlice.reducer;
