import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  isLoading: false,
  totalCount: 0,
  error: '',
  paramsData: {
    page: 1,
    limit: 10,
    sort: '',
    type: '',
    name: '',
    status: '',
  },
  checkedList: [
    'id',
    'firstName',
    'lastName',
    'email',
    'phoneNumber',
    'subscriber',
    'country',
    'status',
  ],
};

const freeAccountsSlice = createSlice({
  name: 'freeAccounts',
  initialState: initialState,
  reducers: {
    getFreeAccountsLoading: (state) => {
      state.isLoading = true;
    },
    getFreeAccountsSuccess: (state, action?) => {
      state.data = action.payload.user;
      state.totalCount = action.payload.count;
      state.isLoading = false;
    },
    getFreeAccountsError: (state, action) => {
      state.isLoading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    setParamsData: (state, action) => {
      state.paramsData = action.payload
        ? { ...state.paramsData, ...action.payload }
        : initialState.paramsData;
    },
    setCheckedList: (state, action) => {
      const arrangeCheckedList = initialState.checkedList.filter(
        (checked) => action.payload && action.payload.includes(checked),
      );
      state.checkedList = action.payload ? arrangeCheckedList : initialState.checkedList;
    },
  },
});

export const {
  getFreeAccountsLoading,
  getFreeAccountsSuccess,
  getFreeAccountsError,
  setParamsData,
  setCheckedList,
} = freeAccountsSlice.actions;

export default freeAccountsSlice.reducer;
