/* eslint-disable no-constant-condition */
import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Radio, AutoComplete, Spin } from 'antd';
import CustomizedUploadFileControl from '../../../components/customized-upload-file-control';
import { DeleteOutlined } from '@ant-design/icons';
import {
  UPLOAD_TYPE,
  OPTIONS_LINK_TYPE,
  KEY_LINK_TYPE,
  REQUIRED_MESSAGE,
  MAX_IMAGE_SIZE,
} from 'utils/constant';
import './style.scss';
import { getBlackBook } from '../../../redux/black-book/black-book-list/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../types/redux';
import _ from 'lodash';
import { setParamsBlackBook } from '../../../redux/black-book/black-book-list/reducers';
import { getUpcomingRaces } from 'redux/upcoming-race/race-list/actions';
import { getPodcast } from 'redux/podcast/podcast-list/actions';
import { setParamsData } from 'redux/upcoming-race/race-list/reducers';
import { setParamsPodcast } from 'redux/podcast/podcast-list/reducers';
import {
  CHECK_FILE,
  CHECK_HTTP,
  getMaxLengthValidate,
  WHITE_SPACE_NOT_REQUIRED,
  WITHOUT_SPECIAL_CHARACTER,
} from 'utils/rule-form';
import { getMerchandise } from 'redux/merchandise/merchandise-list/actions';
import { setParamsMerchandise } from 'redux/merchandise/merchandise-list/reducers';

const { TextArea } = Input;
const MAX_BANNER = 5;
const { Option } = AutoComplete;

const DynamicField = () => {
  const dispatch = useDispatch() as AppDispatch;
  const blackBook = useSelector((state: RootState) => state.blackBook);
  const upcomingRaces = useSelector((state: RootState) => state.upcomingRace);
  const podcasts = useSelector((state: RootState) => state.podcast);
  const merchandise = useSelector((state: RootState) => state.merchandise);
  const [dataLink, setDataLink] = useState<any>([]);
  const [typeLink, setTypeLink] = useState('');
  const form = Form.useFormInstance();
  const formValue = form.getFieldValue('banners');
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const getData = (value: any, typeLinkOpt = '', isAbort = false) => {
    switch (typeLinkOpt ? typeLinkOpt : typeLink) {
      case KEY_LINK_TYPE.BLACKBOOK:
        dispatch(getBlackBook({ ...blackBook.paramsData, name: value, limit: 500 }));
        break;
      case KEY_LINK_TYPE.UPCOMING_RACE:
        dispatch(getUpcomingRaces({ ...upcomingRaces.paramsData, title: value, limit: 500 }));
        break;
      case KEY_LINK_TYPE.PODCAST:
        dispatch(getPodcast({ ...podcasts.paramsData, title: value, limit: 500 }));
        break;
      case KEY_LINK_TYPE.MERCHANDISE:
        dispatch(getMerchandise({ ...merchandise.paramsData, name: value, limit: 500 }));
        break;
      default:
        setDataLink([]);
    }
  };

  const calculateCTR = (viewCount: number, clickCount: number) => {
    const clickThroughRatio =
      clickCount && viewCount
        ? `CTR = ${((clickCount / viewCount) * 100).toFixed(2)} %`
        : 'CTR = 0%';
    return clickThroughRatio;
  };

  const onSearch = _.debounce(async (value, index) => {
    if (value?.length > 0) {
      getData(value);
      setIsSearch(true);
    } else if (
      formValue[index]?.typeLink &&
      formValue[index]?.typeId?.label &&
      value?.length === 0
    ) {
      setIsSearch(false);
    } else if (value?.length === 0) {
      setDataLink([]);
    }
  }, 300);

  const onChange = (value: string, option: any, index: number) => {
    getData(option?.children);
    const initialBanners = formValue.map((item: any, i: number) => {
      if (i === index) {
        return { ...item, typeId: value ? { value, label: option?.children } : null };
      } else return item;
    });
    form.setFieldValue('banners', initialBanners);
  };

  const onSelectTypeLink = (value: string, index: number) => {
    setDataLink([]);
    setTypeLink(value);
    const initialBanners = formValue.map((item: any, i: number) => {
      if (i === index) {
        return { ...item, typeLink: value, typeId: null };
      } else return item;
    });
    form.setFieldValue('banners', initialBanners);
  };

  const onFocusInternalLabel = (value: string, index: number) => {
    setIsSearch(false);
    setTypeLink(formValue[index]?.typeLink);
    if (value && formValue[index]?.typeLink) {
      getData(value, formValue[index]?.typeLink);
    }
  };

  useEffect(() => {
    setDataLink([]);
  }, [typeLink]);

  useEffect(() => {
    switch (typeLink) {
      case KEY_LINK_TYPE.BLACKBOOK:
        setDataLink(blackBook.data.filter((item: any) => item.status));
        break;
      case KEY_LINK_TYPE.UPCOMING_RACE:
        setDataLink(upcomingRaces.data);
        break;
      case KEY_LINK_TYPE.PODCAST:
        setDataLink(podcasts.data);
        break;
      case KEY_LINK_TYPE.MERCHANDISE:
        setDataLink(merchandise.data);
        break;
      default:
        setDataLink([]);
    }
  }, [blackBook.data, upcomingRaces.data, podcasts.data, merchandise.data]);

  useEffect(() => {
    return () => {
      onSearch.cancel();
      dispatch(setParamsBlackBook(null));
      dispatch(setParamsData(null));
      dispatch(setParamsPodcast(null));
      dispatch(setParamsMerchandise(null));
      setIsSearch(false);
    };
  }, []);

  return (
    <div className='dynamic-field'>
      <Form.List name='banners'>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.length > 0 && (
                <div className='dynamic-field__max-item'>
                  Total: {fields.length} items (Max: 5 items)
                </div>
              )}
              <div className='dynamic-field__row'>
                {fields.map((field, index) => {
                  return (
                    <div key={field.key} className='dynamic-field__item'>
                      <div className='dynamic-field__item-content'>
                        <div className='dynamic-field__item-header'>
                          <div className='dynamic-field__item-order'>{index + 1}</div>
                          <div className='dynamic-field__item-click-count'>
                            <span></span>
                            {fields.length && (
                              <div className='dynamic-field__remove'>
                                <Button title='Remove' danger onClick={() => remove(field.name)}>
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <Form.Item
                          name={[index, 'file']}
                          label={`Image ( Only png/jpg/jepg, Maxsize: ${MAX_IMAGE_SIZE}MB, Recommended aspect ratio: 16:9)`}
                          rules={[CHECK_FILE]}
                          className='dynamic-field__image'
                        >
                          <CustomizedUploadFileControl
                            type={UPLOAD_TYPE.IMAGE}
                            maxFileSize={MAX_IMAGE_SIZE}
                            imageRatio={1}
                          />
                        </Form.Item>

                        <Form.Item
                          className='dynamic-field__checkbox'
                          name={[index, 'isInternal']}
                          valuePropName='value'
                          label='Link:'
                        >
                          <Radio.Group style={{ display: 'flex', alignItems: 'center' }}>
                            <Radio value={true}>Internal link</Radio>
                            <Radio value={false}>
                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                External link
                                {formValue[index].isInternal === false && formValue[index].url ? (
                                  <div className='dynamic-field__click-through-ratio'>
                                    <span>
                                      {calculateCTR(
                                        formValue[index].viewCountBanner,
                                        formValue[index].clickUrlCount,
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </Radio>
                          </Radio.Group>
                        </Form.Item>

                        {formValue[index].isInternal ? (
                          <Input.Group compact className='dynamic-field__select'>
                            <Form.Item
                              className='dynamic-field__select--typeLink'
                              name={[index, 'typeLink']}
                            >
                              <Select
                                onSelect={(value: string) => {
                                  onSelectTypeLink(value, index);
                                }}
                                placeholder='Choose'
                                allowClear
                                onClear={() => onSelectTypeLink('', index)}
                                getPopupContainer={(trigger) => trigger.parentElement}
                              >
                                {OPTIONS_LINK_TYPE.map((o) => {
                                  return (
                                    <Select.Option key={o.value} value={o.value}>
                                      {o.label}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[index, 'typeId']}
                              className='dynamic-field__select--typeId'
                              help={
                                formValue[index].typeLink && !formValue[index]?.typeId?.value
                                  ? REQUIRED_MESSAGE
                                  : ''
                              }
                              validateStatus={
                                formValue[index].typeLink && !formValue[index]?.typeId?.value
                                  ? 'error'
                                  : 'validating'
                              }
                            >
                              <Select
                                showSearch
                                disabled={!formValue[index].typeLink}
                                onChange={(value, option) => onChange(value, option, index)}
                                onSearch={(value) => onSearch(value, index)}
                                allowClear
                                onClick={() =>
                                  onFocusInternalLabel(formValue[index]?.typeId?.label, index)
                                }
                                onBlur={() => setDataLink([])}
                                placeholder='Search by title'
                                optionFilterProp='children'
                                notFoundContent={
                                  formValue[index]?.typeId?.label &&
                                  !dataLink.length &&
                                  !isSearch ? (
                                    <Spin size='small' />
                                  ) : (
                                    <div>not found</div>
                                  )
                                }
                                getPopupContainer={(trigger) => trigger.parentElement}
                              >
                                {dataLink.map((d: any, i: number) => (
                                  <Option key={i} value={d.id}>
                                    {[KEY_LINK_TYPE.BLACKBOOK, KEY_LINK_TYPE.MERCHANDISE].includes(
                                      typeLink,
                                    )
                                      ? d.name
                                      : d.title}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Input.Group>
                        ) : (
                          <Form.Item
                            name={[index, 'url']}
                            rules={[CHECK_HTTP, getMaxLengthValidate(1000)]}
                          >
                            <Input />
                          </Form.Item>
                        )}

                        <Form.Item
                          name={[index, 'title']}
                          label='Title:'
                          rules={[
                            getMaxLengthValidate(100),
                            WITHOUT_SPECIAL_CHARACTER,
                            WHITE_SPACE_NOT_REQUIRED,
                          ]}
                        >
                          <TextArea maxLength={100} />
                        </Form.Item>
                      </div>
                    </div>
                  );
                })}
              </div>

              <Form.Item>
                <div className='dynamic-field__wrap-button'>
                  <Button
                    ghost
                    type='primary'
                    onClick={() => add({ isInternal: true })}
                    htmlType='button'
                    disabled={fields.length === MAX_BANNER}
                  >
                    Add Item
                  </Button>
                </div>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default DynamicField;
