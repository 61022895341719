import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const merchandiseSlice = createSlice({
  name: 'deleteMerchandise',
  initialState: initialState,
  reducers: {
    deleteMerchandiseLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    deleteMerchandiseSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    deleteMerchandiseFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { deleteMerchandiseLoading, deleteMerchandiseFailed, deleteMerchandiseSuccess } =
  merchandiseSlice.actions;
export default merchandiseSlice.reducer;
