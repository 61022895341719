import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const blackBookSlice = createSlice({
  name: 'deletePodcast',
  initialState: initialState,
  reducers: {
    deletePodcastLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    deletePodcastSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    deletePodcastFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { deletePodcastLoading, deletePodcastFailed, deletePodcastSuccess } =
  blackBookSlice.actions;
export default blackBookSlice.reducer;
