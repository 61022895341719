import AxiosUtils from 'utils/axios-utils';
import { updatePolicyFailed, updatePolicyLoading, updatePolicySuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { IPolicy } from 'types/policy';

export const updatePolicy = (params: IPolicy) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updatePolicyLoading());
    const apiResponse = (await AxiosUtils.post(`${API.policyUpdate}`, params)) as any;
    dispatch(updatePolicySuccess(apiResponse?.data?.data));
    toast.success('Updated the policy successfully!', { autoClose: 3000 });
  } catch (error: any) {
    dispatch(updatePolicyFailed(error));
  }
};
