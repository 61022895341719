import React from 'react';
import { Modal } from 'antd';
import './style.scss';
import {
  CalendarOutlined,
  EnvironmentOutlined,
  StarOutlined,
  WifiOutlined,
  CloudOutlined,
  DownloadOutlined,
  ArrowUpOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import defaultImg from '../../../assets/images/default-img.png';
import iconVoice from '../../../assets/icons/voice.svg';
import countries from './../../../utils/countries.json';

interface Props {
  form: any;
  open: boolean;
  onClose: () => void;
}
const Preview = (props: Props) => {
  const { form, open, onClose } = props;
  const formValue = form.getFieldValue();
  const onClickScrollToContent = (index: any) => {
    const modal = document.querySelector('.ant-modal-wrap') as any;
    const offsetTopContent = (document.querySelector(`.list-content-${index}`) as any).offsetTop;
    modal.scroll({
      top: offsetTopContent,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {open && (
        <Modal
          width={470}
          style={{ top: 30 }}
          visible={open}
          onCancel={onClose}
          onOk={onClose}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div className='preview '>
            <div className='preview-header'>
              <div className='battery'>{moment().format('hh:mm')}</div>
              <div className='info'>
                <WifiOutlined />
                <CloudOutlined />
                <DownloadOutlined />
              </div>
            </div>
            <div className='preview-content'>
              <div className='preview-content__background'>
                <img src={formValue?.images[0]?.file.fileUrl || defaultImg} />
                <div className='preview-content__background-overlay' />
              </div>
              <div className='preview-content__wrapper'>
                <div className='preview-title'>
                  <div className='title'>
                    <p>{formValue.title}</p>
                  </div>
                  <div className='title-actions'>
                    <button>
                      <StarOutlined />
                    </button>
                  </div>
                </div>
                <div className='preview-date'>
                  <div className='icon'>
                    <CalendarOutlined />
                  </div>
                  <span>{moment(formValue.date).format('DD/MM/YYYY')}</span>
                </div>
                <div className='preview-country'>
                  <div className='icon'>
                    <EnvironmentOutlined />
                  </div>
                  <span>{`${formValue.state === countries[1].name ? '' : formValue.state + ', '} ${
                    formValue.country
                  }`}</span>
                </div>
                <div className='preview-list-title'>
                  <p>LIST CONTENT</p>
                  <ul>
                    {formValue.races.map((data: any, index: any) => (
                      <li
                        key={index}
                        className={`preview-list-${index}`}
                        onClick={() => onClickScrollToContent(index)}
                      >
                        {data.raceNumber}
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className='preview-description description'
                  dangerouslySetInnerHTML={{ __html: formValue.content }}
                />
                <div className='preview-list'>
                  {formValue?.races?.map((data: any, index: any) => (
                    <div className={`list-content list-content-${index} description`} key={index}>
                      <div className='title'>
                        <span className='text-ellipsis'>{data.raceNumber}</span>
                        <div className='actions'>
                          <button>
                            <img src={iconVoice} alt='voice icon' />
                          </button>
                          <button>
                            <StarOutlined />
                          </button>
                        </div>
                      </div>
                      <div className='race-name-length'>
                        <span>{data.title}</span>
                        <span>{data.length}</span>
                      </div>
                      <div className='horse-info'>
                        {data?.horseInformation?.map((item: any, index: any) => {
                          return (
                            <div key={index}>
                              <a href={item.linkBettingSite}>
                                <span>{item.description}</span>&nbsp;
                                <span>
                                  <ArrowUpOutlined className='up-arrow' />
                                </span>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                      <div
                        className='content description'
                        dangerouslySetInnerHTML={{ __html: data.description }}
                      />
                      {data?.youtubeLink?.map((video: any, index: any) => (
                        <div className='video-container' key={index}>
                          <div
                            className='video-description'
                            dangerouslySetInnerHTML={{ __html: video.description }}
                          />
                          <div className='videos'>
                            <div className='video'>
                              <iframe
                                width='100%'
                                height='162'
                                src={video.url?.replace('/watch?v=', '/embed/')}
                                title='YouTube video player'
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className='preview-past-winners'>
                  <span className='title'>Past winners</span>
                  {formValue?.pastWinners?.length !== 0 ? (
                    <>
                      {formValue.pastWinners.map((data: any, index: any) => (
                        <div className='past-winners-list' key={index}>
                          <div className='box'>
                            <div className='past-title text-ellipsis'>{data.horseName}</div>
                            <div className='past-content description'>{data.horseDescription}</div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className='empty'>Empty</div>
                  )}
                </div>
                <a href={formValue.linkBettingSite}>
                  <div className='preview-button'>
                    <span>
                      {formValue.linkToBettingSiteText
                        ? formValue.linkToBettingSiteText
                        : 'Go to Betting site'}
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Preview;
