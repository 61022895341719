import AxiosUtils from 'utils/axios-utils';
import {
  uploadExclusiveContentLoading,
  uploadExclusiveContentFailed,
  uploadExclusiveContentSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { IUpdateExclusiveContent } from 'types/exclusive-content';

export const updateExclusiveContent =
  (params: IUpdateExclusiveContent, id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(uploadExclusiveContentLoading());
      const apiResponse = (await AxiosUtils.put(
        `${API.exclusiveContentUpdate}/${id}`,
        params,
      )) as any;
      dispatch(uploadExclusiveContentSuccess(apiResponse?.data?.data));
      toast.success('Updated the Late Mail Tips successfully!', { autoClose: 3000 });
    } catch (error: any) {
      dispatch(uploadExclusiveContentFailed(error));
    }
  };
