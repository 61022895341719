import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Session } from '../types/common';
import { getSession } from '../utils/session';

interface Props {
  children: ReactElement;
}

const ProtectedRoute = ({ children }: Props) => {
  const session: Session = getSession();
  if (!session || !session.accessToken) {
    return <Navigate to='/login' />;
  }

  return children;
};

export default ProtectedRoute;
