import AxiosUtils from 'utils/axios-utils';
import { uploadBlackBookLoading, uploadBlackBookFailed, uploadBlackBookSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IUpdateBlackBook } from 'types/black-book';
import { toast } from 'react-toastify';

export const updateBlackBook =
  (params: IUpdateBlackBook, id: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(uploadBlackBookLoading());
      const apiResponse = (await AxiosUtils.put(`${API.blackBookUpdate}/${id}`, params)) as any;
      dispatch(uploadBlackBookSuccess(apiResponse?.data?.data));
      toast.success('Updated the Black Book successfully!', { autoClose: 3000 });
    } catch (error: any) {
      dispatch(uploadBlackBookFailed(error));
    }
  };
