import { AppDispatch } from 'types/redux';
import { getUsersLoading, getUsersError, getUsersSuccess } from './reducers';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import { UsersTableType } from 'types/users';
import _ from 'lodash';

export const getUsers = (params: UsersTableType) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getUsersLoading());
    const trueParams = _.pickBy(params, _.identity);

    const res: any = await AxiosUtils.get(API.users, trueParams);
    dispatch(getUsersSuccess(res?.data?.data));
  } catch (error) {
    dispatch(getUsersError(error));
  }
};
