import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  raceSelected: '',
  isLoading: false,
  error: '',
};

const upcomingRaceSlice = createSlice({
  name: 'deleteUpcomingRace',
  initialState: initialState,
  reducers: {
    deleteUpcomingRaceLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    deleteUpcomingRaceFailed: (state, action) => {
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    getRaceSelected: (state, action) => {
      state.raceSelected = action.payload;
    },
  },
});

export const { deleteUpcomingRaceLoading, getRaceSelected, deleteUpcomingRaceFailed } =
  upcomingRaceSlice.actions;
export default upcomingRaceSlice.reducer;
