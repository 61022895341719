import React, { useEffect } from 'react';
import { Radio, Button, Form, Input } from 'antd';
import 'suneditor/dist/css/suneditor.min.css';
import './style.scss';
import PageTitle from '../../../components/page-title';
import { useParams, useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../types/redux';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Indicator from 'components/indicator';
import { UPLOAD_TYPE } from 'utils/constant';
import CustomizedUploadFileControl from 'components/customized-upload-file-control';
import TextEditor from 'components/text-editor/text-editor';
import BackButton from 'components/back-button';
import CustomAddMedia from '../custom-add-media';
import { IPodcast, PodcastType } from 'types/podcast';
import { createPodcast } from 'redux/podcast/podcast-create/actions';
import { getPodcastDetail } from 'redux/podcast/podcast-detail/actions';
import { updatePodcast } from 'redux/podcast/podcast-update/actions';
import { resetDataPodcast } from 'redux/podcast/podcast-detail/reducers';
import { resetCreatePodcast } from 'redux/podcast/podcast-create/reducers';
import { resetUpdatePodcast } from 'redux/podcast/podcast-update/reducers';
import {
  CHECK_FILE,
  CHECK_HTTP,
  MAX_10000_EDITOR,
  getMaxLengthValidate,
  REQUIRED,
  WHITE_SPACE,
  WHITE_SPACE_NOT_REQUIRED,
  WITHOUT_SPECIAL_CHARACTER,
  CHECK_ALL_SPACE,
  CHECK_REQUIRED,
  CHECK_YOUTUBE,
} from 'utils/rule-form';
import { MAX_IMAGE_SIZE } from 'utils/constant';
import { paths } from 'routes/routes';

const NewPodcast = () => {
  const { id } = useParams();
  const [form] = Form.useForm();

  const podcastInfo = useSelector((state: RootState) => state.podcastDetail);
  const { loading: podcastInfoLoading, data: podcastDetail } = podcastInfo;
  const createPodcastDt = useSelector((state: RootState) => state.createPodcast);
  const { loading: createPodcastLoading, error: createPodcastError } = createPodcastDt;
  const updatePodcastDt = useSelector((state: RootState) => state.updatePodcast);
  const {
    loading: updatePodcastLoading,
    error: updatePodcastError,
    data: savedData,
  } = updatePodcastDt;
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const title = id ? 'Edit Weekly Podcast' : 'New Weekly Podcast';
  const buttonText = id ? 'Save' : 'Add new';
  const isVideoWatch = Form.useWatch('isVideo', form);
  const isLoading = podcastInfoLoading || createPodcastLoading || updatePodcastLoading;
  const watchLink = Form.useWatch('linkBettingSite', form);

  const formInitValue = {
    title: id ? podcastDetail.title : '',
    linkBettingSite: id ? podcastDetail.linkBettingSite : '',
    sponsors: id ? podcastDetail.sponsors : [],
    thumbnail: id
      ? { fileUrl: podcastDetail.thumbnailUrl, fileKey: podcastDetail.thumbnailKey }
      : {},
    video: id ? { fileUrl: podcastDetail.videoUrl, fileKey: podcastDetail.videoKey } : {},
    isVideo: id ? (podcastDetail.videoUrl ? true : false) : true,
    youtubeLink: id ? podcastDetail.youtubeLink : '',
    description: id ? podcastDetail.description : '',
    linkToBettingSiteText: id ? podcastDetail.linkToBettingSiteText : 'Go to betting site',
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onFinish = async (value: any) => {
    const newMedia = value.sponsors?.map((item: any) => {
      const newObj = {
        logo: item.logo.fileUrl,
        linkMediaSite: item.linkMediaSite,
      };
      return newObj;
    });

    const thumbnail = value.thumbnail;
    const video = value.video;
    const params: IPodcast = {
      ...value,
      title: value.title.trim().split(/ +/).join(' '),
      sponsors: newMedia,
      thumbnailUrl: thumbnail.fileUrl,
      thumbnailKey: thumbnail.fileKey,
      videoUrl: isVideoWatch ? video.fileUrl : null,
      videoKey: isVideoWatch ? video.fileKey : null,
      youtubeLink: isVideoWatch ? null : value.youtubeLink,
      videoDuration: isVideoWatch ? video.duration || podcastDetail.videoDuration : null,
    };
    delete params.thumbnail;
    delete params.video;
    delete params.isVideo;

    if (isVideoWatch) {
      form.setFieldValue('youtubeLink', '');
    } else {
      form.setFieldValue('video', {
        fileUrl: '',
        fileKey: '',
      });
    }
    if (id) {
      dispatch(updatePodcast(params, id));
    } else {
      dispatch(createPodcast(params, navigate));
    }
  };

  const calculateCTR = (viewCount: number | undefined, clickCount: number | undefined) => {
    const clickThroughRatio =
      clickCount && viewCount
        ? `CTR = ${((clickCount / viewCount) * 100).toFixed(2)} %`
        : 'CTR = 0%';
    return clickThroughRatio;
  };

  useEffect(() => {
    id && dispatch(getPodcastDetail(id));
    return () => {
      dispatch(resetDataPodcast());
      dispatch(resetCreatePodcast());
      dispatch(resetUpdatePodcast());
    };
  }, [id, dispatch]);

  useEffect(() => {
    id &&
      form.setFieldsValue({
        title: podcastDetail.title,
        linkBettingSite: podcastDetail.linkBettingSite,
        description: podcastDetail.description,
        linkToBettingSiteText: podcastDetail.linkToBettingSiteText,
        sponsors: podcastDetail?.sponsors?.map((item: PodcastType) => {
          return {
            ...item,
            logo: {
              fileUrl: item.logo,
            },
            linkMediaSite: item.linkMediaSite,
          };
        }),
        thumbnail: { fileUrl: podcastDetail.thumbnailUrl, fileKey: podcastDetail.thumbnailKey },
        video: { fileUrl: podcastDetail.videoUrl, fileKey: podcastDetail.videoKey },
        isVideo: podcastDetail.videoUrl ? true : false,
        youtubeLink: podcastDetail.youtubeLink ? podcastDetail.youtubeLink : '',
      });
  }, [form, podcastDetail, id]);

  useEffect(() => {
    if (savedData.id && id) {
      form.setFieldsValue({
        title: savedData.title,
        linkBettingSite: savedData.linkBettingSite,
        description: savedData.description,
        linkToBettingSiteText: savedData.linkToBettingSiteText,
        sponsors: savedData?.sponsors?.map((item: PodcastType) => {
          return {
            ...item,
            logo: {
              fileUrl: item.logo,
            },
            linkMediaSite: item.linkMediaSite,
          };
        }),
        thumbnail: { fileUrl: savedData.thumbnailUrl, fileKey: savedData.thumbnailKey },
        video: { fileUrl: savedData.videoUrl, fileKey: savedData.videoKey },
        isVideo: savedData.videoUrl ? true : false,
        youtubeLink: savedData.youtubeLink ? savedData.youtubeLink : '',
      });
    }
  }, [savedData]);

  return (
    <div>
      <BackButton label='Back to list' sourceLink={paths.podcast} />
      <PageTitle
        title={title}
        style={{
          padding: '5px 30px',
          width: '800px',
          color: 'white',
          backgroundColor: '#1d2451',
          marginTop: 15,
        }}
      />
      <div className='podcastDt'>
        {isLoading ? (
          <Indicator />
        ) : (
          <Form
            form={form}
            layout='vertical'
            name='podcastDt-form'
            className='podcastDt__form'
            onFinish={onFinish}
            initialValues={formInitValue}
          >
            <Form.Item
              name='title'
              label='Title'
              rules={[REQUIRED, WHITE_SPACE, getMaxLengthValidate(100), WITHOUT_SPECIAL_CHARACTER]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='description'
              label='Description'
              className='dynamic-field__image'
              rules={[MAX_10000_EDITOR, CHECK_ALL_SPACE, CHECK_REQUIRED]}
            >
              <TextEditor />
            </Form.Item>
            <Form.Item
              className='dynamic-field__checkbox'
              name='isVideo'
              valuePropName='value'
              label='Link:'
            >
              <Radio.Group>
                <Radio value={true}>Add video from your device</Radio>
                <Radio value={false}>Add Youtube link</Radio>
              </Radio.Group>
            </Form.Item>
            {isVideoWatch ? (
              <Form.Item
                name='video'
                label='Add video (Maxsize: 1GB, Video aspect ratio recommended: 16:9)'
                rules={[CHECK_FILE]}
                className='dynamic-field__image'
              >
                <CustomizedUploadFileControl type={UPLOAD_TYPE.VIDEO} maxFileSize={1024} />
              </Form.Item>
            ) : (
              <Form.Item name='youtubeLink' label='Youtube Url' rules={[REQUIRED, CHECK_YOUTUBE]}>
                <Input />
              </Form.Item>
            )}

            <Form.Item
              name='thumbnail'
              label={`Add thumbnail (Only png/jpg/jepg, Maxsize: ${MAX_IMAGE_SIZE} MB, Recommended aspect ratio: 1:1)`}
              rules={[CHECK_FILE]}
              className='dynamic-field__image'
            >
              <CustomizedUploadFileControl type={UPLOAD_TYPE.IMAGE} maxFileSize={MAX_IMAGE_SIZE} />
            </Form.Item>
            <Form.Item name='sponsors' label='Sponsors'>
              <CustomAddMedia />
            </Form.Item>
            <Form.Item
              name='linkBettingSite'
              label={
                <>
                  <div className='podcastDt__link-to-betting-title'>
                    {'Link To Betting Site '}
                    {id && watchLink ? (
                      <div className='podcastDt__click-through-ratio'>
                        <span>
                          {calculateCTR(
                            savedData.viewCountBettingSite
                              ? savedData.viewCountBettingSite
                              : podcastDetail.viewCountBettingSite,
                            savedData.clickCountBettingSite
                              ? savedData.clickCountBettingSite
                              : podcastDetail.clickCountBettingSite,
                          )}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              }
              rules={[CHECK_HTTP]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='linkToBettingSiteText'
              label='Custom Betting site button'
              rules={[WHITE_SPACE_NOT_REQUIRED, getMaxLengthValidate(50)]}
            >
              <Input />
            </Form.Item>
            <div className='podcastDt__footer'>
              {createPodcastError && <p className='red-text'>{createPodcastError} </p>}
              {updatePodcastError && <p className='red-text'>{updatePodcastError} </p>}
              <div className='podcastDt__end'>
                <Button type='primary' htmlType='submit' className='login__button'>
                  {buttonText}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default NewPodcast;
