import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import './style.scss';
import { CalendarOutlined, WifiOutlined, CloudOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import defaultImg from '../../../assets/images/default-img.png';

interface Props {
  form: any;
  open: boolean;
  onClose: () => void;
}
const Preview = (props: Props) => {
  const { form, open, onClose } = props;
  const formValue = form.getFieldValue();
  const [expireSeconds, setExpireSeconds] = useState<number>(0);

  const calculateExpiration = () => {
    const duration = moment.duration(expireSeconds);
    const days = duration.days();
    const hours = duration.hours().toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');
    if (expireSeconds === -2) {
      return 'The content is not open';
    } else if (expireSeconds === -1) {
      return 'The content has been expired';
    } else {
      return (
        <>
          The content expires in:
          <span style={{ color: 'rgba(235, 59, 80)', fontWeight: '700' }}>
            {days ? ` ${days} days` : ''}
            {' ' + hours + ' : ' + minutes + ' : ' + seconds}
          </span>
        </>
      );
    }
  };

  useEffect(() => {
    const now = moment();
    const displayEndTime = moment(formValue?.displayEndDate).set({
      hour: moment(formValue?.displayEndHour).hour(),
      minute: moment(formValue?.displayEndMinute).minute(),
    });
    const displayStartTime = moment(formValue?.displayStartDate).set({
      hour: moment(formValue?.displayStartHour).hour(),
      minute: moment(formValue?.displayStartMinute).minute(),
    });
    if (now.isBefore(displayStartTime)) {
      setExpireSeconds(-2);
    } else if (now.isAfter(displayEndTime)) {
      setExpireSeconds(-1);
    } else {
      const ms = moment(displayEndTime).diff(moment(now));
      setExpireSeconds(ms);
    }
  }, [formValue]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (expireSeconds > 0) {
        setExpireSeconds(expireSeconds - 1000);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [expireSeconds]);

  return (
    <div>
      {open && (
        <Modal
          width={470}
          style={{ top: 30 }}
          visible={open}
          onCancel={onClose}
          onOk={onClose}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <div className='exclusive-preview'>
            <div className='preview-header'>
              <div className='battery'>{moment().format('hh:mm')}</div>
              <div className='info'>
                <WifiOutlined />
                <CloudOutlined />
                <DownloadOutlined />
              </div>
            </div>
            <div className='preview-content'>
              <div className='preview-content__background'>
                <img src={formValue?.thumbnail.fileUrl || defaultImg} />
                <div className='preview-content__background-overlay' />
              </div>
              <div className='preview-content__wrapper'>
                <div className='preview-expiration'>
                  <span
                    style={{
                      backgroundColor: 'rgba(235, 59, 80, 0.1)',
                      padding: '8px',
                      color: '#212A5C',
                      fontSize: '1.3rem',
                      fontWeight: '500',
                    }}
                  >
                    {calculateExpiration()}
                  </span>
                </div>
                <div className='preview-title'>
                  <div className='title'>
                    <p>{formValue.title}</p>
                  </div>
                </div>
                <div className='preview-date'>
                  <div className='icon'>
                    <CalendarOutlined />
                  </div>
                  <span>{moment(formValue.purchaseStartDate).format('DD/MM/YYYY')}</span>
                </div>
                <div
                  className='preview-description description'
                  dangerouslySetInnerHTML={{ __html: formValue.description }}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Preview;
