import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const exclusiveContentSlice = createSlice({
  name: 'updateExclusiveContent',
  initialState: initialState,
  reducers: {
    uploadExclusiveContentLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    uploadExclusiveContentSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    uploadExclusiveContentFailed: (state, action) => {
      state.loading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    resetUpdateExclusiveContentError: (state) => {
      state.error = '';
    },
  },
});

export const {
  uploadExclusiveContentLoading,
  uploadExclusiveContentFailed,
  uploadExclusiveContentSuccess,
  resetUpdateExclusiveContentError,
} = exclusiveContentSlice.actions;
export default exclusiveContentSlice.reducer;
