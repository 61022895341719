import React, { useEffect, useState } from 'react';
import PageTitle from '../../components/page-title';
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  TableProps,
} from 'antd';
import InputSearch from 'components/input-search';
import {
  CHECK_ALL_SPACE,
  CHECK_EMAIL_SPACE_REQUIRE,
  CHECK_PASSWORD,
  REQUIRED,
  getMaxLengthValidate,
} from 'utils/rule-form';
import './style.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'types/redux';
import { useSelector } from 'react-redux';
import { getFreeAccounts } from 'redux/free-accounts/free-account-list/actions';
import { setParamsData } from 'redux/free-accounts/free-account-list/reducers';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UsersTableType, UsersType } from 'types/users';
import { REQUIRED_MESSAGE, USER_OPTIONS } from 'utils/constant';
import _ from 'lodash';
import { createFreeAccount } from 'redux/free-accounts/free-account-create/actions';
import { editFreeAccount } from 'redux/free-accounts/free-account-edit/actions';
import { deleteFreeAccount } from 'redux/free-accounts/free-account-ban-edit/actions';

const FreeAccountPage = () => {
  const [form] = Form.useForm();
  const [changePasswordForm] = Form.useForm();
  const dispatch = useDispatch() as AppDispatch;
  const [search, setSearch] = useState('');
  const freeAccountBanEdit = useSelector((state: RootState) => state.banEditFreeAccount);
  const freeAccounts = useSelector((state: RootState) => state.freeAccounts);
  const create = useSelector((state: RootState) => state.createFreeAccount);
  const { loading: createLoading } = create;
  const { data, error, isLoading, totalCount, paramsData } = freeAccounts;
  const [isCreateNewAccount, setIsCreateNewAccount] = useState<boolean>(false);
  const [isViewDetail, setIsViewDetail] = useState<UsersType | null>(null);
  const [isChangePassword, setIsChangePassword] = useState<string | null>(null);

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: totalCount,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
  };

  const onSearch = (value: string) => {
    setSearch(value);
    dispatch(setParamsData({ ...paramsData, name: value, page: 1 }));
  };

  const onDelete = (id: string) => {
    dispatch(deleteFreeAccount(id, paramsData));
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Subscriber Type',
      dataIndex: 'subcriber_type',
      key: 'subcriber_type',
      align: 'center' as const,
      render: () => {
        return <div>FREE</div>;
      },
    },
    {
      title: 'Detail/Action',
      key: 'detail',
      align: 'center' as const,
      render: (_: any, record: any) => {
        return (
          <div>
            <div className='free-accounts-page__table-action'>
              <Button type='ghost' onClick={() => setIsViewDetail(record)}>
                View Detail
              </Button>
              {freeAccountBanEdit.isLoading && record.id === freeAccountBanEdit.userSelected ? (
                <Spin />
              ) : (
                <Popconfirm title='Are you sure to delete?' onConfirm={() => onDelete(record.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} size='lg' color={'black'} />
                </Popconfirm>
              )}
              {freeAccountBanEdit.error && record.id === freeAccountBanEdit.userSelected && (
                <Alert
                  message={freeAccountBanEdit.error}
                  type='error'
                  style={{ marginTop: '8px', fontSize: '12px' }}
                />
              )}
            </div>
          </div>
        );
      },
    },
  ];

  const onCreateAccount = (values: any) => {
    const params = {
      email: String(values.email).toLocaleLowerCase(),
      password: values.password,
      rePassword: values.passwordConfirm,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: 'jigracing',
      country: 'jigracing',
      accountFree: true,
    };
    dispatch(createFreeAccount(params, setIsCreateNewAccount, paramsData, form.resetFields));
  };

  const onChangePassword = (values: any) => {
    const params = {
      newPassword: values.password,
      rePassword: values.passwordConfirm,
    };
    isChangePassword &&
      dispatch(
        editFreeAccount(
          params,
          isChangePassword,
          setIsChangePassword,
          changePasswordForm.resetFields,
        ),
      );
  };

  useEffect(() => {
    dispatch(getFreeAccounts(paramsData));
  }, [paramsData]);

  useEffect(() => {
    return () => {
      dispatch(setParamsData(null));
    };
  }, []);

  useEffect(() => {
    if (data.length === 0 && paramsData.page > 1) {
      dispatch(getFreeAccounts({ ...paramsData, page: paramsData.page - 1 }));
    }
  }, [data]);

  const handleTableChange: TableProps<UsersType>['onChange'] = (
    pagination,
    filter,
    sorter: any,
  ) => {
    const currentSort = {
      sort: sorter.order ? sorter.field : '',
      type: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
    };
    const previousSort = _.pick(paramsData, ['sort', 'type']);
    const currentPage = (_.isEqual(currentSort, previousSort) && pagination.current) || 1;

    const params: UsersTableType = {
      page: currentPage,
      limit: pagination.pageSize || 10,
      name: search,
      ...currentSort,
    };
    dispatch(setParamsData(params));
  };

  return (
    <div className='free-accounts-page'>
      <PageTitle title='FREE ACCOUNT MANAGEMENT' />
      <Space size='middle' className='users-page_space'>
        <div className='users-page_space--search'>
          <Button type='primary' onClick={() => setIsCreateNewAccount(true)}>
            Create Free Accounts
          </Button>
          <InputSearch placeholder='Search by name' onChangeSearch={onSearch} />
        </div>
      </Space>
      {error ? (
        <Alert message={error} type='error' />
      ) : (
        <Table
          columns={columns}
          dataSource={data.slice(0, paramsData.limit)}
          rowKey='id'
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{ y: '70vh', x: 900 }}
          bordered
        />
      )}

      <Modal
        title='Create Account'
        open={isCreateNewAccount}
        centered
        onCancel={() => {
          setIsCreateNewAccount(false);
          form.resetFields();
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Form layout='vertical' name='login-form' onFinish={onCreateAccount} form={form}>
          <Form.Item
            name='email'
            label='Email'
            rules={[CHECK_EMAIL_SPACE_REQUIRE, getMaxLengthValidate(200)]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Password' name='password' rules={[CHECK_PASSWORD]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Password Confirm'
            name='passwordConfirm'
            rules={[
              {
                message: 'Password confirmation must match with Password',
                validator: (_, value) => {
                  if (value.length === 0) {
                    return Promise.reject(REQUIRED_MESSAGE);
                  }
                  if (value === form.getFieldValue('password')) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Password confirmation must match with Password');
                  }
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='First Name'
            name='firstName'
            rules={[REQUIRED, getMaxLengthValidate(200), CHECK_ALL_SPACE]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Last Name'
            name='lastName'
            rules={[REQUIRED, getMaxLengthValidate(200), CHECK_ALL_SPACE]}
          >
            <Input />
          </Form.Item>
          <div className='free-accounts-page__modal-footer-button'>
            <Button
              loading={createLoading}
              disabled={createLoading}
              type='primary'
              block
              size='middle'
              htmlType='submit'
            >
              Create Account
            </Button>
            <Button
              loading={createLoading}
              disabled={createLoading}
              type='primary'
              danger
              block
              size='middle'
              onClick={() => {
                setIsCreateNewAccount(false);
                form.resetFields();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        open={!!isViewDetail}
        title={`User Detail`}
        width={800}
        centered={true}
        footer={null}
        onCancel={() => setIsViewDetail(null)}
        destroyOnClose={true}
      >
        {isViewDetail && (
          <div>
            <div className='users-page_info'>
              {USER_OPTIONS.map((item, index) => {
                let textValue;
                let label = item.label;
                switch (item.value) {
                  case 'subscriber':
                    textValue = 'FREE';
                    label = 'Subscriber Type';
                    break;
                  case 'status':
                    textValue = '';
                    break;
                  case 'country':
                    textValue = '';
                    break;
                  case 'phoneNumber':
                    textValue = '';
                    break;
                  default:
                    textValue = isViewDetail[item.value];
                    break;
                }
                return (
                  <Row className='users-page_info--row' key={index}>
                    <Col span={8}>
                      <strong>{label}</strong>
                    </Col>
                    <Col span={12}>
                      <p>{textValue}</p>
                    </Col>
                  </Row>
                );
              })}
            </div>
            <div className='free-accounts-page__view-detail-modal-footer'>
              <div>
                <Button
                  loading={createLoading}
                  disabled={createLoading}
                  type='primary'
                  danger
                  block
                  size='middle'
                  onClick={() => {
                    setIsViewDetail(null);
                    setIsChangePassword(isViewDetail.id);
                  }}
                >
                  Change Password
                </Button>
              </div>
              <div>
                <Button
                  loading={createLoading}
                  disabled={createLoading}
                  type='primary'
                  block
                  size='middle'
                  onClick={() => setIsViewDetail(null)}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title='Change Password'
        open={!!isChangePassword}
        centered
        onCancel={() => {
          setIsChangePassword(null);
          changePasswordForm.resetFields();
        }}
        footer={null}
        destroyOnClose={true}
      >
        <Form
          layout='vertical'
          name='login-form'
          onFinish={onChangePassword}
          form={changePasswordForm}
        >
          <Form.Item label='Password' name='password' rules={[CHECK_PASSWORD]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Password Confirm'
            name='passwordConfirm'
            rules={[
              {
                message: 'Password confirmation must match with Password',
                validator: (_, value) => {
                  if (value.length === 0) {
                    return Promise.reject(REQUIRED_MESSAGE);
                  }
                  if (value === changePasswordForm.getFieldValue('password')) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Password confirmation must match with Password');
                  }
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div className='free-accounts-page__modal-footer-button'>
            <Button
              loading={createLoading}
              disabled={createLoading}
              type='primary'
              block
              size='middle'
              htmlType='submit'
            >
              Change Password
            </Button>
            <Button
              loading={createLoading}
              disabled={createLoading}
              type='primary'
              danger
              block
              size='middle'
              onClick={() => {
                setIsChangePassword(null);
                changePasswordForm.resetFields();
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default FreeAccountPage;
