import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import UsersPage from 'pages/users';
import PostcastPagePage from 'pages/podcast/podcast-list';
import PostcastCreate from 'pages/podcast/new-podcast';
import UpcomingRacePage from 'pages/upcoming-race/race-list';
import BlackBook from 'pages/blackbook/black-book-list';
import Articles from 'pages/article/articles';
import ArticleCreate from 'pages/article/new-article';
import ArticleDetail from 'pages/article/detail-article';
import NewBlackBook from 'pages/blackbook/new-black-book';
import BannerPage from 'pages/banner';
import NotFound from 'pages/not-found';
import { RouteObject } from '../types/route';
import NewRace from 'pages/upcoming-race/new-race';
import NewMerchandise from 'pages/merchandise/new-merchandise';
import Merchandise from 'pages/merchandise/merchandise-list';
import ExclusiveContent from '../pages/exclusive-content/exclusive-content-list';
import NewExclusiveContent from '../pages/exclusive-content/new-exclusive-content';
import UpdatePolicy from 'pages/policy-setting/update-policy';
import PublicPolicy from 'pages/policy-setting/public-policy';
import FreeAccountPage from 'pages/free-account';
import RequestDelete from 'pages/request-delete/request-delete-list';
import NewRequestDelete from 'pages/request-delete/new-request-delete';

export const paths = {
  home: '/',
  podcast: '/podcast',
  podcastCreate: '/podcast/create',
  podcastEdit: '/podcast/:id/edit',
  upcoming: '/upcoming-race',
  users: '/users',
  login: '/login',
  blackBook: '/black-book',
  articles: '/articles',
  banner: '/banner',
  articleCreate: '/article/create',
  articleEdit: '/article/:id/edit',
  articleDetail: '/article/:id',
  blackBookEdit: '/black-book/:id/edit',
  blackBookCreate: '/black-book/create',
  notFound: '*',
  upcomingRaceEdit: '/upcoming-race/:id/edit',
  upcomingRaceCreate: '/upcoming-race/create',
  merchandise: '/merchandise',
  merchandiseEdit: '/merchandise/:id/edit',
  merchandiseCreate: '/merchandise/create',
  exclusiveContent: '/exclusive-content',
  exclusiveContentEdit: '/exclusive-content/:id/edit',
  exclusiveContentCreate: '/exclusive-content/create',
  policyEdit: '/policy-setting',
  publicPolicy: '/policy',
  freeAccount: '/free-account',
  requestDelete: '/request-delete',
  requestDeleteCreate: '/request-delete/create',
};

const mainRoutes: RouteObject[] = [
  { path: paths.notFound, component: NotFound },
  { path: paths.login, component: LoginPage, isPublish: true },
  { path: paths.home, component: HomePage },
  { path: paths.podcast, component: PostcastPagePage },
  { path: paths.podcastCreate, component: PostcastCreate },
  { path: paths.podcastEdit, component: PostcastCreate },
  { path: paths.upcoming, component: UpcomingRacePage },
  { path: paths.blackBook, component: BlackBook },
  { path: paths.users, component: UsersPage },
  { path: paths.articles, component: Articles },
  { path: paths.articleCreate, component: ArticleCreate },
  { path: paths.articleEdit, component: ArticleCreate },
  { path: paths.articleDetail, component: ArticleDetail },
  { path: paths.blackBookEdit, component: NewBlackBook },
  { path: paths.blackBookCreate, component: NewBlackBook },
  { path: paths.banner, component: BannerPage },
  { path: paths.upcomingRaceEdit, component: NewRace },
  { path: paths.upcomingRaceCreate, component: NewRace },
  { path: paths.merchandise, component: Merchandise },
  { path: paths.merchandiseCreate, component: NewMerchandise },
  { path: paths.merchandiseEdit, component: NewMerchandise },
  { path: paths.exclusiveContent, component: ExclusiveContent },
  { path: paths.exclusiveContentEdit, component: NewExclusiveContent },
  { path: paths.exclusiveContentCreate, component: NewExclusiveContent },
  { path: paths.policyEdit, component: UpdatePolicy },
  { path: paths.publicPolicy, component: PublicPolicy, isPublish: true },
  { path: paths.freeAccount, component: FreeAccountPage },
  { path: paths.requestDelete, component: RequestDelete },
  { path: paths.requestDeleteCreate, component: NewRequestDelete, isPublish: true },
];

export { mainRoutes };
