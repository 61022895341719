import React from 'react';
import { Form, Button } from 'antd';
import { UPLOAD_TYPE } from 'utils/constant';
import './style.scss';
import CustomizedUploadFileControl from '../customized-upload-file-control';
import { CloseOutlined } from '@ant-design/icons';
import { MAX_IMAGE_SIZE } from 'utils/constant';
import { CHECK_FILE } from 'utils/rule-form';

interface Props {
  maxItem?: number;
  REQUIRE_MESSAGE?: string;
}

const CustomAddImages = ({ maxItem, REQUIRE_MESSAGE = '' }: Props) => {
  return (
    <div className='custom-add-images'>
      <Form.List
        name={'images'}
        rules={[
          {
            validator: async (_, images) => {
              if (REQUIRE_MESSAGE && !images.length)
                return Promise.reject(new Error(REQUIRE_MESSAGE));
              else return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <div>
              <div className='custom-add-images__box-items'>
                {fields.map((field, index) => {
                  return (
                    <div key={field.key} className='custom-add-images__item'>
                      {fields.length && (
                        <div className='custom-add-images__remove'>
                          <CloseOutlined onClick={() => remove(field.name)} />
                        </div>
                      )}
                      <Form.Item
                        name={[index, 'file']}
                        label={`Image ( Only png/jpg/jepg, Maxsize:${MAX_IMAGE_SIZE}MB, Recommended aspect ratio: 1:1)`}
                        className='custom-add-images__image'
                        rules={[CHECK_FILE]}
                        style={{ width: 300 }}
                      >
                        <CustomizedUploadFileControl
                          type={UPLOAD_TYPE.IMAGE}
                          maxFileSize={MAX_IMAGE_SIZE}
                        />
                      </Form.Item>
                    </div>
                  );
                })}
              </div>

              <div className='custom-add-images__max-item'>
                {fields.length > 0 && <p>Total: {fields.length} items</p>}
                <Button
                  ghost
                  type='primary'
                  onClick={() => add()}
                  htmlType='button'
                  disabled={fields.length === maxItem}
                >
                  Add Image
                </Button>
              </div>
              <div className='custom-add-images__error-list'>
                <Form.ErrorList errors={errors} />
              </div>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default CustomAddImages;
