import AxiosUtils from 'utils/axios-utils';
import {
  createRequestDeleteFailure,
  createrequestDeleteLoading,
  createrequestDeleteSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { FormValue } from 'types/request-delete';
import { toast } from 'react-toastify';

export const createRequestDelete = (params: FormValue) => async (dispatch: AppDispatch) => {
  try {
    dispatch(createrequestDeleteLoading());
    const apiResponse: any = await AxiosUtils.post(API.createRequestDelete, params);
    dispatch(createrequestDeleteSuccess(apiResponse?.data?.data));
    toast.success('Send delete request successfully');
  } catch (error: any) {
    dispatch(createRequestDeleteFailure(error));
  }
};
