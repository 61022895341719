import React from 'react';
import { Form, Input, Button } from 'antd';
import { UPLOAD_TYPE } from 'utils/constant';
import './style.scss';
import CustomizedUploadFileControl from '../../../components/customized-upload-file-control';
import { CloseOutlined } from '@ant-design/icons';
import TextEditor from 'components/text-editor/text-editor';
import CustomAddLink from '../custom-add-link-youtube';
import {
  CHECK_ALL_SPACE,
  CHECK_REQUIRED,
  MAX_10000_EDITOR,
  getMaxLengthValidate,
  REQUIRED,
  WHITE_SPACE,
  CHECK_ALL_SPACE_WITHOUT_REQUIRED,
} from 'utils/rule-form';
import CustomAddHorses from 'pages/upcoming-race/custom-add-horses';

const CustomAddRace = () => {
  return (
    <div className='custom-add-race'>
      <Form.List name='races'>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields?.map((field, index) => {
                return (
                  <div key={field.key} className='custom-add-race__item'>
                    {fields?.length && (
                      <div className='custom-add-race__remove'>
                        <CloseOutlined onClick={() => remove(field.name)} />
                      </div>
                    )}
                    <Form.Item
                      name={[index, 'file']}
                      label={`Audio ( Maxsize: 200 MB) Accept mp3, wav, ogg, mp4, adts, 3gp, mov, flac, m4a, webm, mpeg file`}
                      rules={[]}
                    >
                      <CustomizedUploadFileControl type={UPLOAD_TYPE.AUDIO} maxFileSize={200} />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'raceNumber']}
                      label='Race Number (Max 100 characters)'
                      rules={[REQUIRED, CHECK_ALL_SPACE, getMaxLengthValidate(100)]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'title']}
                      label='Race Name & Type (Max 100 characters)'
                      rules={[REQUIRED, WHITE_SPACE, getMaxLengthValidate(100)]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'length']}
                      label='Length (Max 100 characters)'
                      rules={[CHECK_ALL_SPACE_WITHOUT_REQUIRED, getMaxLengthValidate(100)]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label='Horse infomation'>
                      <CustomAddHorses index={index} />
                    </Form.Item>
                    <Form.Item
                      name={[index, 'description']}
                      label='Description'
                      rules={[REQUIRED, CHECK_REQUIRED, CHECK_ALL_SPACE, MAX_10000_EDITOR]}
                    >
                      <TextEditor />
                    </Form.Item>
                    <Form.Item label='Link to Youtube'>
                      <CustomAddLink index={index} />
                    </Form.Item>
                  </div>
                );
              })}
              <div className='custom-add-race__max-item'>
                {fields.length > 0 && <p>Total: {fields.length} items</p>}
                <Button ghost type='primary' onClick={() => add()} htmlType='button'>
                  Add Race
                </Button>
              </div>
            </div>
          );
        }}
      </Form.List>
    </div>
  );
};

export default CustomAddRace;
