import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const merchandiseSlice = createSlice({
  name: 'createMerchandise',
  initialState: initialState,
  reducers: {
    createMerchandiseLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    createMerchandiseSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    createMerchandiseFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCreateMerchandiseError: (state) => {
      state.error = '';
    },
  },
});

export const {
  createMerchandiseLoading,
  createMerchandiseSuccess,
  createMerchandiseFailure,
  resetCreateMerchandiseError,
} = merchandiseSlice.actions;
export default merchandiseSlice.reducer;
