import AxiosUtils from 'utils/axios-utils';
import {
  createMerchandiseLoading,
  createMerchandiseFailure,
  createMerchandiseSuccess,
} from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { IUpdateMerchandise } from 'types/merchandise';
import { paths } from 'routes/routes';

export const createMerchandise =
  (params: IUpdateMerchandise, navigate: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(createMerchandiseLoading());
      const apiResponse: any = await AxiosUtils.post(API.merchandise, params);
      dispatch(createMerchandiseSuccess(apiResponse?.data?.data));
      navigate(paths.merchandise);
    } catch (error: any) {
      dispatch(createMerchandiseFailure(error));
    }
  };
