import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const blackBookSlice = createSlice({
  name: 'updateBlackBook',
  initialState: initialState,
  reducers: {
    uploadBlackBookLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    uploadBlackBookSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    uploadBlackBookFailed: (state, action) => {
      state.loading = false;
      const error = action.payload.message;
      state.error = error ? error : action.payload.toString();
    },
    resetUpdateBlackBookError: (state) => {
      state.error = '';
    },
  },
});

export const {
  uploadBlackBookLoading,
  uploadBlackBookFailed,
  uploadBlackBookSuccess,
  resetUpdateBlackBookError,
} = blackBookSlice.actions;
export default blackBookSlice.reducer;
