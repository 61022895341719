import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
  total: '',
};

const blackBookSortSlice = createSlice({
  name: 'blackBookSort',
  initialState: initialState,
  reducers: {
    getBlackBookLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getBlackBookSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data.list;
      state.total = action.payload.data.count;
    },
    getBlackBookFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
  },
});

export const { getBlackBookLoading, getBlackBookSuccess, getBlackBookFailure } =
  blackBookSortSlice.actions;
export default blackBookSortSlice.reducer;
