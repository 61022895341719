import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Space, TimePicker, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../../components/back-button';
import PageTitle from '../../../components/page-title';
import { paths } from '../../../routes/routes';
import { AppDispatch, RootState } from '../../../types/redux';
import { IUpdateExclusiveContent } from '../../../types/exclusive-content';
import { updateExclusiveContent } from '../../../redux/exclusive-content/exclusive-content-update/actions';
import { createExclusiveContent } from '../../../redux/exclusive-content/exclusive-content-create/actions';
import { getExclusiveContentDetail } from '../../../redux/exclusive-content/exclusive-content-detail/actions';
import Indicator from '../../../components/indicator';
import {
  CHECK_FILE,
  MAX_10000_EDITOR,
  REQUIRED,
  WHITE_SPACE,
  WITHOUT_SPECIAL_CHARACTER,
  CHECK_REQUIRED,
  getMaxLengthValidate,
  CHECK_ALL_SPACE,
} from '../../../utils/rule-form';
import './style.scss';
import {
  DISPLAY_FORMAT_DATE,
  PRIMARY_FORMAT_DATE,
  UPLOAD_TYPE,
  EXCLUSIVE_PRICE_OPTIONS,
  MAX_IMAGE_SIZE,
} from '../../../utils/constant';
import CustomizedUploadFileControl from '../../../components/customized-upload-file-control';
import moment from 'moment';
import {
  combineDateAndTimeMoment,
  combineDateAndTimeMomentToString,
  formatDate,
} from '../../../utils/common';
import useRangeDateTime from '../../../hooks/use-range-date-time';
import TextEditor from '../../../components/text-editor/text-editor';
import Preview from '../preview';
import { resetCreateExclusiveContentError } from 'redux/exclusive-content/exclusive-content-create/reducers';
import { resetUpdateExclusiveContentError } from 'redux/exclusive-content/exclusive-content-update/reducers';
import { resetExclusiveContentDetailData } from 'redux/exclusive-content/exclusive-content-detail/reducers';

const NewExclusiveContent = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const { Option } = Select;
  const { TextArea } = Input;
  const [showPreview, setShowPreview] = useState(false);

  const exclusiveContent = useSelector((state: RootState) => state.exclusiveContentDetail);
  const { loading: exclusiveContentLoading, data: exclusiveContentDetail } = exclusiveContent;
  const createExclusiveContentDt = useSelector((state: RootState) => state.createExclusiveContent);
  const { loading: createLoading, error: createError } = createExclusiveContentDt;
  const updateExclusiveContentDt = useSelector((state: RootState) => state.updateExclusiveContent);
  const { loading: updateLoading, error: updateError } = updateExclusiveContentDt;
  const [viewStatus, setViewStatus] = useState<boolean>(false);

  const pageTitle = id ? 'Edit Late Mail Tips' : 'New Late Mail Tips';
  const buttonText = id ? 'Save' : 'Add new';

  const {
    title,
    displayTimeStart,
    displayTimeEnd,
    purchaseTimeStart,
    purchaseTimeEnd,
    description,
    thumbnailUrl,
    thumbnailKey,
    price,
    contentNotification,
  } = exclusiveContentDetail;

  const onClosePreview = () => {
    setShowPreview(false);
  };
  const onShowPreview = async () => {
    try {
      const values = await form.validateFields();
      if (values) {
        setShowPreview(true);
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        id &&
        moment().isSameOrAfter(moment(displayTimeStart, PRIMARY_FORMAT_DATE)) &&
        moment().isSameOrBefore(moment(displayTimeEnd, PRIMARY_FORMAT_DATE))
      ) {
        setViewStatus(true);
      } else {
        setViewStatus(false);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [displayTimeStart, displayTimeEnd]);

  const displayTimeStartDetail = moment(displayTimeStart, PRIMARY_FORMAT_DATE).local();
  const displayTimeEndDetail = moment(displayTimeEnd, PRIMARY_FORMAT_DATE).local();
  const purchaseTimeStartDetail = moment(purchaseTimeStart, PRIMARY_FORMAT_DATE).local();
  const purchaseTimeEndDetail = moment(purchaseTimeEnd, PRIMARY_FORMAT_DATE).local();

  const timeLoading =
    id &&
    !(
      displayTimeStartDetail.isValid() &&
      displayTimeEndDetail.isValid() &&
      purchaseTimeStartDetail.isValid() &&
      purchaseTimeEndDetail.isValid()
    );

  const isLoading = exclusiveContentLoading || timeLoading || createLoading || updateLoading;

  const {
    currentStartDate: currentDisplayStartDate,
    currentEndDate: currentDisplayEndDate,
    currentEndHour: currentDisplayEndHour,
    currentEndMinute: currentDisplayEndMinute,
    disableStartHour: disableDisplayStartHour,
    disableStartMinute: disableDisplayStartMinute,
    disableEndDate: disableDisplayEndDate,
    disableEndHour: disableDisplayEndHours,
    disableEndMinute: disableDisplayEndMinutes,
    getDisabledStartHours: getDisabledDisplayStartHours,
    getDisabledStartMinutes: getDisabledDisplayStartMinutes,
    getDisabledEndHours: getDisabledDisplayEndHours,
    getDisabledEndMinutes: getDisabledDisplayEndMinutes,
    getDisableEndDays: getDisableDisplayEndDays,
    getDisableStartDays: getDisableDisplayStartDays,
  } = useRangeDateTime(form, 'display');

  const {
    currentStartDate: currentPurchaseStartDate,
    currentEndDate: currentPurchaseEndDate,
    currentEndHour: currentPurchaseEndHour,
    currentEndMinute: currentPurchaseEndMinute,
    disableEndDate: disablePurchaseEndDate,
    disableEndHour: disablePurchaseEndHour,
    disableEndMinute: disablePurchaseEndMinute,
    getDisabledStartHours: getDisabledPurchaseStartHours,
    getDisabledStartMinutes: getDisabledPurchaseStartMinutes,
    getDisabledEndHours: getDisabledPurchaseEndHours,
    getDisabledEndMinutes: getDisabledPurchaseEndMinutes,
  } = useRangeDateTime(form, 'purchase');

  const formInitValue = {
    title: id ? title : '',
    displayStartDate: id ? displayTimeStartDetail : '',
    displayStartHour: id ? displayTimeStartDetail : '',
    displayStartMinute: id ? displayTimeStartDetail : '',
    displayEndDate: id ? displayTimeEndDetail : '',
    displayEndHour: id ? displayTimeEndDetail : '',
    displayEndMinute: id ? displayTimeEndDetail : '',
    purchaseStartDate: id ? purchaseTimeStartDetail : '',
    purchaseStartHour: id ? purchaseTimeStartDetail : '',
    purchaseStartMinute: id ? purchaseTimeStartDetail : '',
    purchaseEndDate: id ? purchaseTimeEndDetail : '',
    purchaseEndHour: id ? purchaseTimeEndDetail : '',
    purchaseEndMinute: id ? purchaseTimeEndDetail : '',
    description: id ? description : '',
    price: id ? price : undefined,
    thumbnail: id ? { fileUrl: thumbnailUrl, fileKey: thumbnailKey } : '',
    contentNotification: id ? contentNotification?.description : '',
  };

  let showErrorRangerDate = false;
  const displayEndDateTime =
    currentDisplayEndDate && currentDisplayEndHour && currentDisplayEndHour
      ? combineDateAndTimeMoment(
          currentDisplayEndDate,
          currentDisplayEndHour,
          currentDisplayEndMinute,
        )
      : undefined;

  const purchaseEndDateTime =
    currentPurchaseEndDate && currentPurchaseEndHour && currentPurchaseEndMinute
      ? combineDateAndTimeMoment(
          currentPurchaseEndDate,
          currentPurchaseEndHour,
          currentPurchaseEndMinute,
        )
      : undefined;
  if (
    purchaseEndDateTime &&
    displayEndDateTime &&
    purchaseEndDateTime.isAfter(displayEndDateTime)
  ) {
    showErrorRangerDate = true;
  }

  const onFinish = async (value: any) => {
    if (showErrorRangerDate) {
      return;
    }

    const fileInfo = value.thumbnail;

    const displayTimeStart = combineDateAndTimeMomentToString(
      value.displayStartDate,
      value.displayStartHour,
      value.displayStartMinute,
    );

    const displayTimeEnd = combineDateAndTimeMomentToString(
      value.displayEndDate,
      value.displayEndHour,
      value.displayEndMinute,
    );

    const purchaseTimeStart = combineDateAndTimeMomentToString(
      value.purchaseStartDate,
      value.purchaseStartHour,
      value.purchaseStartMinute,
    );

    const purchaseTimeEnd = combineDateAndTimeMomentToString(
      value.purchaseEndDate,
      value.purchaseEndHour,
      value.purchaseEndMinute,
    );

    const params: IUpdateExclusiveContent = {
      title: value.title,
      displayTimeStart: formatDate(displayTimeStart, PRIMARY_FORMAT_DATE),
      displayTimeEnd: formatDate(displayTimeEnd, PRIMARY_FORMAT_DATE),
      purchaseTimeStart: formatDate(purchaseTimeStart, PRIMARY_FORMAT_DATE),
      purchaseTimeEnd: formatDate(purchaseTimeEnd, PRIMARY_FORMAT_DATE),
      price: value.price,
      description: value.description,
      thumbnailUrl: fileInfo.fileUrl,
      thumbnailKey: fileInfo.fileKey,
      contentNotification: { description: value.contentNotification },
    };

    if (id) {
      dispatch(updateExclusiveContent(params, id));
    } else {
      dispatch(createExclusiveContent(params, navigate));
    }
  };

  useEffect(() => {
    id && dispatch(getExclusiveContentDetail(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (id) {
      form.setFieldsValue({
        title,
        displayStartDate: displayTimeStartDetail,
        displayStartHour: displayTimeStartDetail,
        displayStartMinute: displayTimeStartDetail,
        displayEndDate: displayTimeEndDetail,
        displayEndHour: displayTimeEndDetail,
        displayEndMinute: displayTimeEndDetail,
        purchaseStartDate: purchaseTimeStartDetail,
        purchaseStartHour: purchaseTimeStartDetail,
        purchaseStartMinute: purchaseTimeStartDetail,
        purchaseEndDate: purchaseTimeEndDetail,
        purchaseEndHour: purchaseTimeEndDetail,
        purchaseEndMinute: purchaseTimeEndDetail,
        price: price,
        description,
        thumbnail: { fileUrl: thumbnailUrl, fileKey: thumbnailKey },
        contentNotification: contentNotification?.description,
      });
    }
  }, [form, exclusiveContentDetail, id]);

  useEffect(() => {
    return () => {
      dispatch(resetCreateExclusiveContentError());
      dispatch(resetUpdateExclusiveContentError());
      dispatch(resetExclusiveContentDetailData());
    };
  }, []);

  return (
    <div>
      <BackButton label='Back to list' sourceLink={paths.exclusiveContent} />
      <PageTitle
        title={pageTitle}
        style={{
          padding: '5px 30px',
          width: '800px',
          color: 'white',
          backgroundColor: '#1d2451',
          marginTop: 15,
        }}
      />
      <div className='exclusive-content'>
        {isLoading ? (
          <Indicator />
        ) : (
          <div>
            <Form
              form={form}
              layout='vertical'
              name='exclusive-content-form'
              onFinish={onFinish}
              initialValues={formInitValue}
              disabled={viewStatus}
            >
              <Form.Item
                name='title'
                label='Title'
                rules={[
                  REQUIRED,
                  WHITE_SPACE,
                  getMaxLengthValidate(100),
                  WITHOUT_SPECIAL_CHARACTER,
                ]}
              >
                <Input />
              </Form.Item>
              <Space style={{ width: '100%' }} align='end'>
                <Form.Item name='displayStartDate' label='Start Display Time' rules={[REQUIRED]}>
                  <DatePicker
                    disabledDate={getDisableDisplayStartDays}
                    format={DISPLAY_FORMAT_DATE}
                  />
                </Form.Item>
                <Form.Item name='displayStartHour' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Hour'
                    disabled={disableDisplayStartHour}
                    disabledTime={() => ({
                      disabledHours: getDisabledDisplayStartHours,
                    })}
                    hideDisabledOptions
                    inputReadOnly
                    showNow={false}
                    format={'HH'}
                    className='exclusive-content__time-picker'
                  />
                </Form.Item>
                <Form.Item name='displayStartMinute' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Minute'
                    disabled={disableDisplayStartMinute}
                    disabledTime={() => ({
                      disabledHours: () => {
                        return [];
                      },
                      disabledMinutes: getDisabledDisplayStartMinutes,
                    })}
                    hideDisabledOptions
                    inputReadOnly
                    showNow={false}
                    format={'mm'}
                    className='exclusive-content__time-picker'
                  />
                </Form.Item>
              </Space>
              <Space style={{ width: '100%' }} align='end'>
                <Form.Item name='displayEndDate' label='End Display Time' rules={[REQUIRED]}>
                  <DatePicker
                    disabled={disableDisplayEndDate}
                    disabledDate={getDisableDisplayEndDays}
                    format={DISPLAY_FORMAT_DATE}
                  />
                </Form.Item>
                <Form.Item name='displayEndHour' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Hour'
                    disabled={disableDisplayEndHours}
                    disabledTime={() => ({
                      disabledHours: getDisabledDisplayEndHours,
                    })}
                    hideDisabledOptions
                    className='exclusive-content__time-picker'
                    format={'HH'}
                    showNow={false}
                    inputReadOnly
                  />
                </Form.Item>
                <Form.Item name='displayEndMinute' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Minute'
                    disabled={disableDisplayEndMinutes}
                    disabledTime={() => ({
                      disabledHours: () => {
                        return [];
                      },
                      disabledMinutes: getDisabledDisplayEndMinutes,
                    })}
                    hideDisabledOptions
                    className='exclusive-content__time-picker'
                    format={'mm'}
                    showNow={false}
                    inputReadOnly
                  />
                </Form.Item>
              </Space>
              <Space style={{ width: '100%' }} align='end'>
                <Form.Item name='purchaseStartDate' label='Start Purchase Time' rules={[REQUIRED]}>
                  <DatePicker
                    disabledDate={(current) => {
                      if (
                        currentDisplayStartDate &&
                        currentDisplayEndDate &&
                        (current.startOf('day').isBefore(currentDisplayStartDate.startOf('day')) ||
                          currentDisplayEndDate.startOf('day').isBefore(current.startOf('day')))
                      ) {
                        return true;
                      }
                      return false;
                    }}
                    disabled={true}
                    format={DISPLAY_FORMAT_DATE}
                  />
                </Form.Item>
                <Form.Item name='purchaseStartHour' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Hour'
                    disabled={true}
                    disabledTime={() => ({
                      disabledHours: getDisabledPurchaseStartHours,
                    })}
                    hideDisabledOptions
                    format={'HH'}
                    showNow={false}
                    className='exclusive-content__time-picker'
                    inputReadOnly
                  />
                </Form.Item>
                <Form.Item name='purchaseStartMinute' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Minute'
                    disabled={true}
                    disabledTime={() => ({
                      disabledHours: () => {
                        return [];
                      },
                      disabledMinutes: getDisabledPurchaseStartMinutes,
                    })}
                    hideDisabledOptions
                    format={'mm'}
                    showNow={false}
                    className='exclusive-content__time-picker'
                    inputReadOnly
                  />
                </Form.Item>
              </Space>
              <Space style={{ width: '100%' }} align='end'>
                <Form.Item name='purchaseEndDate' label='End Purchase Time' rules={[REQUIRED]}>
                  <DatePicker
                    disabled={disablePurchaseEndDate || !currentDisplayEndDate}
                    disabledDate={(current) => {
                      if (
                        currentPurchaseStartDate &&
                        currentDisplayEndDate &&
                        (current.startOf('day').isBefore(currentPurchaseStartDate.startOf('day')) ||
                          currentDisplayEndDate.startOf('day').isBefore(current.startOf('day')))
                      ) {
                        return true;
                      }
                      return false;
                    }}
                    format={DISPLAY_FORMAT_DATE}
                  />
                </Form.Item>
                <Form.Item name='purchaseEndHour' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Hour'
                    disabled={disablePurchaseEndHour}
                    disabledTime={() => ({
                      disabledHours: getDisabledPurchaseEndHours,
                    })}
                    hideDisabledOptions
                    format={'HH'}
                    showNow={false}
                    className='exclusive-content__time-picker'
                    inputReadOnly
                  />
                </Form.Item>
                <Form.Item name='purchaseEndMinute' rules={[REQUIRED]}>
                  <TimePicker
                    placeholder='Minute'
                    disabled={disablePurchaseEndMinute}
                    disabledTime={() => ({
                      disabledHours: () => {
                        return [];
                      },
                      disabledMinutes: getDisabledPurchaseEndMinutes,
                    })}
                    hideDisabledOptions
                    format={'mm'}
                    showNow={false}
                    className='exclusive-content__time-picker'
                    inputReadOnly
                  />
                </Form.Item>
              </Space>
              {showErrorRangerDate && (
                <p className='red-text'>Display Time must end after the Purchase Time</p>
              )}
              <Form.Item name='price' label='Price' rules={[REQUIRED]}>
                <Select
                  placeholder='Select Price (AUD)'
                  style={{ width: 240 }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                >
                  {EXCLUSIVE_PRICE_OPTIONS?.map((item: any, index: number) => {
                    return (
                      <Option value={item.value} key={index}>
                        AUD{item.value}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name='thumbnail'
                rules={[CHECK_FILE]}
                label={`Image ( Only png/jpg/jepg, Maxsize: ${MAX_IMAGE_SIZE} MB, Recommended aspect ratio: 1:1)`}
                className='dynamic-field__image'
              >
                <CustomizedUploadFileControl
                  type={UPLOAD_TYPE.IMAGE}
                  maxFileSize={MAX_IMAGE_SIZE}
                  disable={viewStatus}
                />
              </Form.Item>
              <Form.Item
                name='description'
                label='Description'
                className='dynamic-field__image'
                rules={[CHECK_REQUIRED, WHITE_SPACE, MAX_10000_EDITOR, CHECK_ALL_SPACE]}
              >
                <TextEditor disable={viewStatus} />
              </Form.Item>
              <Form.Item
                name='contentNotification'
                label='Customize notification sub-content'
                rules={[REQUIRED, getMaxLengthValidate(200), CHECK_ALL_SPACE]}
              >
                <TextArea autoSize={{ minRows: 5 }} />
              </Form.Item>
              <div className='black-book__footer'>
                {createError && <p className='red-text'>{createError} </p>}
                {updateError && <p className='red-text'>{updateError} </p>}
                {viewStatus ? (
                  ''
                ) : (
                  <div className='black-book__end'>
                    <Button type='primary' htmlType='submit'>
                      {buttonText}
                    </Button>
                  </div>
                )}
              </div>
            </Form>
            <div className='preview__button'>
              <Button type='primary' onClick={onShowPreview}>
                Show preview
              </Button>
            </div>
          </div>
        )}
        <div className='upcoming-race-right'>
          <Preview form={form} open={showPreview} onClose={onClosePreview} />
        </div>
      </div>
    </div>
  );
};

export default NewExclusiveContent;
