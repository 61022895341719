import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import PageTitle from '../../../components/page-title';
import Indicator from 'components/indicator';
import TextEditor from 'components/text-editor/text-editor';
import './style.scss';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'types/redux';
import { getPolicyDetail } from 'redux/policy-setting/policy-detail/actions';
import { resetUpdatePolicy } from 'redux/policy-setting/policy-update/reducers';
import { useSelector } from 'react-redux';
import { updatePolicy } from 'redux/policy-setting/policy-update/actions';
import { IUpdatePolicy } from 'types/policy';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes/routes';

const UpdatePolicy = () => {
  const dispatch = useDispatch() as AppDispatch;
  const navigate = useNavigate();
  const policyDetail = useSelector((state: RootState) => state.policyDetail);
  const { loading, data, error } = policyDetail;
  const [form] = Form.useForm();

  const onFinish = async (value: any) => {
    const params: IUpdatePolicy = {
      content: value?.content,
      pageCode: 1,
    };
    dispatch(updatePolicy(params));
  };

  const onView = () => {
    navigate(paths.publicPolicy);
  };

  useEffect(() => {
    dispatch(getPolicyDetail(1));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetUpdatePolicy());
    };
  }, []);

  useEffect(() => {
    form.setFieldValue('content', data?.content);
  }, [data]);

  return (
    <div>
      <PageTitle
        title='Setting policy'
        style={{
          padding: '5px 30px',
          width: '800px',
          color: 'white',
          backgroundColor: '#1d2451',
          marginTop: 15,
        }}
      />
      <Button onClick={onView} type='primary' style={{ marginRight: '20px' }}>
        View Policy
      </Button>
      <div className='update-policy'>
        {loading ? (
          <Indicator />
        ) : (
          <Form
            form={form}
            layout='vertical'
            className='update-policy__form'
            name='update-policy-form'
            onFinish={onFinish}
          >
            <Form.Item name='content' label='Content'>
              <TextEditor />
            </Form.Item>
            <div className='update-policy__footer'>
              {error && <p className='red-text'>{error} </p>}
              <div className='update-policy__end'>
                <Button type='primary' htmlType='submit' className='login__button'>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default UpdatePolicy;
