import { AppDispatch } from 'types/redux';
import { getFreeAccountsLoading, getFreeAccountsError, getFreeAccountsSuccess } from './reducers';
import AxiosUtils from 'utils/axios-utils';
import { API } from 'redux/api-route';
import { UsersTableType } from 'types/users';
import _ from 'lodash';

export const getFreeAccounts = (params: UsersTableType) => async (dispatch: AppDispatch) => {
  try {
    dispatch(getFreeAccountsLoading());
    const trueParams = _.pickBy(params, _.identity);

    const res: any = await AxiosUtils.get(API.freeAccounts, trueParams);
    dispatch(getFreeAccountsSuccess(res?.data?.data));
  } catch (error) {
    dispatch(getFreeAccountsError(error));
  }
};
