import axios from 'axios';
import { getTokenStatus } from 'utils/session';
import { StorageUtils, StorageKey } from 'utils/local-storage';
import store from '../redux/configure-store';
import { changeTokenExpired } from '../redux/auth/reducers';

const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const publicUrls = ['admin/signin', 'admin/forgot-password', 'content-page/1'];

AxiosClient.interceptors.request.use((req) => {
  const session = StorageUtils.getObject(StorageKey.SESSION);
  const isValidToken = getTokenStatus();
  if (req.url && !publicUrls.includes(req.url) && !isValidToken) {
    store.dispatch(changeTokenExpired());
  } else if (session && req.headers && isValidToken) {
    req.headers['Authorization'] = `Bearer ${session.accessToken}`;
  }
  return req;
});

AxiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (error?.response?.data?.data?.message) {
      throw error?.response?.data?.data?.message;
    } else {
      throw error.message;
    }
  },
);

const get = <ReqType, ResType>(
  url: string,
  params?: ReqType,
  customHeaders?: any,
): Promise<ResType> => {
  return AxiosClient.get(url, { params, headers: customHeaders });
};

function post<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any,
): Promise<ResType> {
  return AxiosClient.post(url, data, { headers: customHeaders });
}

function postS3<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any,
): Promise<ResType> {
  return AxiosClient.post(url, data, { headers: customHeaders });
}

async function put<ReqType, ResType>(
  url: string,
  data?: ReqType,
  customHeaders?: any,
): Promise<ResType> {
  return AxiosClient.put(url, data, { headers: customHeaders });
}

async function _delete<ReqType, ResType>(url: string, params?: ReqType): Promise<ResType> {
  return AxiosClient.delete(url, { params });
}

const AxiosUtils = { get, postS3, post, put, _delete };
export default AxiosUtils;
