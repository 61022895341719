import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  loading: false,
  error: '',
};

const exclusiveContentSlice = createSlice({
  name: 'deleteExclusiveContent',
  initialState: initialState,
  reducers: {
    deleteExclusiveContentLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    deleteExclusiveContentSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    deleteExclusiveContentFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  deleteExclusiveContentLoading,
  deleteExclusiveContentFailed,
  deleteExclusiveContentSuccess,
} = exclusiveContentSlice.actions;
export default exclusiveContentSlice.reducer;
