import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    content: '',
  },
  loading: false,
  error: '',
};

const policyDetailSlice = createSlice({
  name: 'policyDetail',
  initialState: initialState,
  reducers: {
    getPolicyDetailLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getPolicyDetailSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getPolicyDetailFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    resetDataPolicy: (state) => {
      state.data = initialState.data;
    },
  },
});

export const {
  getPolicyDetailFailure,
  getPolicyDetailLoading,
  getPolicyDetailSuccess,
  resetDataPolicy,
} = policyDetailSlice.actions;
export default policyDetailSlice.reducer;
