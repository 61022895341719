import AxiosUtils from 'utils/axios-utils';
import { updatePodcastLoading, updatePodcastFailed, updatePodcastSuccess } from './reducers';
import { AppDispatch } from '../../../types/redux';
import { API } from 'redux/api-route';
import { toast } from 'react-toastify';
import { IPodcast } from 'types/podcast';

export const updatePodcast = (params: IPodcast, id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updatePodcastLoading());
    const apiResponse = (await AxiosUtils.put(`${API.podcastUpdate}/${id}`, params)) as any;
    dispatch(updatePodcastSuccess(apiResponse?.data?.data));
    toast.success('Updated the podcast successfully!', { autoClose: 3000 });
  } catch (error: any) {
    dispatch(updatePodcastFailed(error));
  }
};
