import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  loading: false,
  error: '',
};

const podcastSlice = createSlice({
  name: 'createPodcast',
  initialState: initialState,
  reducers: {
    createPodcastLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    createPodcastSuccess: (state, action) => {
      state.error = '';
      state.loading = false;
      state.data = action.payload;
    },
    createPodcastFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetCreatePodcast: (state) => {
      state.error = '';
    },
  },
});

export const {
  createPodcastLoading,
  createPodcastSuccess,
  createPodcastFailure,
  resetCreatePodcast,
} = podcastSlice.actions;
export default podcastSlice.reducer;
