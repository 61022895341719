import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    description: '',
    id: '',
    name: '',
    price: '',
    date: '',
    images: [],
    voucher: {
      name: '',
      status: '1',
    },
    linkToShop: '',
  },
  loading: false,
  error: '',
};

const merchandiseSlice = createSlice({
  name: 'merchandiseDetail',
  initialState: initialState,
  reducers: {
    getMerchandiseDetailLoading: (state) => {
      state.loading = true;
      state.error = '';
    },
    getMerchandiseDetailSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    getMerchandiseDetailFailure: (state, action) => {
      state.loading = false;
      state.error = JSON.stringify(action.payload);
    },
    resetMerchandiseDetailData: (state) => {
      state.data = initialState.data;
    },
  },
});

export const {
  getMerchandiseDetailLoading,
  getMerchandiseDetailSuccess,
  getMerchandiseDetailFailure,
  resetMerchandiseDetailData,
} = merchandiseSlice.actions;
export default merchandiseSlice.reducer;
