import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Table, Popconfirm, Space, DatePicker, Modal, Input, Form, Button } from 'antd';
import { getBlackBook } from '../../../redux/black-book/black-book-list/actions';
import { AppDispatch, RootState } from '../../../types/redux';
import { IBlackBook } from '../../../types/black-book';
import './style.scss';
import PageTitle from '../../../components/page-title';
import { setDataBlackBook, setParamsBlackBook } from 'redux/black-book/black-book-list/reducers';
import { deleteBlackBook } from 'redux/black-book/black-book-delete/actions';
import { pushNotiBlackBook } from 'redux/black-book/black-book-push-noti/actions';
import { formatDate } from 'utils/common';
import {
  API_FORMAT_DATE,
  DISPLAY_FORMAT_DATE,
  STATUS_OPTIONS,
  DELAY_SECONDS,
} from 'utils/constant';
import InputSearch from 'components/input-search';
import { paths } from 'routes/routes';
import { REQUIRED, getMaxLengthValidate, CHECK_ALL_SPACE } from 'utils/rule-form';
import moment from 'moment';

const BlackBook = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch() as AppDispatch;
  const blackBook = useSelector((state: RootState) => state.blackBook);
  const deleteBlackBookData = useSelector((state: RootState) => state.deleteBlackBook);
  const pushNotiBlackBookData = useSelector((state: RootState) => state.pushNotiBlackBook);
  const { error: pushNotiError } = pushNotiBlackBookData;
  const { data, error, loading, total, paramsData } = blackBook;
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [delayPushTime, setDelayPushTime] = useState<number>(0);
  const [isDisablePushNoti, setIsDisablePushNoti] = useState<boolean>(true);
  const { TextArea } = Input;

  const pagination = {
    current: paramsData.page,
    pageSize: paramsData.limit,
    pageSizeOptions: [10, 20, 50, 100],
    total: total,
    showSizeChanger: true,
    showTotal: (total: number, range: Array<number>) => `${range[0]}-${range[1]} of ${total}`,
    onChange: (current: number, size: number) => {
      dispatch(setParamsBlackBook({ paramsData, page: current, limit: size }));
    },
  };

  const onDelete = (id: string | number) => {
    dispatch(deleteBlackBook(id));
  };

  const onAdd = () => {
    navigate(paths.blackBookCreate);
  };

  const onSearch = (value: string) => {
    dispatch(setParamsBlackBook({ ...paramsData, name: value, page: 1 }));
  };

  const handleFilterSearch = (e: any) => {
    if (!e) dispatch(setParamsBlackBook({ ...paramsData, startDate: '', endDate: '' }));
    else if (e?.length < 2) return;
    else {
      const startDate = formatDate(String(new Date(e[0])), API_FORMAT_DATE);
      const endDate = formatDate(String(new Date(e[1])), API_FORMAT_DATE);
      dispatch(setParamsBlackBook({ ...paramsData, page: 1, startDate, endDate }));
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: (text: string | number) => <span>{text}</span>,
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      render: (text: string | number) => <span>{text}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center' as const,
      render: (text: string) => (text ? formatDate(text, DISPLAY_FORMAT_DATE) : ''),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'createdAt',
      render: (status: number) => {
        switch (status.toString()) {
          case STATUS_OPTIONS[0].key:
            return <span>{STATUS_OPTIONS[0].label}</span>;
          case STATUS_OPTIONS[1].key:
            return <span>{STATUS_OPTIONS[1].label}</span>;
          default:
            return <span>{STATUS_OPTIONS[0].label}</span>;
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: IBlackBook) => {
        const editLink = `/black-book/${record.id}/edit`;
        return (
          <Space size='middle'>
            <Link to={editLink}>Edit</Link>
            <Popconfirm title='Are you sure to delete?' onConfirm={() => onDelete(record.id)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (value: any) => {
    dispatch(pushNotiBlackBook(value));
    form.setFieldValue('description', '');
    setIsModalOpen(false);
    setDelayPushTime(DELAY_SECONDS);
    setIsDisablePushNoti(true);
    localStorage.setItem('delayStartTime', JSON.stringify(moment()));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getBlackBook(paramsData));
  }, [dispatch, paramsData, deleteBlackBookData]);

  useEffect(() => {
    if (data.length === 0 && paramsData.page > 1) {
      dispatch(setParamsBlackBook({ ...paramsData, page: paramsData.page - 1 }));
    }
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(setParamsBlackBook(null));
      dispatch(setDataBlackBook([]));
    };
  }, []);

  useEffect(() => {
    if (delayPushTime === 0) {
      setIsDisablePushNoti(false);
    } else {
      setIsDisablePushNoti(true);
    }

    const delayStartTime = JSON.parse(localStorage.getItem('delayStartTime') as string);
    if (delayStartTime) {
      const duration = Math.floor(
        DELAY_SECONDS - moment.duration(moment().diff(delayStartTime)).asSeconds(),
      );
      if (duration > 0) {
        setDelayPushTime(duration);
      }
    }

    const interval = setInterval(() => {
      if (delayPushTime && delayPushTime > 0) {
        setDelayPushTime(delayPushTime - 1);
      }
    }, 1000);

    return () => {
      return clearInterval(interval);
    };
  }, [delayPushTime]);

  return (
    <div className='blackbook'>
      <PageTitle title='Black Book' />
      <Space size='middle' className='blackbook__featureBox' align='start'>
        <div className='blackbook__searchBoxContainer'>
          <div className='blackbook__searchBox'>
            <Button onClick={onAdd} type='primary'>
              Add new
            </Button>
            <div style={{ height: '32px' }}>
              <InputSearch
                placeholder='Search the horse by name'
                onChangeSearch={(value) => onSearch(value)}
              />
            </div>
            <div className='blackbook__push-noti-modal'>
              <Button type='primary' onClick={showModal} disabled={isDisablePushNoti}>
                {isDisablePushNoti ? 'Avaiable after ' + delayPushTime + ' s' : 'Push Notification'}
              </Button>
              <Modal
                width={600}
                style={{ top: 100 }}
                visible={isModalOpen}
                title='Push notification'
                onCancel={handleCancel}
                footer={[
                  <Button
                    form='black-book__noti-form'
                    key='submit'
                    htmlType='submit'
                    type='primary'
                  >
                    Send
                  </Button>,
                ]}
              >
                <Form
                  form={form}
                  layout='vertical'
                  name='black-book-noti-form'
                  className='black-book__noti-form'
                  onFinish={handleOk}
                  id='black-book__noti-form'
                >
                  <Form.Item
                    name='description'
                    label='Description'
                    rules={[REQUIRED, getMaxLengthValidate(200), CHECK_ALL_SPACE]}
                  >
                    <Input.TextArea
                      style={{ borderRadius: '8px' }}
                      className='blackbook__noti-text-area'
                      autoSize={{ minRows: 8 }}
                    />
                  </Form.Item>
                </Form>
              </Modal>
            </div>
          </div>
          <div className='blackbook__push-noti-error'>
            {pushNotiError && <p className='red-text'>Push notification error: {pushNotiError} </p>}
          </div>
        </div>
        <div className='blackbook__filterBox'>
          <p>Filter by date:</p>
          <RangePicker
            placeholder={['From Date', 'To Date']}
            format={DISPLAY_FORMAT_DATE}
            onChange={handleFilterSearch}
          />
        </div>
      </Space>

      <div>
        {error ? (
          <Alert message={error} type='error' />
        ) : (
          <Table
            rowKey='id'
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            bordered
            scroll={{ y: '70vh', x: 900 }}
          />
        )}
      </div>
    </div>
  );
};

export default BlackBook;
