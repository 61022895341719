import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import _ from 'lodash';
import { Rule } from 'antd/lib/form';

interface Props {
  placeholder: string;
  onChangeSearch: (value: string) => void;
  propRules?: Rule[];
}

const InputSearch = ({ placeholder, onChangeSearch, propRules = [] }: Props) => {
  const onSearch = _.debounce(async (value) => {
    onChangeSearch(value);
  }, 300);

  return (
    <Form>
      <Form.Item name='' label='' rules={propRules}>
        <Input
          allowClear
          placeholder={placeholder}
          onChange={(e) => onSearch(e.target.value)}
          suffix={<SearchOutlined />}
        />
      </Form.Item>
    </Form>
  );
};

export default InputSearch;
